// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface PostsInterface {
  block: (id: string) => Promise<any>;
  getAll: (query?: Query) => Promise<any>;
  handleLike: (id: string) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (
    id: string,
    values: { [key: string]: any },
    query?: Query
  ) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  getProducts: (id: string, query?: Query) => Promise<any>;
  getSections: (id: string, query?: Query) => Promise<any>;
  getSectionProducts: (id: string, query?: Query) => Promise<any>;
  addFiles: (id: string, fileIds: string[]) => Promise<any>;
  addProducts: (id: string, productIds: string[]) => Promise<any>;
  setProducts: (id: string, productIds: string[]) => Promise<any>;
  reportPost: (values: { [key: string]: any }, query?: Query) => Promise<any>;
}

export const posts = (client: Axios): PostsInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery(`/posts`, query);
    const { data } = await client.get(url);
    return data;
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/posts/${id}`, query);
    const { data } = await client.get(url);
    return data;
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/posts", query);
    const cleanedValues = cleanValues(values);
    const { data } = await client.post(url, cleanedValues);
    return data;
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/posts/${id}`, query);
    const cleanedValues = cleanValues(values);
    const { data } = await client.put(url, cleanedValues);
    return data;
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/posts/${id}`, query);
    const { data } = await client.delete(url);
    return data;
  },

  addFiles: async (id: string, fileIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/posts/${id}/files/add`, { ids: fileIds });
    return data;
  },

  getSections: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/posts/${id}/sections`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  getSectionProducts: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/sections/${id}/products`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  getProducts: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/posts/${id}/products`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  addProducts: async (id: string, productIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/posts/${id}/products/add`, { ids: productIds });
    return data;
  },

  setProducts: async (id: string, productIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/posts/${id}/products/set`, { ids: productIds });
    return data;
  },

  handleLike: async (id: string) => {
    const { data }: AxiosResponse = await client.post(`/posts/${id}/like`);
    return data;
  },

  block: async (id: string) => {
    const { data }: AxiosResponse = await client.post(`/posts/${id}/block`);
    return data;
  },

  reportPost: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/reports", query);
    const cleanedValues = cleanValues(values);
    const { data } = await client.post(url, cleanedValues);
    return data;
  }
});
