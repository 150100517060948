import { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import outsyde from "@outsyde/sdk";
import { format } from 'date-fns';
import { useApp } from 'context';

const Wallet = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (window.document) {
    window.document.body.style.overflowY = "auto";
  }
  
  const { user, setError, setLoading } = useApp();
  const [wallet, setWallet] = useState({});
  const transactions = wallet?.recentTransactions;
  const withdrawReady = wallet?.pendingSum > 20;

  const handleTransactions = async () => {
    if (user?.isPro === 'true') {
      try {
        setLoading(true);
        let data = await outsyde.users.getWallet();
        setWallet(data);
        setLoading(false);
      } catch(err) {
        setError(err?.message)
        setLoading(false);
      }
    } else {

    }
  }

  useEffect(() => {
    handleTransactions();
    //eslint-disable-next-line
  }, [])

  return(
    <div className="App">
      <Header />
      <div className='container pt-4 js-ac flex-col'>
        <div className='js-ac mb-4' style={{ width: '100%', maxWidth: 900 }}>
          <div style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>
            {user?.username}'s Wallet
          </div>
        </div>

        <div style={{ width: '100%', maxWidth: 900, background: '#19181a', border: '1px solid rgba(255,255,255,.12' }} className='mb-3 ps-4 pe-4 pt-2 pb-2'>
          <div style={{ width: '100%', borderBottom: '1px solid rgba(255,255,255,.12', height: 44 }} className='jb-ac'>
            <div>Available</div>
            <div style={{ fontSize: 24, fontWeight: 600 }}>${roundRevenue(wallet?.availableSum)}</div>
          </div>
          <div style={{ width: '100%', borderBottom: '1px solid rgba(255,255,255,.12', height: 44 }} className='jb-ac'>
            <div>Pending</div>
            <div>${roundRevenue(wallet?.pendingSum)}</div>
          </div>
          <div style={{ width: '100%', height: 44 }} className='jb-ac'>
            <div>All Time</div>
            <div>${roundRevenue(wallet?.totalSum)}</div>
          </div>
        </div>

        <div style={{ width: '100%', maxWidth: 900, opacity: withdrawReady ? 1 : 0.5, background: withdrawReady ? '' : '#4f7361', color: withdrawReady ? '' : 'white' }} className='btn1'>
          WITHDRAW
        </div>

        {!withdrawReady && (
          <div className='jc-ac' style={{ fontSize: 11, fontWeight: 700 }}>
            *MINIMUM $20
          </div>
        )}

        <div className='js-ac mt-4 mb-2' style={{ width: '100%', maxWidth: 900 }}>
          <div style={{ color: 'white', fontSize: 18, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>
            Recent Transactions
          </div>
        </div>

        <div style={{ minHeight: 400, width: '100%', maxWidth: 900 }}>
          {transactions?.length === 0 && (
            <div style={{ width: '100%', height: 300 }} className='jc-ac'>
              <div>No Recent Transactions</div>
            </div>
          )}

          {transactions?.map((t, idx) => (
            <div key={t?.id} className='jb-ac pt-2 pb-2' style={{ borderBottom: idx === transactions?.length - 1 ? "" : '1px solid rgba(255,255,255,.12' }}>
              <div>{format(new Date(t?.clickDate), "MM/dd/yyyy")}</div>
              <div>${roundRevenue(t?.netRevenue)}</div>
            </div>
          ))}
        </div>
      </div>
      <Footer className=" " />
    </div>
  )
}

export default Wallet;

const roundRevenue = (rev) => {
  if (rev) {
    return Math.round(rev*100)/100
  } else {
    return 0
  }
}
