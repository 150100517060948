// External Imports
import { Axios } from "axios";

// Internal Imports
import { cleanValues } from "../utils";

export interface FilesInterface {
  processImages: (values: { [key: string]: any }) => Promise<any>;
  getSignedUrls: (values: { [key: string]: any }) => Promise<any>;
  createMultipart: (values: { [key: string]: any }) => Promise<any>;
  completeMultipart: (values: { [key: string]: any }) => Promise<any>;
}

export const files = (client: Axios): FilesInterface => ({
  completeMultipart: async (values: { [key: string]: any }) => {
    const cleanedValues = cleanValues(values);
    const { data } = await client.post('/files/complete-multipart', cleanedValues);
    return data;
  },

  createMultipart: async (values: { [key: string]: any }) => {
    const cleanedValues = cleanValues(values);
    const { data } = await client.post('/files/create-multipart', cleanedValues);
    return data;
  },

  getSignedUrls: async (values: { [key: string]: any }) => {
    const cleanedValues = cleanValues(values);
    const { data } = await client.post('/files/sign-parts', cleanedValues);
    return data;
  },

  processImages: async (values: { [key: string]: any }) => {
    const cleanedValues = cleanValues(values);
    const { data } = await client.post('/files/process-images', cleanedValues);
    return data;
  }
});
