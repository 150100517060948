import { IoCheckmarkOutline } from 'react-icons/io5';
import { useState, useEffect, useRef } from 'react';
import { Oval } from 'react-loading-icons'
import { Portal } from 'react-portal';
import outsyde from "@outsyde/sdk";
import { useApp } from 'context';
import InlineModal from 'components/InlineModal';
import CreateLocationModal from './CreateLocationModal';

const GeoSearch = ({ value, onChange, placeholder }) => {
  const { setLoading } = useApp();
  const [searchVal, setSearchVal] = useState("");
  const [options, setOptions] = useState([]);
  const [boundingRect, setBoundingRect] = useState({});
  const [valueVoundingRect, setValueBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length;
  const valueRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;
  const [locationsLoading, setLocationsLoading] = useState(false);
  const showHelper = searchVal?.length === 0 && !locationsLoading && options?.length === 0
  const showCreate = options.length < 4 && !locationsLoading && searchVal?.length > 0;
  const [createLocationModal, setCreateLocationModal] = useState(false);

  useEffect(() => {
    if (!value) {
      setSearchVal("")
    }
  }, [value])

  const isSelected = (option) => {
    const formattedValues = value?.map((v) => v.value);
    return !!formattedValues?.includes(option?.value)
  }

  const handleSelect = (val) => {
    setSearchVal(val?.name)
    setFocus(false)
    onChange(val)
  }

  const handleType = async (e) => {
    let newVal = e.target.value;
    setLocationsLoading(true);
    setSearchVal(newVal);
    try {
      let data = await outsyde.locations.getAll({ filter: { name: { _iLike: `%25${newVal}%25` } } });
      if (data?.length > 0) {
        setOptions(data);
      } else {
        setOptions([]);
      }
      setLocationsLoading(false);
    } catch(err) {
      setLocationsLoading(false);
    }
  }

  const handleCreateModal = () => {
    setCreateLocationModal(true);
    setSearchVal('')
    setFocus(false)
  }

  return(
    <>
      <div className='' style={{ position: 'relative' }}>
        <div style={{ position: 'relative' }}>
          <input ref={inputRef} value={searchVal || ""} onChange={(e) => handleType(e)} onFocus={() => setFocus(true)} className='input-styles light-grey-bg' placeholder="LOCATION" />
          {locationsLoading && (
            <div style={{ position: 'absolute', right: 8, top: 7 }}>
              <Oval stroke="#5c7d6d" fill="#5c7d6d" strokeWidth={5} height="24" width="24" />
            </div>
          )}
        </div>
        <InlineModal open={focus} handleClose={() => setFocus(false)} btnRef={inputRef} full={true}>
          <div className='ps-3 pe-3'>
            {options.map((option, idx) => <ResultItem key={idx} option={option} handleSelect={handleSelect} isLast={idx === options?.length - 1 && !showCreate} />)}
            {showHelper && (
              <div className='jc-ac mb-2' style={{ color: 'rgba(255,255,255,.75)'}}>Start Typing..</div>
            )}
            {showCreate && (
              <div onClick={handleCreateModal} style={{ width: '100%', color: 'white', cursor: 'pointer', whiteSpace: 'nowrap' }} className='jc-ac mt-1 mb-1 pt-2 pb-2'>
                Create New Location
              </div>
            )}
          </div>
        </InlineModal>
      </div>
      <CreateLocationModal open={createLocationModal} onCreate={handleSelect} handleClose={() => setCreateLocationModal(false)} />
    </>
  )
}

export default GeoSearch;

const ResultItem = ({ isLast, option, handleSelect }) => {
  return(
    <div onClick={() => handleSelect(option)} style={{ width: '100%', color: 'white', cursor: 'pointer', borderBottom: isLast ? "" : '1px solid rgba(255,255,255,.12)', whiteSpace: 'nowrap' }} className='js-ac truncate1 pt-2 pb-2'>
      {option?.name}
    </div>
  )
}
