import { Uploader } from "./uploader"

export const uploadFile = (file, fileType, setProgress = () => {}) =>
  new Promise((resolve, reject) => {
    let percentage = undefined
    const uploader = new Uploader({ file, fileType })

     uploader
       .onProgress((results) => {
         if (results?.percentage) {
           let newPercentage = results?.percentage;
           if (newPercentage !== percentage) {
             percentage = newPercentage
             setProgress(percentage)
           }
         }
       })
       .onComplete((fileId) => {
         resolve(fileId)
       })
       .onError((error) => {
         reject(error)
       })

     uploader.start()
  })
