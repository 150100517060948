// External Imports
import { Amplify, Auth } from "aws-amplify";
import axios, { Axios } from "axios";

// Error Classes
import { UserError, UnauthorizedError, ForbiddenError, NotFoundError, ServerError } from "./errors";

// Core SDK Modules
// import { Analytics, AnalyticsInterface } from "./modules/analytics";
import { auth, AuthInterface } from "./modules/auth";
import { pages, PagesInterface } from "./modules/pages";
import { posts, PostsInterface } from "./modules/posts";
import { users, UsersInterface } from "./modules/users";
import { files, FilesInterface } from "./modules/files";
import { emails, EmailsInterface } from "./modules/emails";
import { search, SearchInterface } from "./modules/search";
import { products, ProductsInterface } from "./modules/products";
import { postTypes, PostTypesInterface } from "./modules/postTypes";
import { giveaways, GiveawaysInterface } from "./modules/giveaways";
import { locations, LocationsInterface } from "./modules/locations";
import { activities, ActivitiesInterface } from "./modules/activities";
import { transactions, TransactionsInterface } from "./modules/transactions";

class OutsydeSDK {
  // declare analytics: AnalyticsInterface;
  declare auth: AuthInterface;
  declare pages: PagesInterface;
  declare posts: PostsInterface;
  declare users: UsersInterface;
  declare files: FilesInterface;
  declare emails: EmailsInterface;
  declare search: SearchInterface;
  declare products: ProductsInterface;
  declare postTypes: PostTypesInterface;
  declare giveaways: GiveawaysInterface;
  declare locations: LocationsInterface;
  declare activities: ActivitiesInterface;
  declare transactions: TransactionsInterface;

  declare cognitoUserPoolId: string;
  declare cognitoClientId: string;
  declare cognitoRegion: string;
  declare apiVersion: string;
  declare apiBaseUrl: string;
  declare amplitudeApiKey?: string;
  declare gaApiKey?: string;
  declare client: Axios;

  constructor() {}

  initialize = (config: {
    cognitoUserPoolId: string;
    cognitoClientId: string;
    cognitoRegion: string;
    apiVersion: string;
    apiBaseUrl: string;
    amplitudeApiKey?: string;
    gaApiKey?: string;
  }) => {
    if (!config) {
      throw new SyntaxError("Missing or invalid SDK config");
    }

    if (!config.cognitoUserPoolId) {
      throw new SyntaxError(
        `Missing or invalid cognitoUserPoolId param. Expected a string, but recieved ${typeof config.cognitoUserPoolId}`
      );
    }

    if (!config.cognitoClientId) {
      throw new SyntaxError(
        `Missing or invalid cognitoClientId param. Expected a string, but recieved ${typeof config.cognitoClientId}`
      );
    }

    if (!config.cognitoRegion) {
      throw new SyntaxError(
        `Missing or invalid cognitoRegion param. Expected a string, but recieved ${typeof config.cognitoRegion}`
      );
    }

    if (!config.apiVersion) {
      throw new SyntaxError(
        `Missing or invalid apiVersion param. Expected a string, but recieved ${typeof config.apiVersion}`
      );
    }

    if (!config.apiBaseUrl) {
      throw new SyntaxError(
        `Missing or invalid apiBaseUrl param. Expected a string, but recieved ${typeof config.apiBaseUrl}`
      );
    }

    // class configs
    this.cognitoUserPoolId = config.cognitoUserPoolId;
    this.cognitoClientId = config.cognitoClientId;
    this.cognitoRegion = config.cognitoRegion;
    this.apiVersion = config.apiVersion;
    this.apiBaseUrl = config.apiBaseUrl;
    this.amplitudeApiKey = config.amplitudeApiKey;
    this.gaApiKey = config.gaApiKey;

    Amplify.configure({
      Auth: {
        region: this.cognitoRegion,
        userPoolId: this.cognitoUserPoolId,
        userPoolWebClientId: this.cognitoClientId,
        signUpVerificationMethod: "code",
        authenticationFlowType: "USER_SRP_AUTH",
      },
    });

    // build axios api client
    this.buildAxiosClient();

    // build api module classes
    this.buildSdkModules();
  };

  // build axios api client
  buildAxiosClient = () => {
    this.client = axios.create({
      withCredentials: true,
      baseURL: `${this.apiBaseUrl}/v${this.apiVersion}`,
    });

    this.client.interceptors.request.use(
      (config: any) =>
        Auth.currentSession()
          .then((session: any) => {
            const accessToken = session.getAccessToken();
            const jwtToken = accessToken.getJwtToken();
            // const jwtToken = session.accessToken.jwtToken;
            config.headers["Authorization"] = `Bearer ${jwtToken}`;
            return config;
          })
          .catch(() => {
            return config;
          }),
      (error) => Promise.reject(error)
    );

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const status = error.response?.status;
        const message = error.response?.data?.message;

        if (status === 401) {
          // if (typeof this.handleUnauthenticated === "function") {
          //   this.handleUnauthenticated();
          // }
          throw new UnauthorizedError(status, message || "Please login in");
        } else if (status === 403) {
          throw new ForbiddenError(status, message || "You do not have permission to do this");
        } else if (status === 404) {
          throw new NotFoundError(status, message || "Oh oh.. We can't find what you are looking for");
        } else if (status < 500) {
          throw new UserError(status, message || "Something doesn't look right.. Please try again");
        } else {
          throw new ServerError(status, message || "Oh oh.. Something went wrong");
        }
      }
    );
  };

  // build sdk modules ** should be overwriten in the classes extended from this one **
  buildSdkModules = () => {
    // base modules
    // this.analytics = new Analytics(this.gaApiKey, this.amplitudeApiKey);
    this.auth = auth(this.client, this.cognitoClientId);
    this.pages = pages(this.client);
    this.posts = posts(this.client);
    this.users = users(this.client);
    this.files = files(this.client);
    this.emails = emails(this.client);
    this.search = search(this.client);
    this.products = products(this.client);
    this.postTypes = postTypes(this.client);
    this.giveaways = giveaways(this.client);
    this.locations = locations(this.client);
    this.activities = activities(this.client);
    this.transactions = transactions(this.client);
  };
}

export default OutsydeSDK;
