// import { useApp } from 'context';
import Map from "components/Map";
import { useEffect } from "react";
import VideoSection from './Banner';
import Header from "components/Header";
import Footer from "components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import BlogDisplay from "components/BlogPreviews";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (window.document) {
    window.document.body.style.overflowY = "auto";
  }

  return (
    <div className="App">
      <Header />
      <VideoSection />
      <div
        className="mt-5 mb-4 container"
        style={{ width: "100%", textAlign: "left" }}
      >
        <h1 style={{ marginBottom: 2 }}>GUIDES</h1>
        <div style={{ fontSize: 18, maxWidth: 500, lineHeight: 1.2 }}>
          Some text here about what they can get from guides like learn about
          new stuff, find cool shit blah blah blah.
        </div>
      </div>
      <BlogDisplay
        query={{
          limit: 4,
          filter: { postTypeId: ["3f8eef00-20b9-4b73-bf37-c3d78f5a75a6"], activityId: { _not: null } },
        }}
      />
      <div
        className="mt-5 mb-4 container js-ac"
        style={{ width: "100%", flexDirection: "column" }}
      >
        <h1 style={{ marginBottom: 2 }}>LOCATIONS</h1>
        <div
          style={{
            fontSize: 18,
            maxWidth: 500,
            lineHeight: 1.2,
            textAlign: "center",
          }}
        >
          Discover new places to enjoy your favorite activities and places and
          try all your new ones.
        </div>
      </div>
      <Map frozen={true} />
      <div
        className="mt-5 mb-4 container"
        style={{ width: "100%", textAlign: "left" }}
      >
        <h1 style={{ marginBottom: 2 }}>GEAR</h1>
        <div style={{ fontSize: 18, maxWidth: 500, lineHeight: 1.2 }}>
          Find recomendations from your favorite athletes about the best gear.
          Learn about maintaining and upgrading all your stuff..
        </div>
      </div>
      <BlogDisplay
        query={{
          limit: 2,
          filter: { postTypeId: ["8d02fa91-b079-463a-8b72-6770eb8f7526"], activityId: { _not: null } },
        }}
        cardType={3}
      />
      <div
        className="mt-5 mb-4 container"
        style={{ width: "100%", textAlign: "left" }}
      >
        <h1 style={{ marginBottom: 2 }}>HOW TO</h1>
        <div style={{ fontSize: 18, maxWidth: 500, lineHeight: 1.2 }}>
          Some text here about what they can get from HOW TO posts etc like
          learn about new stuff, find cool shit blah blah blah.
        </div>
      </div>
      <BlogDisplay
        query={{
          limit: 4,
          filter: { postTypeId: ["a753fd1f-8843-4565-8c58-2e0b03580699"], activityId: { _not: null } },
        }}
      />
      <div
        className="mt-5 mb-4 container"
        style={{ width: "100%", textAlign: "left" }}
      >
        <h1 style={{ marginBottom: 2 }}>PRODUCT REVIEWS</h1>
        <div style={{ fontSize: 18, maxWidth: 500, lineHeight: 1.2 }}>
          Some text here about what they can get from HOW TO posts etc like
          learn about new stuff, find cool shit blah blah blah.
        </div>
      </div>
      <BlogDisplay
        query={{
          limit: 3,
          filter: { postTypeId: ["8cb27e7e-262a-4659-a3a0-3c865a08f061"], activityId: { _not: null } },
        }}
        sections={true}
        cardType={4}
      />

      <div
        className="mt-5"
        style={{ width: "100%", height: 600, background: "black" }}
      >
        <div className="container pt-5">
          <h1 style={{ marginBottom: 2 }}>OUTSYDE COMMUNITY</h1>
          <div style={{ fontSize: 18, maxWidth: 500, lineHeight: 1.2 }}>
            Stay connect everywhere, find places on the go, learn in the field
            etc
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;

// <div className="container mt-5 jc-ac" style={{ flexDirection: "column" }}>
//   <h1 style={{ marginBottom: 2 }}>STAY UP TO DATE</h1>
//   <div
//     style={{
//       fontSize: 18,
//       maxWidth: 500,
//       lineHeight: 1.2,
//       textAlign: "center",
//     }}
//   >
//     Stay connect everywhere, find places on the go, learn in the field etc
//   </div>
//   <input
//     placeholder="EMAIL"
//     className="mt-3"
//     style={{
//       color: "white",
//       width: "90%",
//       maxWidth: 420,
//       height: 44,
//       background: "transparent",
//       border: "1px solid rgba(255,255,255,.25)",
//       paddingLeft: 12,
//       outline: "none",
//       borderRadius: 0,
//     }}
//   />
//   <div className="btn1 mt-3" style={{ width: "90%", maxWidth: 420 }}>
//     SUBMIT
//   </div>
// </div>

// <div
//   className="mt-5 container"
//   style={{ width: "100%", textAlign: "left" }}
// >
//   <div className="row">
//     <div className="col-6">
//       <h1 style={{ marginBottom: 2 }}>OUTSYDE APP</h1>
//       <div style={{ fontSize: 18, maxWidth: 500, lineHeight: 1.2 }}>
//         Stay connect everywhere, find places on the go, learn in the field
//         etc
//       </div>
//       <div className="btn1 mt-4" style={{ width: 200 }}>
//         DOWNLOAD NOW
//       </div>
//     </div>
//     <div className="col-6 jc-ac">
//       <Phone>
//         <div
//           style={{ width: "100%", height: "100%", background: "black" }}
//         />
//       </Phone>
//     </div>
//   </div>
// </div>
