import { Portal } from 'react-portal';
import CheckBox from 'components/CheckBox';
import InlineModal from 'components/InlineModal';
import { useState, useEffect, useRef } from 'react';
import { BsChevronDown, BsCalendar2Week, BsClock } from 'react-icons/bs';
import { format, setMinutes, getHours, getMinutes, setHours } from 'date-fns';

const SingleSelect = ({ value = {}, onChange = () => {}, options = [], style = {}, placeholder, label }) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option) => {
    return option?.value === value?.value
  }

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
  }, [inputRef, focus, valueKey])

  const handleSelect = (val) => {
    setFocus(false)
    onChange(val)
  }

  return(
    <div className='' style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', right: 8, top: '50%', transform: 'translate(0%, -50%)' }} onClick={() => setFocus(true)}>
        <BsChevronDown style={{ color: '#5c7d6d' }} />
      </div>
      <div ref={inputRef} onClick={() => setFocus(true)} className='fake-input js-ac' style={{ cursor: 'pointer', outline: focus ? '1px solid #5c7d6d' : '', color: value?.label ? '' : 'rgba(255,255,255,.375)', ...style }}>
        {value?.label || placeholder}
      </div>
      <InlineModal open={focus} handleClose={() => setFocus(false)} btnRef={inputRef} full={true} blurStyle={{ zIndex: 1001001 }} style={{ zIndex: 1001002 }}>
        <div className='ps-3 pe-3'>
          {options.map((option, idx) => <OptionItem key={idx} isLast={idx === options?.length - 1} option={option} isSelected={isSelected} handleSelect={handleSelect} />)}
          {options?.length === 0 && (
            <div className='jc-ac mb-2'>No Options</div>
          )}
        </div>
      </InlineModal>
    </div>
  )
}

export default SingleSelect;

const OptionItem = ({ isLast, option, isSelected, handleSelect }) => {
  return(
    <div onClick={() => handleSelect(option)} style={{ width: '100%', color: isSelected(option) ? '#5c7d6d' : 'white', cursor: 'pointer', borderBottom: isLast ? "" : '1px solid rgba(255,255,255,.12)', whiteSpace: 'nowrap' }} className='js-ac truncate1 pt-2 pb-2'>
      {option?.name}
    </div>
  )
}
