import Blog from './pages/Blog';
import Feed from './pages/Feed';
import About from 'pages/About';
import AppContext from './context';
import Listen from './pages/Listen';
import TnC from './pages/Legal/TnC';
import Post from './pages/Feed/Post';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import Landing from './pages/Landing';
import Support from './pages/Support';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Legal from './pages/Legal/Legal';
import Locations from './pages/Locations';
import Location from './pages/Locations/Location';
import BlogPost from './pages/Blog/BlogPost';
import Podcast from './pages/Listen/Podcast';
import LoadingSpinner from 'components/Loader';
import PrivacyPolicy from './pages/Legal/Privacy';
import Saved from './pages/Saved';
import PodcastEpisode from './pages/Listen/Podcast/Episode';
import CreatorSupport from './pages/Support/CreatorSupport';
import AddPost from 'pages/AddPost';
import EditPost from 'pages/EditPost';
import Errors from 'components/Errors';
import Wallet from './pages/Wallet';
import Discord from './pages/Discord';
import Toasts from './components/Toasts';
import Docs from './pages/Docs';
import Login from './pages/Auth/Login';
import Verify from './pages/Auth/Verify';
import Register from './pages/Auth/Register';
import { useState, useEffect } from "react";
import outsyde from "@outsyde/sdk";
import { isBefore, subDays } from 'date-fns';
import {
  createBrowserRouter,
  Route,
  RouterProvider,
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const savedActivites = localStorage.getItem("outsyde_activities");
  const parsedActivitesObj = JSON.parse(savedActivites) || {};
  const parsedActivites = parsedActivitesObj?.acts || [];
  const [acts, setActivities] = useState(parsedActivites);

  const savedPTs = localStorage.getItem("outsyde_post_types");
  const parsedPTsObj = JSON.parse(savedPTs) || {};
  const parsedPTs = parsedPTsObj?.types || [];
  const [postTypes, setPostTypes] = useState(parsedPTs);

  if (window.document) {
    window.document.body.style.backgroundColor = '#19181a';
  }

  const handleActivities = async () => {
    if (!parsedActivitesObj?.date || isBefore(new Date(parsedActivitesObj?.date), subDays(new Date(), 7))) {
      try {
        let data = await outsyde.activities.getAll({ filter: { active: true } });
        let sorted = data?.sort((a, b) => a.name.localeCompare(b.name));
        localStorage.setItem("outsyde_activities", JSON.stringify({ date: new Date(), acts: sorted }))
        setActivities(sorted)
      } catch(err) {
        console.log(err);
      }
    }
  }

  const handlePostTypes = async () => {
    if (!parsedPTsObj?.date || isBefore(new Date(parsedPTsObj?.date), subDays(new Date(), 7))) {
      try {
        let data = await outsyde.postTypes.getAll();
        let sorted = data?.sort((a, b) => a.name.localeCompare(b.name));
        localStorage.setItem("outsyde_post_types", JSON.stringify({ date: new Date(), types: sorted }))
        setPostTypes(sorted)
      } catch(err) {
        console.log(err);
      }
    }
  }

  useEffect(() => {
    handlePostTypes();
    handleActivities();
  }, [])

  const router = createBrowserRouter([
    { path: "/", element: <Landing /> },
    { path: "/docs", element: <Docs /> },
    { path: "/feed", element: <Feed /> },
    { path: "/read", element: <Blog /> },
    { path: "*", element: <NotFound /> },
    { path: "/saved", element: <Saved /> },
    { path: "/legal", element: <Legal /> },
    { path: "/wallet", element: <Wallet /> },
    { path: "/listen", element: <Listen /> },
    { path: "/post/:id", element: <Post /> },
    { path: "/add-post", element: <AddPost /> },
    { path: "/listen/:id", element: <Podcast /> },
    { path: "/about-outsyde", element: <About /> },
    { path: "/profile/:id", element: <Profile /> },
    { path: "/locations", element: <Locations /> },
    { path: "/terms-conditions", element: <TnC /> },
    { path: "/edit-post/:id", element: <EditPost /> },
    { path: "/outsyde-support", element: <Support /> },
    { path: "/contact-outsyde", element: <Contact /> },
    { path: "/outsyde-discord", element: <Discord /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/creator-support", element: <CreatorSupport /> },
    { path: "/listen/:id/:episodeid", element: <PodcastEpisode /> },

    { path: "/login", element: <Login /> },
    { path: "/verify", element: <Verify /> },
    { path: "/register", element: <Register /> },
  ]);

  return (
    <AppContext acts={acts} postTypes={postTypes}>
      <RouterProvider router={router} />
      <LoadingSpinner />
      <Errors />
      <Toasts />
    </AppContext>
  );
}

export default App;

// <Route path="*" element={<NotFound />} />
// <Route exact path="/add-post" element={<AddPost />} />
// <Route exact path="/listen/:id/:episodeid" element={<PodcastEpisode />} />
// <Route exact path="/creator-support" element={<CreatorSupport />} />
// <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
// <Route exact path="/profile/:username" element={<Profile />} />
// <Route exact path="/terms-conditions" element={<TnC />} />
// <Route exact path="/locations/:id" element={<Location />} />
// <Route exact path="/locations" element={<Locations />} />
// <Route exact path="/listen/:id" element={<Podcast />} />
// <Route exact path="/post/:id" element={<Post />} />
// <Route exact path="/contact" element={<Contact />} />
// <Route exact path="/support" element={<Support />} />
// <Route exact path="/listen" element={<Listen />} />
// <Route exact path="/legal" element={<Legal />} />
// <Route exact path="/wallet" element={<Wallet />} />
// <Route exact path="/read" element={<Blog />} />
// <Route exact path="/about" element={<About />} />
// <Route exact path="/feed" element={<Feed />} />
// <Route exact path="/saved" element={<Saved />} />
// <Route exact path="/" element={<Landing />} />

const NotFound = () => {
  return(
    <div style={{ height: '100vh', width: '100vw', flexDirection: 'column' }} className='jb-ac App'>
      <Header />
      <div style={{ color: 'white', fontSize: 32, fontWeight: 700 }}>NOT FOUND</div>
      <Footer />
    </div>
  )
}
