import { PodcastCard1, FeaturedPodcastCard } from 'components/PodcastCards';
import { useNavigate } from 'react-router-dom';
import Filter from 'components/LearnFilterUI';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Pill from 'components/Pill';
import { useEffect } from 'react';
import { format } from 'date-fns';
import { useApp } from 'context';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Listen = () => {
  const { podcasts, handlePodcasts } = useApp();

  useEffect(() => {
    // handlePodcasts()
  }, [])

  if (window.document) {
    window.document.body.style.overflowY = 'auto';
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  //{podcasts?.length > 0 && <PodcastContent podcasts={podcasts} />}
  return(
    <div className='App'>
      <Header />
      <div className='jc-ac' style={{ height: 600, width: '100%' }}>Coming Soon!</div>
      <Footer />
    </div>
  )
}

export default Listen;

const PodcastContent = ({ podcasts = [] }) => {
  let featured = podcasts[0]?.id ? podcasts[0] : {};

  return(
    <>
      <FeaturedPodcastCard podcast={featured} />
      <div className='container mt-4 pb-5'>
        <div className='row'>
          <div className='col-9'>
            <div className='row'>
              {podcasts?.map((podcast, key) => <PodcastCard1 podcast={podcast} key={key} />)}
            </div>
          </div>
          <div className='col-3'>
            <Filter />
          </div>
        </div>
      </div>
    </>
  )
}

const handleDate = (podcast) => {
  if (podcast?.createdAt) {
    return format(new Date(podcast?.createdAt), 'MM/dd/yyyy')
  } else {
    return null;
  }
}
