import outsyde from "@outsyde/sdk";
import Photo from 'components/Viewer/Photo';
import { browserName } from "react-device-detect";
import { useRef, useState, useEffect } from "react";
import { BsPlayFill, BsArrowRepeat } from "react-icons/bs";

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const VideoSection = () => {
  const [videos, setVideos] = useState([]);
  const [selectedPost, setSelectedPost] = useState(3);
  let width = window.innerWidth;
  let _itemWidth = width * .3;
  let itemWidth = Math.max(Math.min(_itemWidth, 393.75), 220);
  let itemHeight = itemWidth * 1.78;

  const handleVideos = async () => {
    let data = await outsyde.posts.getAll({ limit: 7, order: '-createdAt' });
    setVideos(data);
  };

  useEffect(() => {
    handleVideos();
  }, []);

  return (
    <div style={{ position: "relative", height: itemHeight, overflowX: "hidden" }}>
      {videos?.map((post, idx) => (
        <SwitchContent
          key={idx}
          idx={idx}
          post={post}
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          selectedPost={selectedPost}
          setSelectedPost={setSelectedPost}
        />
      ))}
    </div>
  );
};

export default VideoSection;

const SwitchContent = ({ idx, post, itemWidth, itemHeight, selectedPost, setSelectedPost }) => {
  let mainFile = post?.files?.find((pf) => pf?.fileType === 'main');
  let isVideo = mainFile?.contentType?.includes("video");

  if (isVideo) {
    return(
      <VideoItem
        idx={idx}
        post={post}
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
      />
    )
  } else {
    return(
      <PhotoItem
        idx={idx}
        post={post}
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
      />
    )
  }
}

const PhotoItem = ({ post, idx, itemWidth, itemHeight, selectedPost, setSelectedPost }) => {
  let mainFile = post?.files?.find((pf) => pf?.fileType === 'main');

  return(
    <div
      onClick={() => setSelectedPost(idx)}
      style={{
        position: "absolute",
        zIndex: getZIndex(idx, selectedPost),
        left: getPostPosition(idx, selectedPost),
        top: 0,
        transform: `translate(-50%, 0%) scale(${getPostScale(
          idx,
          selectedPost
        )})`,
        width: itemWidth,
        height: itemHeight,
        cursor: "pointer",
        background: "#1f1e20",
        overflow: "hidden",
        textAlign: "left",
        transition: "all 250ms ease",
      }}
      className="shadow-sm"
    >
      <Photo post={post} file={mainFile} />
    </div>
  )
}

const VideoItem = ({ post, idx, itemWidth, itemHeight, selectedPost, setSelectedPost }) => {
  const isSelected = idx === selectedPost;
  const videoRef = useRef(null);

  const smallFile = post?.files?.find((f) => f?.fileType === "small");
  const mainFile = post?.files?.find((f) => f?.fileType === "main");
  const hlsFile = post?.files?.find((f) => f?.fileType === "hls");
  const canHls = !!hlsFile?.filePath && browserName === "Safari";
  const file = canHls ? hlsFile : mainFile;

  const url = `${CDN_ROUTE}${file?.filePath}`;
  const [isPlaying, setIsPlaying] = useState(false);
  const [ended, setEnded] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (isPlaying && !isSelected) {
      setIsPlaying(false);
      videoRef.current?.pause();
      videoRef.current.currentTime = 0;
    }
  }, [selectedPost]);

  const handlePlay = async () => {
    setIsPlaying(true);
    if (!loaded) {
      videoRef.current.src = url;
      videoRef.current.load();
      setLoaded(true)
    }
    if (ended) {
      setEnded(false);
      videoRef.current.currentTime = 0;
    }
    videoRef.current?.play();
  };

  const handleEnd = () => {
    setEnded(true);
    setIsPlaying(false);
  };

  // useEffect(() => {
  //   if (videoRef?.current) {
  //     videoRef.current.src = url;
  //     videoRef.current.load();
  //   }
  // }, []);

  const handleSelect = () => {
    if (!isSelected) {
      setSelectedPost(idx);
      if (!ended) {
        if (!loaded) {
          videoRef.current.src = url;
          videoRef.current.load();
          setLoaded(true)
        }
        videoRef.current?.play();
        setIsPlaying(true);
      }
    }
  };

  return (
    <div
      onClick={handleSelect}
      style={{
        position: "absolute",
        zIndex: getZIndex(idx, selectedPost),
        left: getPostPosition(idx, selectedPost),
        top: 0,
        transform: `translate(-50%, 0%) scale(${getPostScale(
          idx,
          selectedPost
        )})`,
        width: itemWidth,
        height: itemHeight,
        cursor: "pointer",
        background: "#1f1e20",
        overflow: "hidden",
        textAlign: "left",
        transition: "all 250ms ease",
      }}
      className="shadow-sm"
    >
      <video
        muted={true}
        ref={videoRef}
        playsInline="1"
        onEnded={handleEnd}
        preload="auto"
        poster={`${CDN_ROUTE}${smallFile?.filePath}`}
        style={{
          zIndex: 2,
          width: "100%",
          height: "100%",
          objectFit: "contain",
          position: "relative",
        }}
      />
      {isSelected && !isPlaying && (
        <div
          className="jc-ac shadow"
          onClick={handlePlay}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            fontSize: 56,
            paddingLeft: ended ? 0 : 5,
            color: "black",
            transform: "translate(-50%, -50%)",
            width: 90,
            height: 90,
            borderRadius: "50%",
            background: "rgba(194,194,194, .6)",
            zIndex: 3,
          }}
        >
          {ended ? <BsArrowRepeat /> : <BsPlayFill />}
        </div>
      )}
      {!isSelected && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 4,
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,.4)",
          }}
        />
      )}
    </div>
  );
};

const getPostPosition = (idx, selectedPost) => {
  if (idx === selectedPost) {
    return "50%";
  } else if (idx < selectedPost) {
    return `${50 - (selectedPost - idx) * 20}%`;
  } else {
    return `${50 + (idx - selectedPost) * 20}%`;
  }
};

const getPostScale = (idx, selectedPost) => {
  if (idx === selectedPost) {
    return 1;
  } else {
    let diff = Math.abs(idx - selectedPost) / 15;
    return 1 - diff;
  }
};

const getZIndex = (idx, selectedPost) => {
  if (idx === selectedPost) {
    return 10;
  } else {
    let diff = Math.abs(idx - selectedPost);
    return 10 - diff;
  }
};
