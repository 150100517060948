// External Imports
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import { useState, useEffect, useMemo, useRef } from "react";
import Map, { Marker, GeolocateControl, NavigationControl, FullscreenControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from "mapbox-gl";
import { BsX } from 'react-icons/bs';
// import Phone from './Phone';
import {Pill3} from 'components/Pill'
// Internal Imports
// import { useApp } from "context";
import outsyde from "@outsyde/sdk";
import SmallViewer from 'components/SmallViewer'
// import LocationModal from "components/Modals/MapLocationModal";

mapboxgl.workerClass = MapboxWorker;
const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;
const REACT_APP_MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const newOrleans = { lat: 29.9511, lng: -90.0715 };

const MapWrapper = ({ width = '100vw', height = 500, frozen = false, passedLocation = null }) => {
  const mapRef = useRef(null);
  const [locations, setLocations] = useState([]);
  const [locsPending, setLocsPending] = useState(false);
  // const [locationModal, setLocationModal] = useState(false);
  // const [selectedLocation, setSelectedLocation] = useState("");
  const center = newOrleans;
  const toShow = passedLocation ? passedLocation : locations

  const handleLocations = async (bounds) => {
    if (bounds?.south !== undefined) {
      try {
        let data = await outsyde.locations.getAllGeo(bounds);
        let existingIds = locations?.map((l) => l.id);
        let filteredRes = data?.filter((l) => !existingIds?.includes(l.id));
        let allLocs = [...locations, ...filteredRes];
        // let map = mapRef?.current?.getMap();
        // let mapBounds = map?.getBounds();
        // let inView = allLocs?.filter((l) => mapBounds.contains(l?.geometry?.coordinates));
        setLocations(allLocs);
      } catch(err) {
        console.log(err);
        console.log('error getting locations');
      }
    }
  };

  const handleChange = async () => {
    let map = mapRef?.current?.getMap();

    if (!locsPending) {
      setLocsPending(true);
      let newBounds = handleBounds(map)
      await handleLocations(newBounds);
      setLocsPending(false);
    }
  }

  const markers = useMemo(() => toShow.map((l, key) => (<OutsydeMarker key={key} l={l} mapRef={mapRef} />)), [locations]);

  return(
    <Map
      ref={mapRef}
      onLoad={handleChange}
      scrollZoom={frozen ? false : true}
      onMoveEnd={handleChange}
      mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        longitude: center?.lng,
        latitude: center?.lat,
        zoom: 10
      }}
      style={{ width: width, height: height, position: "relative" }}
      mapStyle="mapbox://styles/mapbox/outdoors-v12"
    >
      {markers}
      {!frozen && <GeolocateControl />}
      {!frozen && <NavigationControl />}
      {!frozen && <FullscreenControl />}
    </Map>
  )
}

export default MapWrapper;

const OutsydeMarker = ({ l, mapRef }) => {
  const [rendered, setRendered] = useState(false);
  const [modal, setModal] = useState(false);
  let leaving = l?.leaving;

  useEffect(() => {
    if (leaving) {
      setRendered(false)
    } else {
      setTimeout(() => {
        setRendered(true)
      }, 100)
    }
  }, [leaving])

  const handleClick = () => {
    setModal(true)
    mapRef?.current?.flyTo({center: l?.coords?.coordinates});
  }

  return(
    <>
      <Marker longitude={l?.coords?.coordinates[0]} latitude={l?.coords?.coordinates[1]} anchor="bottom" onClick={handleClick}>
        <img alt='map-marker' style={{ width: rendered ? 32 : 24, opacity: rendered ? 1 : 0, transform: rendered ? 'translate(0px, 0px)' : 'translate(0px, -15px)',  transition: 'all 250ms ease-out' }} src={`${CDN_ROUTE}${l?.locationType?.icon?.filePath}`} />
      </Marker>
      <LocationModal open={modal} setOpen={setModal} location={l} />
    </>
  )
}

const LocationModal = ({ open, setOpen, location }) => {
  const [posts, setPosts] = useState([]);

  const handlePosts = async () => {
    try {
      let data = await outsyde.locations.getPosts(location?.id);
      setPosts(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handlePosts()
      }, 250)
    }

  }, [open])

  // <div style={{ height: 90 }}>
  //   <img
  //     alt=""
  //     preload='auto'
  //     style={{ width: 90 }}
  //     src={`${CDN_ROUTE}${location?.locationType?.icon?.filePath}`}
  //   />
  // </div>

  if (open) {
    return(
      <>
        <div style={{ position: 'absolute', left: 10, top: 10, background: 'black', width: 320, height: 'calc(100% - 20px)' }}>
          <div className="js-as flex-col" style={{ padding: 18 }}>
            <div className='mb-2 jb-ac' style={{ width: '100%' }}>
              <Pill3 title={location?.locationType?.name} className=' ' />
              <div onClick={() => setOpen(false)}><BsX style={{ position: 'absolute', top: 12, right: 12, color: 'rgba(255,255,255,.5)', fontSize: 24 }} /></div>
            </div>
            <div className='mb-2' style={{ fontWeight: 600, color: "white", fontSize: 20 }}>
              {location?.name}
            </div>
            <div style={{ fontWeight: 500, color: "white" }}>
              {location?.description}
            </div>
          </div>
          <SmallViewer posts={posts} width={320} />
        </div>
      </>
    )
  } else {
    return null;
  }
};



// const FooterBtns = ({ location }) => {
//   const geo = location?.geometry?.coordinates || [];
//   const lat = geo[1];
//   const lng = geo[0];
//
//   let hasWeb = !!location?.website;
//   // let hasPhone = !!location?.tel;
//   let count = hasWeb ? 2 : 1;
//   // let count = hasWeb && hasPhone ? 3 : (hasWeb || hasPhone) ? 2 : 1;
//
//   const openCapacitorSite = async (link) => {
//     // await Browser.open({ url: link });
//   };
//
//   let gmurl = `https://maps.google.com?q=${lat},${lng}`
//   // let tel = formatPhoneNumber(location?.tel);
//   //
//   // const handlePhone = async () => {
//   //   window.open(tel);
//   // }
//
//   return(
//     <div style={{ width: '100%', height: 80, borderTop: '1px solid rgba(255,255,255,.15)' }} className='ja-ac'>
//       <div onClick={() => openCapacitorSite(gmurl)} className="location-btn2" style={{ width: `calc(100% / ${count})`}}>
//         Directions
//       </div>
//
//       {location?.website && (
//         <div onClick={() => openCapacitorSite(location?.website)} className="location-btn2" style={{ width: `calc(100% / ${count})`}}>
//           Website
//         </div>
//       )}
//     </div>
//   )
// }

const handleBounds = (map) => {
  let newBounds = map?.getBounds();
  let n = newBounds?._ne?.lat;
  let e = newBounds?._ne?.lng;
  let s = newBounds?._sw?.lat;
  let w = newBounds?._sw?.lng;
  return { north: n, east: e, west: w, south: s }
}
