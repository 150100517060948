const TextArea = ({ value, onChange, placeholder, style = {}, className = '', type = '', label = "" }) => {
  return(
    <div>
      {label && <div style={{ fontSize: 12, color: '#5c7d6d', fontWeight: 600 }}>{label}</div>}
      <textarea type={type} className={className} style={{ paddingTop: 8, height: 200, ...style}} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}

export default TextArea
