import { useNavigate, useLocation } from "react-router";
import { useState, useEffect, useRef } from 'react';
import outsyde from "@outsyde/sdk";

const Search = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const [searchRes, setSearchRes] = useState([]);
  const top = window.innerWidth > 991 ? 113 : 62;

  const handleChange = async (e) => {
    let val = e.target.value;
    setSearchVal(val);
    if (val?.length > 0) {
      let data = await outsyde.search.search(val);
      let p = data?.posts?.map(p => ({ ...p, searchType: "post" }));
      let u = data?.users?.map(p => ({ ...p, searchType: "user" }));
      let l = data?.locations?.map(p => ({ ...p, searchType: "landmark" }));
      setSearchRes([...p, ...u, ...l])
    } else {
      setSearchRes([])
    }
  }

  const handleClick = (sr) => {
    if (sr?.searchType === 'user') {
      navigate(`/profile/${sr?.id}`)
    } else if (sr?.searchType === 'post') {
      navigate(`/post/${sr?.id}`)
    } else if (sr?.searchType === 'landmark') {
      navigate(`/locations/${sr?.id}`)
    }
    setOpen(false)
  }

  if (open) {
    return(
      <div style={{ position: 'fixed', left: 0, top: top, width: '100%', height: `calc(100% - ${top}px)`, background: 'rgba(0,0,0,.75)', padding: 10, zIndex: 1000 }} className='jc-as'>
        <div style={{ left: 0, top: 0, width: '100%', height: '100%', zIndex: 1, position: 'absolute' }} onClick={() => setOpen(false)} />
        <div style={{ width: 600, maxWidth: '100%', zIndex: 2, position: 'relative'  }}>
          <input onChange={handleChange} value={searchVal} style={{ color: 'white', width: '100%', background: 'transparent', border: '1px solid rgba(255,255,255,.12)', outline: 'none', height: 40, paddingLeft: 12 }} placeholder='SEARCH' />
          {searchRes?.map((sr, key) => (
            <div onClick={() => handleClick(sr)} key={key} className='mt-2 pb-2' style={{ borderBottom: '1px solid rgba(255,255,255,.18)', cursor: 'pointer' }}>
              <div className='truncate1'>{sr?.username || sr?.caption || sr?.name}</div>
              <div style={{fontSize: 12, fontWeight: 600, textTransform: 'uppercase' }}>{sr?.searchType}</div>
            </div>
          ))}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default Search;
