import { useApp } from 'context';
import CenterModal from 'components/CenterModal';

const Errors = () => {
  const { error, setError } = useApp();

  const handleDismiss = () => {
    setError('')
  }

  return(
    <CenterModal open={error?.length > 0} handleClose={handleDismiss} blurStyle={{ zIndex: 199999999, background: 'rgba(0,0,0,.5)' }} style={{ zIndex: 200000000 }}>
      <div style={{ color: 'white', width: 500 }} className='p-3'>
        <div className='mb-3' style={{ fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Uh Oh!</div>
        <div>{error}</div>
        <div className='btn1 mt-4' onClick={handleDismiss}>OK</div>
      </div>
    </CenterModal>
  )
}

export default Errors;
