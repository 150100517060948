import CenterModal from 'components/CenterModal';
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  ViberShareButton,
} from "react-share";
import {
  FacebookIcon,
  // FacebookMessengerIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";
import { useApp } from 'context';
import { IoCopyOutline } from 'react-icons/io5'
import { CopyToClipboard } from "react-copy-to-clipboard";

const ShareModal = ({ post, open, handleClose }) => {
  const { addToast } = useApp();

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div className='p-3' style={{ width: 500, maxWidth: window.innerWidth - 20 }}>
        <div style={{ color: 'white', fontWeight: 700, fontSize: 20, lineHeight: 1 }}>Share</div>
        <div style={{ flexWrap: 'wrap' }} className='jc-ac mt-3'>
          <FacebookShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <FacebookIcon size={44} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <LinkedinIcon size={44} round={true} />
          </LinkedinShareButton>
          <RedditShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <RedditIcon size={44} round={true} />
          </RedditShareButton>
          <PinterestShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <PinterestIcon size={44} round={true} />
          </PinterestShareButton>
          <TelegramShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <TelegramIcon size={44} round={true} />
          </TelegramShareButton>
          <TwitterShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <TwitterIcon size={44} round={true} />
          </TwitterShareButton>
          <ViberShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <ViberIcon size={44} round={true} />
          </ViberShareButton>
          <WhatsappShareButton className='m-1' url={`https://outsyde.app/post/${post?.id}`}>
            <WhatsappIcon size={44} round={true} />
          </WhatsappShareButton>
          <CopyToClipboard text={`https://outsyde.app/post/${post?.id}`}>
            <div onClick={() => addToast("Copied!")} className='jc-ac m-1' style={{ width: 44, height: 44, background: '#4f7361', borderRadius: '50%' }}>
              <IoCopyOutline style={{ fontSize: 30, color: 'white' }} />
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </CenterModal>
  )
}

export default ShareModal;

export const ShareProfileModal = ({ profile, open, handleClose }) => {
  const { addToast } = useApp();

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div className='p-3' style={{ width: 500, maxWidth: window.innerWidth - 20 }}>
        <div style={{ color: 'white', fontWeight: 700, fontSize: 20, lineHeight: 1 }}>Share</div>
        <div style={{ flexWrap: 'wrap' }} className='jc-ac mt-3'>
          <FacebookShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <FacebookIcon size={44} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <LinkedinIcon size={44} round={true} />
          </LinkedinShareButton>
          <RedditShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <RedditIcon size={44} round={true} />
          </RedditShareButton>
          <PinterestShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <PinterestIcon size={44} round={true} />
          </PinterestShareButton>
          <TelegramShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <TelegramIcon size={44} round={true} />
          </TelegramShareButton>
          <TwitterShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <TwitterIcon size={44} round={true} />
          </TwitterShareButton>
          <ViberShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <ViberIcon size={44} round={true} />
          </ViberShareButton>
          <WhatsappShareButton className='m-1' url={`https://outsyde.app/profile/${profile?.id}`}>
            <WhatsappIcon size={44} round={true} />
          </WhatsappShareButton>
          <CopyToClipboard text={`https://outsyde.app/profile/${profile?.id}`}>
            <div onClick={() => addToast("Copied!")} className='jc-ac m-1' style={{ width: 44, height: 44, background: '#4f7361', borderRadius: '50%' }}>
              <IoCopyOutline style={{ fontSize: 30, color: 'white' }} />
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </CenterModal>
  )
}
