import { BsCheck } from 'react-icons/bs';

const CheckBox = ({ value = false, onChange = () => {} }) => {
  return(
    <div onClick={onChange} style={{ height: 26, width: 26, borderRadius: '50%', border: '1px solid white', marginRight: 12, background: value ? '#4f7361' : '' }} className='jc-ac'>
      {value && <BsCheck style={{ fontSize: 22 }} />}
    </div>
  )
}

export default CheckBox;
