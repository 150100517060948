// External Imports
import { Axios, AxiosResponse } from "axios";
import { Auth } from "aws-amplify";
import CryptoJS from "crypto-js";

// Internal Imports
// import { NotFoundError } from "../../errors";
import { cleanEmail } from "../utils";

const authAttributes = [
  "address",
  "birthdate",
  "email",
  "family_name",
  "gender",
  "given_name",
  "locale",
  "middle_name",
  "name",
  "nickname",
  "phone_number",
  "picture",
  "preferred_username",
  "profile",
  "updated_at",
  "website",
  "zoneinfo",
];

export interface AuthInterface {
  getCurrentAuth: () => Promise<any>;
  refreshCurrentAuth: () => Promise<any>;
  signin: (email: string, password: string) => Promise<any>;
  signup: (
    email: string,
    password: string,
    username: string
  ) => Promise<any>;
  verifyConfirmationCode: (code: string) => Promise<any>;
  resendConfirmationCode: () => Promise<any>;
  signout: () => Promise<any>;
  signoutAll: () => Promise<any>;
  updateAuthAttribute: (attributes: { [key: string]: string }) => Promise<any>;
  refreshTokens: () => Promise<any>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<any>;
  sendPasswordResetCode: (email: string) => Promise<any>;
  resetPassword: (code: string, newPassword: string) => Promise<any>;
  updateEmail: (newEmail: string) => Promise<any>;
  sendEmailVerificationCode: () => Promise<any>;
  verifyEmail: (code: string) => Promise<any>;
  updatePhoneNumber: (newPhoneNumber: string) => Promise<any>;
  sendPhoneNumberVerificationCode: (newPhoneNumber: string) => Promise<any>;
  verifyPhoneNumber: (code: string) => Promise<any>;
}

export const auth = (
  client: Axios,
  cognitoClientId: string
): AuthInterface => {
  return {
    async getCurrentAuth() {
      return Auth.currentAuthenticatedUser()
        .then((user) => user)
        .catch(() => null);
    },

    async refreshCurrentAuth() {
      return Auth.currentAuthenticatedUser({ bypassCache: false })
        .then((user) => user)
        .catch(() => null);
    },

    async signin(email: string, password: string) {
      const _email = cleanEmail(email);
      var user = await Auth.signIn(_email, password);

      // goes through this flow if first time signing in after password reset
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        user = await Auth.completeNewPassword(user, password);
      }

      // register login with backend
      const { data }: AxiosResponse = await client.post("/auth/login");
      return data;
    },

    async signup(
      email: string,
      password: string,
      username: string
    ) {
      const _email = cleanEmail(email);

      const { user } = await Auth.signUp({
        username: username,
        password,
        attributes: {
          email: _email,
          name: username
        },
        autoSignIn: {
          enabled: true,
        },
      });

      // store credentials to save after user confirmation
      localStorage.setItem("pending-confirmation", "true");
      const credentials = CryptoJS.AES.encrypt(
        JSON.stringify({ email: username, password }),
        cognitoClientId
      ).toString();
      localStorage.setItem("confirmation-credentials", credentials);
      return user;
    },

    async verifyConfirmationCode(code: string) {
      const encryptedCredential = localStorage.getItem("confirmation-credentials") || "";
      const credentialStr = CryptoJS.AES.decrypt(encryptedCredential, cognitoClientId).toString(
        CryptoJS.enc.Utf8
      );

      const { email, password } = JSON.parse(credentialStr); // phoneNumber
      await Auth.confirmSignUp(email, code);
      await Auth.signIn(email, password);
      const { data } = await client.post("/auth/login");
      return data;

      // const { data }: AxiosResponse = await client.post("/users", { email, phoneNumber });
      // localStorage.removeItem("pending-confirmation");
      // localStorage.removeItem("confirmation-credentials");
      // return data;
    },

    resendConfirmationCode() {
      const encryptedCredential = localStorage.getItem("confirmation-credentials") || "";
      const credentialStr = CryptoJS.AES.decrypt(encryptedCredential, cognitoClientId).toString(
        CryptoJS.enc.Utf8
      );

      const { email } = JSON.parse(credentialStr);
      return Auth.resendSignUp(email);
    },

    async signout() {
      await Auth.signOut();
    },

    async signoutAll() {
      await Auth.signOut({ global: true });
    },

    async updateAuthAttribute(attributes: { [key: string]: string }) {
      if (!attributes) {
        throw new Error("Invalid auth attributes");
      }

      const _attributes: { [key: string]: string } = {};
      Object.entries(attributes).forEach(([key, value]) => {
        if (authAttributes.includes(key)) {
          _attributes[key] = value;
        } else {
          console.warn(`Recieved invlaid auth attribute '${key}'. Valid attributes are ${authAttributes}`);
        }
      });

      if (Object.keys(_attributes).length === 0) {
        throw new Error("No valid auth attributes provided");
      }

      const user = await Auth.currentAuthenticatedUser();
      return Auth.updateUserAttributes(user, _attributes);
    },

    refreshTokens() {
      return Auth.currentSession();
    },

    async changePassword(oldPassword: string, newPassword: string) {
      const user = await Auth.currentAuthenticatedUser();
      return Auth.changePassword(user, oldPassword, newPassword);
    },

    sendPasswordResetCode(email: string) {
      const _email = cleanEmail(email);
      const credentials = CryptoJS.AES.encrypt(JSON.stringify({ email: _email }), cognitoClientId).toString();
      localStorage.setItem("password-reset-credentials", credentials);
      return Auth.forgotPassword(_email);
    },

    async resetPassword(code: string, newPassword: string) {
      const encryptedCredential = localStorage.getItem("password-reset-credentials") || "";
      const credentialStr = CryptoJS.AES.decrypt(encryptedCredential, cognitoClientId).toString(
        CryptoJS.enc.Utf8
      );

      const { email } = JSON.parse(credentialStr);
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      const data = await this.signin(email, newPassword);
      localStorage.removeItem("password-reset-credentials");
      return data;
    },

    async updateEmail(newEmail: string) {
      const _email = cleanEmail(newEmail);
      const user = await Auth.currentAuthenticatedUser();
      return Auth.updateUserAttributes(user, { email: _email });
    },

    sendEmailVerificationCode() {
      return Auth.verifyCurrentUserAttribute("email");
    },

    verifyEmail(code: string) {
      return Auth.verifyCurrentUserAttributeSubmit("email", code);
    },

    async updatePhoneNumber(newPhoneNumber: string) {
      const user = await Auth.currentAuthenticatedUser();
      return Auth.updateUserAttributes(user, { phone_number: newPhoneNumber });
    },

    sendPhoneNumberVerificationCode() {
      return Auth.verifyCurrentUserAttribute("phone_number");
    },

    verifyPhoneNumber(code: string) {
      return Auth.verifyCurrentUserAttributeSubmit("phone_number", code);
    },
  };
};
