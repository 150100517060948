import { useState, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { BsX } from 'react-icons/bs';

const InlineModal = ({ open = false, handleClose = () => {}, children, btnRef, full = false, blurStyle = {}, style = {} }) => {
  const modalRef = useRef(null);
  const [modalRect, setModalRect] = useState({});
  const [screenRect, setScreenRect] = useState({});
  const [boundingRect, setBoundingRect] = useState({});

  const getRects = () => {
    if (btnRef?.current) {
      setModalRect(modalRef.current?.getBoundingClientRect())
      setBoundingRect(btnRef.current?.getBoundingClientRect())
      setScreenRect({ width: window.innerWidth, height: window.innerHeight })
    }
  }

  const getPosition = () => {
    if (boundingRect?.x && screenRect?.width && modalRect?.x) {
      let btnX = boundingRect.x;
      let btnY = boundingRect.bottom + 6;
      let centerX = btnX + boundingRect.width / 2;
      let modalWidth = modalRect?.width;
      let modalHeight = modalRect?.height;
      let modalX = centerX - modalWidth / 2;
      let modalY = btnY;
      let screenXSafe = screenRect?.width - 20;
      let screenYSafe = screenRect?.height - 20;
      let tooFarX = modalX + modalWidth > screenXSafe;
      let tooFarY = modalY + modalHeight > screenYSafe;
      let tooNearX = modalX < 0;
      let tooNearY = modalY < 0;
      let safeX = tooFarX ? screenXSafe - modalWidth : tooNearX ? 20 : modalX;
      let safeY = tooFarY ? screenYSafe - modalHeight: tooNearY ? 20 : modalY;
      let maxHeight = window.innerHeight - modalY - 20;

      if (full) {
        return { left: btnX, top: modalY, width: boundingRect?.width, maxHeight: maxHeight, overflowY: 'auto' }
      } else {
        return { left: safeX, top: safeY + 12 }
      }
    } else {
      return { left: '50%', top: '50%', transform: 'translate(-50%, -50%)', opacity: 0 }
    }
  }

  useEffect(() => {
    getRects()
  }, [btnRef, modalRef, open])

  if (open) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', zIndex: 100100, ...blurStyle }} onClick={handleClose} />
        <div ref={modalRef} style={{ position: 'fixed', borderRadius: 0, zIndex: 1001000, whiteSpace: 'nowrap', border: '1px solid rgba(255,255,255,.12)', color: 'white', ...style, ...getPosition() }} className='shadow outsyde-inner-content'>
          {children}
        </div>
      </Portal>
    )
  } else {
    return null;
  }
}

export default InlineModal;
