// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface UsersInterface {
  get: (id: string, query?: Query) => Promise<any>;
  getPosts: (id: string, query?: Query) => Promise<any>;
  getSaved: (id: string, query?: Query) => Promise<any>;
  getFavorites: (id: string, query?: Query) => Promise<any>;
  setFavorites: (id: string, activityIds: string[]) => Promise<any>;
  update: (id: string, values: { [key: string]: any }, query?: Query) => Promise<any>;
  getBlocked: (id: string, query?: Query) => Promise<any>;
  blockUsers: (id: string, userIds: string[]) => Promise<any>;
  unBlockUsers: (id: string, userIds: string[]) => Promise<any>;
  followUser: (id: string, userIds: string[]) => Promise<any>;
  unFollowUser: (id: string, userIds: string[]) => Promise<any>;
  getFollowing: (id: string, query?: Query) => Promise<any>;
  getWallet: (id: string) => Promise<any>;
}

export const users = (client: Axios): UsersInterface => ({
  get: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  update: async (id: string = "current", values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/users/${id}`, query);
    const cleanedValues = cleanValues(values);
    const { data }: AxiosResponse = await client.put(url, cleanedValues);
    return data;
  },

  getPosts: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/posts`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  getSaved: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/saved`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  getFavorites: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/activities`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  setFavorites: async (id: string = "current", activityIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/users/${id}/activities/set`, { ids: activityIds });
    return data;
  },

  getBlocked: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/blocked`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  blockUsers: async (id: string = "current", userIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/users/${id}/blocked/add`, { ids: userIds });
    return data;
  },

  unBlockUsers: async (id: string = "current", userIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/users/${id}/blocked/remove`, { ids: userIds });
    return data;
  },

  followUser: async (id: string = "current", userIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/users/${id}/follow/add`, { ids: userIds });
    return data;
  },

  unFollowUser: async (id: string = "current", userIds: string[]) => {
    const { data }: AxiosResponse = await client.post(`/users/${id}/follow/remove`, { ids: userIds });
    return data;
  },

  getFollowing: async (id: string = "current", query?: Query) => {
    const url = addUrlQuery(`/users/${id}/follow`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  getWallet: async (id: string = "current") => {
    const { data }: AxiosResponse = await client.get(`/users/${id}/wallet`);
    return data;
  },
});
