import { useNavigate } from "react-router";
import { useApp } from 'context';
import { useState, useEffect } from 'react';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';

const Register = () => {
  const navigate = useNavigate();
  const { setLoading, setError, handleLogout, user } = useApp()
  const [terms, setTerms] = useState(true);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (user?.id) {
      handleLogout();
    }
  }, [])

  const handleRegister = async () => {
    if (!terms) {
      setError('You must agree to our terms to register')
    } else {
      try {
        setLoading(true);
        await outsyde.auth.signup(email, password, username);
        navigate('/verify');
        setLoading(false);
      } catch(err) {
        setLoading(false);
        setError(err?.message || 'An error occured, please try again')
      }
    }
  };
  //<div style={{ fontSize: 64, fontWeight: 800, color: '#5c7d6d', opacity: 0.25 }}>OUTSYDE</div>
  return(
    <div className='jc-ac flex-col' style={{ width: '100vw', height: '100vh' }}>
      <div style={{ width: '100%', maxWidth: 500, background: 'black', padding: 20, position: 'relative', zIndex: 2 }} className='shadow'>
        <div className='mb-2' style={{ fontSize: 32, fontWeight: 700, color: "#5c7d6d", letterSpacing: '0.35mm' }}>JOIN OUTSYDE</div>
        <Input placeholder='USERNAME' value={username} onChange={setUsername} />
        <Input placeholder='EMAIL' value={email} onChange={setEmail} style={{ marginTop: 12 }} />
        <Input placeholder='PASSWORD' value={password} onChange={setPassword} type='password' style={{ marginTop: 12 }} />
        <div style={{ height: 38, marginTop: 24 }} className="btn1" onClick={handleRegister}>SIGN UP</div>
        <div style={{ marginTop: 12 }} className="outsyde-text-btn" onClick={() => navigate("/login")}>LOGIN</div>
      </div>
    </div>
  )
}

export default Register;
