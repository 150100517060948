import UserSection from 'components/UserSection';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';
import Pill from 'components/Pill';
import { handleDate } from 'utils';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

export const PodcastCard1 = ({ podcast }) => {
  const navigate = useNavigate();
  const [ref, { width }] = useMeasure();

  return(
    <div className='col-12 col-md-6 mb-3'>
      <div ref={ref} onClick={() => navigate(`/listen/${podcast?.slug}`)} style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <img alt='' src={`${CDN_ROUTE}${podcast?.coverPhoto}`} style={{ width: width, height: width, objectFit: 'cover' }} />
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <Pill title={podcast?.activity?.name} />
          </div>
          <div className='truncate1 mb-1' style={{ fontWeight: 700, lineHeight: 1.5, height: 24 }}>{podcast?.name}</div>
          <div className='truncate3' style={{ lineHeight: 1.25, height: 60 }}>{podcast?.description}</div>
          <UserSection user={podcast?.user} className="mt-3" />
        </div>
      </div>
    </div>
  )
}

export const FeaturedPodcastCard = ({ podcast }) => {
  const navigate = useNavigate();

  if (podcast?.id) {
    return(
      <div className='container'>
        <div className='row pt-4' onClick={() => navigate(`/listen/${podcast?.slug}`)}>
          <div className='col-6' style={{ paddingRight: 0, cursor: 'pointer' }}>
            <div style={{ borderTop: '1px solid rgba(255,255,255,.12)', borderLeft: '1px solid rgba(255,255,255,.12)', borderBottom: '1px solid rgba(255,255,255,.12)', height: 450, overflow: 'hidden' }}>
              <img alt='' src={`${CDN_ROUTE}${podcast?.coverPhoto}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          </div>
          <div className='col-6' style={{ paddingLeft: 0, cursor: 'pointer' }}>
            <div style={{ borderTop: '1px solid rgba(255,255,255,.12)', background: '#1f1e20', borderRight: '1px solid rgba(255,255,255,.12)', borderBottom: '1px solid rgba(255,255,255,.12)', height: 450, flexDirection: 'column', width: '100%' }} className='p-3 jb-as'>
              <div style={{width: '100%'}}>
                <div className='d-flex mb-2'>
                  <Pill title={podcast?.activity?.name} />
                </div>
                <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.25, fontSize: 32 }}>{podcast?.name}</div>
                <div className='truncate3' style={{}}>{podcast?.description}</div>
                <UserSection user={podcast?.user} className="mt-3" />
              </div>
              <div style={{width: '100%'}}>
                <div className='jb-ac mb-1'>
                  <div style={{ fontWeight: 600 }}>EPISODES ({podcast?.episodes?.length})</div>
                  <div style={{ fontWeight: 700, color: '#4f7361', fontSize: 14 }}>SEE ALL</div>
                </div>
                <FeaturedEpisodes podcast={podcast} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export const FeaturedPodcastCard2 = ({ podcast }) => {
  const navigate = useNavigate();
  const [ref, { width }] = useMeasure();
  const imgSize = width * .40

  if (podcast?.id) {
    return(
      <div ref={ref} className='js-as mb-4'>
        <img alt='' src={`${CDN_ROUTE}${podcast?.coverPhoto}`} style={{ width: imgSize, height: imgSize, objectFit: 'cover' }} />
        <div style={{ height: imgSize, flexDirection: 'column' }} className='ps-4 pe-4 jb-as'>
          <div>
            <div className='d-flex mb-2'>
              <Pill title={podcast?.activity?.name} />
            </div>
            <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.25, fontSize: 32 }}>{podcast?.name}</div>
            <div className='truncate3 mt-2'>{podcast?.description}</div>
          </div>
          <UserSection user={podcast?.user} className="mb-1" />
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export const FeaturedEpisodeCard1 = ({ episode, podcast }) => {
  const navigate = useNavigate();

  if (episode?.id) {
    return(
      <div className='container'>
        <div className='row pt-4' onClick={() => navigate(`/listen/${podcast?.slug}/${episode?.id}`)}>
          <div className='col-6' style={{ paddingRight: 0, cursor: 'pointer' }}>
            <div style={{ borderTop: '1px solid rgba(255,255,255,.12)', borderLeft: '1px solid rgba(255,255,255,.12)', borderBottom: '1px solid rgba(255,255,255,.12)', height: 450, overflow: 'hidden' }}>
              <img alt='' src={`${CDN_ROUTE}${episode?.coverPhoto}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
            </div>
          </div>
          <div className='col-6' style={{ paddingLeft: 0, cursor: 'pointer' }}>
            <div style={{ borderTop: '1px solid rgba(255,255,255,.12)', background: '#1f1e20', borderRight: '1px solid rgba(255,255,255,.12)', borderBottom: '1px solid rgba(255,255,255,.12)', height: 450, flexDirection: 'column', width: '100%' }} className='p-3 jb-as'>
              <div style={{width: '100%'}}>
                <div className='d-flex mb-2'>
                  <Pill title={podcast?.activity?.name} />
                </div>
                <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.25, fontSize: 32 }}>{episode?.name}</div>
                <div className='truncate3' style={{}}>{episode?.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

const FeaturedEpisodes = ({ podcast }) => {
  let toShow = podcast?.episodes?.filter((ep, idx) => idx < 4);
  const [ref, { width }] = useMeasure();

  return(
    <div className='row'>
      {toShow?.map((episode, key) => (
        <div key={key} className='col-3'>
          <div ref={ref} style={{ width: '100%' }}>
            <img alt='' src={`${CDN_ROUTE}${episode?.coverPhoto}`} style={{ width: width, height: width - 10, objectFit: 'cover' }} />
            <div style={{ fontSize: 12, fontWeight: 600 }} className='truncate1'>{episode?.name}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export const PodcastEpisodeCard1 = ({ podcast, episode }) => {
  const navigate = useNavigate();
  const [ref, { width }] = useMeasure();
  const imgSize = width * .2

  return(
    <div className='mb-3' style={{ width: '100%' }}>
      <div ref={ref} onClick={() => navigate(`/listen/${podcast?.slug}/${episode?.id}`)} style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='js-as shadow-sm'>
        <img alt='' src={`${CDN_ROUTE}${episode?.coverPhoto}`} style={{ width: imgSize, height: imgSize, objectFit: 'cover' }} />
        <div className='p-3'>
          <div className='truncate1' style={{ fontWeight: 700, lineHeight: 1.5 }}>{episode?.name}</div>
          <div className='truncate4 mt-2' style={{ lineHeight: 1.25 }}>{episode?.description}</div>
        </div>
      </div>
    </div>
  )
}
