// External Imports
import { Axios } from "axios";

// Internal Imports
import { cleanValues } from "../utils";

export interface EmailsInterface {
  sendDynamic: (values: { [key: string]: any }) => Promise<any>;
  sendCreatorWelcome: (values: { [key: string]: any }) => Promise<any>;
}

export const emails = (client: Axios): EmailsInterface => ({
  sendDynamic: async (values: { [key: string]: any }) => {
    const cleanedValues = cleanValues(values);
    const { data } = await client.post('emails/dynamic', cleanedValues);
    return data;
  },

  sendCreatorWelcome: async (values: { [key: string]: any }) => {
    const cleanedValues = cleanValues(values);
    const { data } = await client.post('emails/creator-welcome', cleanedValues);
    return data;
  }
});
