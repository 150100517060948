import LoggedIn from './LoggedIn';
import NotLoggedIn from './NotLoggedIn';
import { useState, useEffect, useRef } from 'react';
import { useApp } from 'context';
import { Transition } from 'react-transition-group';

const Menu = ({ btnRef, open, handleClose }) => {
  const { user } = useApp();
  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  const nodeRef3 = useRef(null);

  if (user?.id) {
    return(
      <>
        <Transition nodeRef={nodeRef2} in={open} timeout={300}>
          {state => (
            <div ref={nodeRef2} onClick={handleClose} style={{ ...defaultStyle2, ...transitionStyles2[state] }}/>
          )}
        </Transition>
        <Transition nodeRef={nodeRef} in={open} timeout={300}>
          {state => (
            <div ref={nodeRef} className='outsyde-inner-content shadow' style={{ ...defaultStyle, ...transitionStyles[state] }}>
              <LoggedIn open={open} user={user} handleClose={handleClose} />
            </div>
          )}
        </Transition>
      </>
    )
  } else {
    return (
      <Transition nodeRef={nodeRef} in={open} timeout={300}>
        {state => (
          <div ref={nodeRef3} className='jc-ac' style={{ ...defaultStyle3, ...transitionStyles3[state] }}>
              <div style={{ width: '100%', height: '100%', position: 'fixed', left: 0, top: 0, zIndex: 1 }} onClick={handleClose} />
              <NotLoggedIn handleClose={handleClose} />
            </div>
          )}
      </Transition>
    )
  }
}

export default Menu;

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  transform: "translate(110%, 0%)",
  width: 400, position: 'fixed', right: 0, top: 0, height: '100vh', zIndex: 100000, maxWidth: "80%"
}

const transitionStyles = {
  entering: { transform: "translate(0%, 0%)" },
  entered:  { transform: "translate(0%, 0%)" },
  exiting:  { transform: "translate(110%, 0%)" },
  exited:  { transform: "translate(110%, 0%)" },
};

const defaultStyle2 = {
  transition: `all 300ms ease-in-out`,
  opacity: 0,
  zIndex: -10,
  width: '100%', height: '100%', position: 'fixed', left: 0, top: 0, zIndex: 100000, background: 'rgba(0,0,0,.75)'
}

const transitionStyles2 = {
  entering: { opacity: 1, zIndex: 100000, },
  entered:  { opacity: 1, zIndex: 100000, },
  exiting:  { opacity: 0, zIndex: -10, },
  exited:  { opacity: 0, zIndex: -10, },
};

const defaultStyle3 = {
  transition: `all 300ms ease-in-out`,
  opacity: 0,
  zIndex: -10,
  position: 'fixed', left: 0, top: 0, width: '100%', height: '100vh', background: 'rgba(0,0,0,.75)', padding: 10, zIndex: 1000, flexDirection: 'column'
}

const transitionStyles3 = {
  entering: { opacity: 1, zIndex: 100000, },
  entered:  { opacity: 1, zIndex: 100000, },
  exiting:  { opacity: 0, zIndex: -10, },
  exited:  { opacity: 0, zIndex: -10, },
};
