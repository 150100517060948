// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface ActivitiesInterface {
  getAll: (query?: Query) => Promise<any>;
}

export const activities = (client: Axios): ActivitiesInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery(`/activities`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  }
});
