export const getUniqueArrayValues = (arr, matchKey) => {
    let distinct = [];

    for (var i = 0; i < arr.length; i++)
    //eslint-disable-next-line
    if (distinct?.filter((d) => d[matchKey] === arr[i]?.[matchKey])?.length < 1) {
        distinct.push(arr[i])
    }
    return distinct;
}
