import { useState } from "react";
// import { updatePost } from 'api/Posts';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Photo = ({ post, file }) => {
  let path = `${CDN_ROUTE}${file?.filePath}`
  let microFile = post?.files?.find((f) => f?.fileType === 'small');
  let microPath = `${CDN_ROUTE}${microFile?.filePath}`
  let smallFile = post?.files?.find((f) => f?.fileType === 'small');
  let smallPath = `${CDN_ROUTE}${smallFile?.filePath}`
  const [errorPath, setErrorPath] = useState("");

  const createPostError = () => {
    setErrorPath("/assets/imgs/errorPhoto.png");
  };

  return (
    <>
      {microFile && (
        <img
          alt=""
          src={microPath}
          draggable={false}
          style={{ width: "100%", height: "100%", objectFit: "cover", position: 'absolute', zIndex: 1, left: 0, top: 0 }}
        />
      )}
      {smallFile && (
        <img
          alt=""
          src={smallPath}
          draggable={false}
          style={{ width: "100%", height: "100%", objectFit: "cover", position: 'absolute', zIndex: 2, left: 0, top: 0 }}
        />
      )}
      <img
        alt=""
        draggable={false}
        src={errorPath || path}
        onError={createPostError}
        style={{ width: "100%", height: "100%", objectFit: "cover", position: 'relative', zIndex: 3 }}
      />
    </>
  );
};

export default Photo;
