import { useNavigate } from "react-router";
import { useApp } from 'context';
import { useState, useEffect } from 'react';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';

const Login = () => {
  const navigate = useNavigate();
  const { setLoading, setError, setUser, user, handleLogout } = useApp()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (user?.id) {
      handleLogout();
    }
  }, [])

  const handleLogin = async () => {
    if (username?.length < 1) {
      setError("Username cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else {
      try {
        setLoading(true);
        localStorage.setItem("outsyde-remember", JSON.stringify(username));
        let data = await outsyde.auth.signin(username, password);
        setUser(data);
        localStorage.setItem("outsyde_user", JSON.stringify(data));
        setLoading(false);
        navigate("/feed");
      } catch (err) {
        setLoading(false);
        setError(err?.message || "We couldn't log you in with those credentials!");
      }
    }
  };

  return(
    <div className='jc-ac' style={{ width: '100vw', height: '100vh' }}>
      <div style={{ width: '100%', maxWidth: 500, background: 'black', padding: 20, position: 'relative', zIndex: 2 }} className='shadow'>
        <div className='mb-2' style={{ fontSize: 32, fontWeight: 700, color: "#5c7d6d", letterSpacing: '0.35mm' }}>SIGN IN</div>
        <Input placeholder='USERNAME' value={username} onChange={setUsername} />
        <Input placeholder='PASSWORD' value={password} onChange={setPassword} type='password' style={{ marginTop: 12 }} />
        <div style={{ height: 38, marginTop: 24 }} className="btn1" onClick={handleLogin}>DONE</div>
        <div style={{ marginTop: 12 }} className="outsyde-text-btn" onClick={() => navigate('/register')}>SIGN UP</div>
      </div>
    </div>
  )
}

export default Login;
