import Map from '../../components/Map';
import Header from '../../components/Header';
import { useState, useEffect } from 'react';
import outsyde from "@outsyde/sdk";

const Locations = () => {
  if (window.document) {
    window.document.body.style.overflowY = 'hidden';
  }

  // const [acts, setActivities] = useState([]);
  let contentHeight = window.innerHeight - 113;
  //
  // const handleActivities = async () => {
  //   let data = await outsyde.activities.get();
  //   let sorted = data?.sort((a, b) => a.name.localeCompare(b.name));
  //   setActivities(sorted);
  // }
  //
  // useEffect(() => {
  //   handleActivities()
  // }, [])

  return(
    <div className='App'>
      <Header />
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <Map height={contentHeight} />
      </div>
    </div>
  )
}

export default Locations;
