import UserSection from 'components/UserSection';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import outsyde from "@outsyde/sdk";
import {format} from 'date-fns';
import { PodcastEpisodeCard1, FeaturedPodcastCard2 } from 'components/PodcastCards'
const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Podcast = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [podcast, setPodcast] = useState({});
  const sortedEpisodes = podcast?.episodes?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)) || []
  const featured = sortedEpisodes[0] || {};

  const handlePodcast = async () => {
    try {
      let data = await outsyde.podcasts.getOneBySlug(id);
      setPodcast(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handlePodcast()
  }, [id])

  return(
    <div className='App'>
      <Header />
      <div className='container mt-4 pb-5 jc-as'>
        <div style={{ maxWidth: 1000 }}>
          <FeaturedPodcastCard2 podcast={podcast} />
          <Episodes podcast={podcast} sortedEpisodes={sortedEpisodes} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Podcast;

const Episodes = ({ podcast }) => {
  let toShow = podcast?.episodes || [];
  const navigate = useNavigate();

  return(
    <div className='row'>
      {toShow?.map((episode, key) => (
        <PodcastEpisodeCard1 key={key} podcast={podcast} episode={episode} />
      ))}
    </div>
  )
}

const handleDate = (podcast) => {
  if (podcast?.createdAt) {
    return format(new Date(podcast?.createdAt), 'MM/dd/yyyy')
  } else {
    return null;
  }
}
