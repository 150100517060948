import { useState, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { BsX } from 'react-icons/bs';
import { useScrollBlock } from 'hooks/useScrollBlock';

const CenterModal = ({ open = false, handleClose = () => {}, children, style = {}, blurStyle = {} }) => {
  const { blockScroll, allowScroll } = useScrollBlock();

  useEffect(() => {
    if (open) {
      blockScroll()
    } else {
      allowScroll()
    }
  }, [open])

  if (open) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: 1000000, background: 'rgba(0,0,0,.025)', ...blurStyle }} onClick={handleClose} />
        <div style={{ position: 'fixed', background: 'black', zIndex: 1000001, left: '50%', top: '50%', transform: 'translate(-50%, -50%)', ...style }} className='shadow'>
          <div onClick={handleClose}><BsX style={{ position: 'absolute', top: 12, right: 12, color: 'rgba(255,255,255,.5)', fontSize: 24 }} /></div>
          {children}
        </div>
      </Portal>
    )
  } else {
    return null;
  }
}

export default CenterModal;
