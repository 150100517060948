import { useApp } from 'context';

const Pill = ({ post, className = "me-2", bg = "#5c7d6d", style = {} }) => {
  const { getActivity, updateFilter } = useApp();
  const act = getActivity(post);
  const { name, id } = act;

  if (name) {
    return <a href={`/read?activities=${id}`} onClick={() => updateFilter({ activities: [id] })} className={className} style={{ cursor: 'pointer', lineHeight: 1, color: '#4f7361', fontSize: 12, fontWeight: 700, textTransform: 'uppercase', textDecoration: 'none', ...style }}>{name}</a>
  } else {
    return null;
  }
}

export default Pill;

export const Pill2 = ({ post, className = "me-2", bg = "#97477D", style = {} }) => {
  const { getPostType } = useApp();
  const type = getPostType(post);
  const { name, id } = type

  if (name) {
    return <a href={`/read?type=${id}`} className={className} style={{ cursor: 'pointer', lineHeight: 1, color: '#7d5a71', fontSize: 12, fontWeight: 700, textTransform: 'uppercase', textDecoration: 'none', ...style }}>{name}</a>
  } else {
    return null;
  }
}

export const Pill3 = ({ title, className = "ms-2", bg = "#976147", style = {} }) => {
  if (title) {
    return <div className={className} style={{ cursor: 'pointer', lineHeight: 1, color: '#976147', fontSize: 12, fontWeight: 700, textTransform: 'uppercase', textDecoration: 'none', ...style }}>{title}</div>
  } else {
    return null;
  }
}

//orginal #4f7361

//#5b4d69
//5c7d6d
//335242
//#58665f

//purple
//#7e6596
