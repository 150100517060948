import { ProductCard1 } from "components/ProductCards";

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Title = ({ section }) => {
  return (
    <div
      className="mb-1 mt-2"
      style={{
        color: "white",
        // fontSize: 32,
        fontWeight: 600,
        textAlign: "left",
        lineHeight: 1.2,
      }}
    >
      {section?.content1}
    </div>
  );
};

const SubTitle = ({ section }) => {
  return (
    <div
      className="mb-1 mt-2"
      style={{
        color: "white",
        // fontSize: 24,
        textAlign: "left",
        fontWeight: 600,
      }}
    >
      {section?.content1}
    </div>
  );
};

const SubSubTitle = ({ section }) => {
  return (
    <div
      className="mb-1 mt-2"
      style={{
        color: "white",
        // fontSize: 20,
        textAlign: "left",
        fontWeight: 600,
      }}
    >
      {section?.content1}
    </div>
  );
};

const Paragraph = ({ section }) => {
  return (
    <span style={{ color: "white", textAlign: "left" }}>
      {section?.content1}
    </span>
  );
};

const Image = ({ section }) => {
  const handleImgClick = () => {
    if (section?.href) {
      var win = window.open(section?.href, "_blank");
      win.focus();
    }
  };

  return (
    <img
      onClick={handleImgClick}
      className="mb-1 mt-2"
      alt=""
      style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
      src={`${CDN_ROUTE}${section?.content1}`}
    />
  );
};

const Spacer = ({ section }) => {
  let type = section?.type;
  let isLG = type === "Spacer LG" || type === "Spacer";
  let isMD = type === "Spacer MD";
  let windowWidth = window.innerWidth;
  let small = windowWidth < 992;
  let height = small ? 12 : isLG ? 50 : isMD ? 30 : 12;

  return (
    <div
      className="blog-item-hover"
      style={{ width: "100%", height: height }}
    />
  );
};

const Line = () => {
  return (
    <div
      className="blog-item-hover mt-3 mb-3"
      style={{ width: "100%", height: 1, background: "rgba(255,255,255,.25)" }}
    />
  );
};

const Product = ({ section }) => {
  const products = section?.products;

  return (
    <div
      className="row blog-item-hover mb-2 mt-3"
      style={{ cursor: "pointer" }}
    >
      {products?.map((p, key) => (
        <div key={key} className="col-12 col-xl-6">
          <ProductCard1 product={p} key={key} />
        </div>
      ))}
    </div>
  );
};

const ImageText = ({ blog, section, setSelectedBlog }) => {
  const handleImgClick = () => {
    if (section?.href) {
      var win = window.open(section?.href, "_blank");
      win.focus();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <img
            onClick={handleImgClick}
            className="mb-2 mt-2"
            alt=""
            style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
            src={`${CDN_ROUTE}${section?.content1}`}
          />
        </div>
        <div className="col-12 col-xl-6 pt-2">
          <span
            className="blog-item-hover"
            style={{ color: "white", cursor: "pointer" }}
          >
            {section?.content3}
          </span>
        </div>
      </div>
    </>
  );
};

const TextImage = ({ blog, section, setSelectedBlog }) => {
  const handleImgClick = () => {
    if (section?.href) {
      var win = window.open(section?.href, "_blank");
      win.focus();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6 pt-2">
          <span
            className="blog-item-hover"
            style={{ color: "white", cursor: "pointer" }}
          >
            {section?.content3}
          </span>
        </div>
        <div className="col-12 col-xl-6">
          <img
            onClick={handleImgClick}
            className="mb-2 mt-2"
            alt=""
            style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
            src={`${CDN_ROUTE}${section?.content1}`}
          />
        </div>
      </div>
    </>
  );
};

const TitleTextImage = ({ section }) => {
  const handleImgClick = () => {
    if (section?.href) {
      var win = window.open(section?.href, "_blank");
      win.focus();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div
            className="blog-item-hover mb-1 mt-2"
            style={{
              color: "white",
              // fontSize: 24,
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            {section?.content2}
          </div>
          <span
            className="blog-item-hover"
            style={{ color: "white", cursor: "pointer" }}
          >
            {section?.content3}
          </span>
        </div>
        <div className="col-12 col-xl-6">
          <img
            onClick={handleImgClick}
            className="mb-2 mt-2"
            alt=""
            style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
            src={`${CDN_ROUTE}${section?.content1}`}
          />
        </div>
      </div>
    </>
  );
};

const ImageTitleText = ({ section }) => {
  const handleImgClick = () => {
    if (section?.href) {
      var win = window.open(section?.href, "_blank");
      win.focus();
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-xl-6">
          <img
            onClick={handleImgClick}
            className="mb-2 mt-2"
            alt=""
            style={{ width: "100%", objectFit: "cover", cursor: "pointer" }}
            src={`${CDN_ROUTE}${section?.content1}`}
          />
        </div>
        <div className="col-12 col-xl-6">
          <div
            className="blog-item-hover mb-1 mt-2"
            style={{
              color: "white",
              // fontSize: 24,
              cursor: "pointer",
              fontWeight: 600,
            }}
          >
            {section?.content2}
          </div>
          <span
            className="blog-item-hover"
            style={{ color: "white", cursor: "pointer" }}
          >
            {section?.content3}
          </span>
        </div>
      </div>
    </>
  );
};

const Link = ({ blog, section }) => {
  const lastP = blog?.sections?.find(
    (bs) => bs?.position === section?.position - 1 && bs?.type === "Paragraph"
  );
  const nextP = blog?.sections?.find(
    (bs) => bs?.position === section?.position + 1 && bs?.type === "Paragraph"
  );
  const hasLast = lastP?.id;
  const hasNext = nextP?.id;
  const margin =
    hasLast && hasNext ? "ms-1 me-1" : hasLast ? "ms-1" : hasNext ? "me-1" : "";
  const href = section?.href || null;
  const content = section?.content1;

  if (content) {
    if (href) {
      return (
        <a
          href={section?.href}
          target="_blank"
          rel="noopener noreferrer"
          className={`blog-item-hover ${margin}`}
          style={{ color: "#4f7361", cursor: "pointer", fontWeight: 600 }}
        >
          {content}
        </a>
      );
    } else {
      return (
        <div
          className={`blog-item-hover ${margin}`}
          style={{ color: "#4f7361", cursor: "pointer", fontWeight: 600 }}
        >
          {content}
        </div>
      );
    }
  } else {
    return null;
  }
};

const Video = ({ blog, section }) => {
  const path = section?.content1 || null;
  const width = section?.content2 ? `${section?.content2}px` : "100%";
  const height = section?.content3 ? `${section?.content3}px` : 562;

  if (path) {
    return (
      <div style={{ width: width, height: height }}>
        <iframe
          className="video mb-2 mt-2"
          title="Youtube player"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`${path}/autoplay=0`}
        ></iframe>
      </div>
    );
  } else {
    return null;
  }
};

const Bullet = ({ blog, section }) => {
  let content = section?.content || null;

  if (content) {
    return (
      <div
        className="blog-item-hover js-ac mt-1"
        style={{ color: "white", cursor: "pointer" }}
      >
        <div
          style={{
            width: 4,
            height: 4,
            border: "50%",
            background: "#4f7361",
            borderRadius: "50%",
            marginRight: 8,
          }}
        />
        {section?.content1}
      </div>
    );
  } else {
    return null;
  }
};

const Embed = ({ blog, section }) => {
  const html = section?.content1 || null;
  const width = section?.content2 ? `${section?.content2}px` : "100%";
  const height = section?.content3 ? `${section?.content3}px` : 562;

  if (html) {
    return (
      <div
        className="blog-item-hover mb-3 mt-2 jc-ac"
        style={{
          color: "white",
          // fontSize: 32,
          cursor: "pointer",
          fontWeight: 600,
          minHeight: 30,
          position: "relative",
          width: "100%",
        }}
      >
        <div
          style={{ width: width, height: height, position: "relative" }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    );
  } else {
    return null;
  }
};

const isSpacer = (section) => {
  let sTypes = ["Spacer", "Spacer LG", "Spacer MD", "Spacer SM"];
  if (sTypes?.includes(section?.sectionType)) {
    return true;
  }
};

export const GetSection = ({ section, blog }) => {
  if (section?.sectionType === "Title") {
    return <Title section={section} />;
  } else if (section?.sectionType === "Subtitle") {
    return <SubTitle section={section} />;
  } else if (section?.sectionType === "SubSubtitle") {
    return <SubSubTitle section={section} />;
  } else if (section?.sectionType === "Paragraph") {
    return <Paragraph section={section} />;
  } else if (section?.sectionType === "Image") {
    return <Image section={section} />;
  } else if (isSpacer(section)) {
    return <Spacer section={section} />;
  } else if (section?.sectionType === "Link") {
    return <Link section={section} blog={blog} />;
  } else if (section?.sectionType === "Line") {
    return <Line section={section} />;
  } else if (section?.sectionType === "Product") {
    return <Product section={section} />;
  } else if (section?.sectionType === "ImageTitleText") {
    return <ImageTitleText section={section} />;
  } else if (section?.sectionType === "TitleTextImage") {
    return <TitleTextImage section={section} />;
  } else if (section?.sectionType === "ImageText") {
    return <ImageText section={section} />;
  } else if (section?.sectionType === "TextImage") {
    return <TextImage section={section} />;
  } else if (section?.sectionType === "Video") {
    return <Video section={section} />;
  } else if (section?.sectionType === "Embed") {
    return <Embed section={section} />;
  } else if (section?.sectionType === "Bullet") {
    return <Bullet section={section} />;
  } else {
    return null;
  }
};
