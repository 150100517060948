import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';
import Select from 'components/Select';
import { BsCheck } from 'react-icons/bs';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import CenterModal from 'components/CenterModal';
import GeoCode from 'components/GeoCode';

const CreateLocationModal = ({ open, onCreate, handleClose }) => {
  const { setError, setLoading } = useApp();
  const [location, setLocation] = useState({});
  const [useAddress, setUseAddress] = useState(false);
  const [locationTypes, setLocationTypes] = useState([]);
  const formattedTypes = locationTypes?.map((lt) => ({ ...lt, label: lt?.name, value: lt?.id }))
  const address = location?.address || {};

  const handleLocationTypes = async () => {
    try {
      let data = await outsyde.locations.getAllTypes();
      setLocationTypes(data)
    } catch(err) {
      console.log(err);
    }
  }

  const updateLocation = (changes = {}) => {
    setLocation({ ...location, ...changes })
  }

  useEffect(() => {
    if (open && locationTypes?.length === 0) {
      handleLocationTypes()
    }
  }, [open])

  const handleCreate = async () => {
    if (!location?.name) {
      setError("Please add a name")
    } else if (!location?.type) {
      setError("Please choose a type")
    } else if (!location?.address?.lat) {
      setError("Please add a latitude or address")
    } else if (!location?.address?.lng) {
      setError("Please add a longitude or address")
    } else {
      try {
        setLoading(true)
        let data = await outsyde.locations.create({
          name: location?.name,
          description: location?.desc,
          fullAddress: address?.fullAddress,
          locationTypeId: location?.type?.id,
          coords: { type: "Point", coordinates: [address?.lng, address?.lat] }
        })
        onCreate(data);
        setLoading(false);
        handleClose()
        setLocation({})
      } catch(err) {
        setLoading(false);
      }
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ width: 500, height: 600 }} className='p-3 flex-col jb-as'>
        <div style={{ width: '100%' }}>
          <div className='mb-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Create a Location</div>
          <Input className='mt-2 mb-2' placeholder='NAME' value={location?.name} onChange={(v) => updateLocation({ name: v })} />
          <Select placeholder='TYPE' value={location?.type} onChange={(v) => updateLocation({ type: v })} options={formattedTypes} />
          {useAddress && <GeoCode className='mt-2 mb-2' onChange={(v) => updateLocation({ address: v })} />}
          {!useAddress && (
            <div className='row'>
              <div className='col-6'>
                <Input className='mt-2 mb-2' placeholder='LATITUDE' value={location?.address?.lat} onChange={(v) => updateLocation({ address: { ...address, lat: v } })} />
              </div>
              <div className='col-6'>
                <Input className='mt-2 mb-2' placeholder='LONGITUDE' value={location.address?.lng} onChange={(v) => updateLocation({ address: { ...address, lng: v } })} />
              </div>
            </div>
          )}
          <div className='mb-2 jc-ac' style={{ color: 'white', cursor: 'pointer', fontSize: 12, fontWeight: 600 }} onClick={() => setUseAddress(!useAddress)}>
            {useAddress ? 'Find by LAT/LNG' : 'Find by Address'}
          </div>
          <TextArea className='mt-2' placeholder='DESCRIPTION' value={location?.desc} onChange={(v) => updateLocation({ desc: v })} />
        </div>
        <div style={{ width: '100%' }} onClick={handleCreate} className='btn1'>CREATE</div>
      </div>
    </CenterModal>
  )
}

export default CreateLocationModal;
