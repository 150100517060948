import { useRef, useState, useEffect } from "react";
import { browserName } from "react-device-detect";
import { BsArrowRepeat } from "react-icons/bs";
import { useApp } from "context";
import Hls from "hls.js";

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const PlayerSwitch = ({ isInView, post, dragging }) => {
  const hlsFile = post?.files?.find((f) => f?.fileType === "hls") || {};
  const mainFile = post?.files?.find((f) => f?.fileType === "main") || {};
  const smallFile = post?.files?.find((f) => f?.fileType === "small") || {};
  const canHls = !!hlsFile?.filePath && browserName === 'Safari';
  const file = canHls ? hlsFile : mainFile;

  if (file?.filePath) {
    return (
      <HLSPlayer
        file={file}
        key={post?.id}
        canHls={canHls}
        dragging={dragging}
        isInView={isInView}
        smallFile={smallFile}
      />
    );
  } else {
    return null;
  }
};

export default PlayerSwitch;

const HLSPlayer = ({ isInView, file, canHls, dragging, smallFile }) => {
  const appActive = true;
  const { muted } = useApp();
  const videoRef = useRef(null);
  const [isOver, setIsOver] = useState(false);
  const [attached, setAttached] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [setupAttempts, setSetupAttempts] = useState(0);
  const path = `${CDN_ROUTE}${file?.filePath}`;

  useEffect(() => {
    if (!appActive) {
      videoRef?.current?.pause();
    } else {
      if (!videoRef?.current.src) {
        videoRef.current.src = path;
        videoRef.current.load();
      }
    }
    //eslint-disable-next-line
  }, [appActive]);

  useEffect(() => {
    const tempRef = videoRef.current;

    const canPlayHandler = () => {
      setAttached(true);
      setIsWaiting(false);
    };

    const errorHandler = (e) => {
      setIsWaiting(true);
    }

    const waitingHandler = () => {
      if (tempRef?.currentTime > 0) {
        setIsWaiting(true);
      }
    };

    const playHandler = () => {
      setIsWaiting(false);
      setIsOver(false);
    };

    // const handleTime = () => {
    //   console.log(videoRef?.current?.currentTime);
    // }

    //tempRef.addEventListener("timeupdate", handleTime);

    tempRef.addEventListener("waiting", waitingHandler);
    tempRef.addEventListener("error", errorHandler);
    tempRef.addEventListener("play", playHandler);
    tempRef.addEventListener("loadeddata", canPlayHandler);
    tempRef.addEventListener("playing", playHandler);
    //tempRef.addEventListener("canplay", canPlayHandler);

    return () => {
      //tempRef.removeEventListener("timeupdate", handleTime);

      //tempRef.removeEventListener("canplay", canPlayHandler);
      tempRef.removeEventListener("loadeddata", canPlayHandler);
      tempRef.removeEventListener("waiting", waitingHandler);
      tempRef.removeEventListener("error", waitingHandler);
      tempRef.removeEventListener("play", playHandler);
      tempRef.removeEventListener("playing", playHandler);
    };
  }, []);

  const setupHls = () => {
    if (!attached) {
      if (Hls.isSupported() && canHls) {
        var hls = new Hls();
        hls.loadSource(path);
        hls.attachMedia(videoRef.current);
        hls.startLoad();
        hls.on(Hls.Events.ERROR, function () {
          hls.recoverMediaError();
        });
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          setAttached(true);
        });
      } else {
        if (videoRef?.current) {
          videoRef.current.src = path;
          videoRef.current.load();
          setSetupAttempts(0);
        } else {
          if (setupAttempts < 10) {
            setTimeout(() => setupHls(), setupAttempts * 200);
            setSetupAttempts(setupAttempts + 1)
          }
        }
      }
    }
  };

  const handleReset = () => {
    if (videoRef?.current) {
      videoRef.current.currentTime = 0;
    }
  }

  const handleStart = () => {
    if (!isOver) {
      if (attached) {
        if (isInView) {
          if (videoRef?.current?.src) {
            videoRef.current.play();
          } else {
            setupHls();
          }
        } else {
          if (videoRef?.current?.currentTime > 0) {
            videoRef?.current.pause();
            if (!dragging) {
              setTimeout(() => handleReset(), 500);
            }
          }
        }
      } else {
        setupHls();
      }
    }
  };

  useEffect(() => {
    handleStart();
    //eslint-disable-next-line
  }, [isInView, attached, dragging, appActive]);

  const handleEnd = () => {
    setIsOver(true);
  };

  const handleReplay = (e) => {
    setIsOver(false);
    e.stopPropagation();
    videoRef.current.currentTime = 0;
    videoRef?.current?.play();
  };

  const showSpinner = isWaiting && !isOver;
  const isMuted = muted || !isInView;

  return (
    <>
      <video
        muted={isMuted}
        ref={videoRef}
        playsInline="1"
        onEnded={handleEnd}
        preload="auto"
        poster={smallFile?.filePath}
        style={{
          zIndex: 2,
          width: "100%",
          height: "100%",
          objectFit: "contain",
          position: 'relative',
        }}
      />

      {isOver && <RepeatBtn handleReplay={handleReplay} />}
    </>
  );
};

const RepeatBtn = ({ handleReplay }) => {
  return (
    <div className="video-repeat-btn" onClick={(e) => handleReplay(e)}>
      <BsArrowRepeat
        style={{
          fontSize: 52,
          filter: "drop-shadow(2px 2px 12px rgba(0,0,0,.8))",
        }}
      />
    </div>
  );
};
