import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';
import { GetSection } from 'components/BlogSections';

const DynamicPage = ({ url, overrides = {} }) => {
  if (window.document) {
    window.document.body.style.overflowY = 'auto';
  }

  const { setError } = useApp();
  const [page, setPage] = useState({});
  const sortedSections = page?.sections?.sort((a, b) => a.position - b.position)

  const getPage = async () => {
    try {
      let data = await outsyde.pages.getAll({ filter: { slug: { _eq: url } } });
      if (data?.length > 0) {
        setPage({ ...data[0], ...overrides })
      } else {
        setError("Sorry!, There was a problem loading this page")
      }
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPage()
  }, []);

  return(
    <div className="App">
      <Header />
      <div className='container mt-3 pb-5 d-flex justify-content-center' style={{ textAlign: 'left' }}>
        <div style={{ maxWidth: 900, width: '100%' }}>
          <div className='mb-3 mt-2' style={{ color: 'white', fontSize: 32, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>{page?.name}</div>
          {overrides?.firstChildren}
          {sortedSections?.map((s, key) => (
            <GetSection section={s} blog={page} key={key} />
          ))}
          {overrides?.lastChildren}
        </div>
      </div>
      <Footer className=" " />
    </div>
  )
}

export default DynamicPage;
