// import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useEffect, useState } from 'react';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const Docs = ({ url }) => {
  const [pages, setPages] = useState([]);

  if (window.document) {
    window.document.body.style.overflowY = 'auto';
  }

  const getPages = async () => {
    try {
      let data = await outsyde.pages.getAll({ filter: { pageType: { _not: 'core' } } });
      setPages(data);
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getPages()
  }, []);

  return(
    <div className="App">
      <Header />
      <div className='container mt-3 pb-5 d-flex justify-content-center' style={{ textAlign: 'left' }}>
        <div className='row'>
          {pages?.map((p) => (
            <DocItem key={p?.id} doc={p} />
          ))}
        </div>
      </div>
      <Footer className=" " />
    </div>
  )
}

export default Docs;

export const DocItem = ({ doc }) => {
  return(
    <div className='col-12 col-md-6 col-lg-3 mb-3'>
      <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <a href={`/docs/${doc?.slug}`}>
          <Display post={doc} />
        </a>
        <div className='p-3'>
          <a href={`/docs/${doc?.slug}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.5, height: 48, color: 'white', textDecoration: 'none' }}>{doc?.name}</a>
        </div>
      </div>
    </div>
  )
}

const Display = ({ post, height = 240 }) => {
  const smallFile = post?.files?.find((f) => f?.fileType === 'small');
  const [errorPath, setErrorPath] = useState("");

  const createPostError = () => {
    setErrorPath("/assets/imgs/errorPhoto.png");
  };

  if (smallFile) {
    return <img onError={createPostError} alt='' src={errorPath || `${CDN_ROUTE}${smallFile?.filePath}`} style={{ width: '100%', height: height, objectFit: 'cover' }} />
  } else {
    const mainFile = post?.files?.find((f) => f?.fileType === 'main');

    if (mainFile?.contentType?.includes("video")) {
      return(
        <video width="100%" height="100%" style={{ width: '100%', height: height, objectFit: 'cover' }} muted={true}>
          <source src={`${CDN_ROUTE}${mainFile?.filePath}`} type="video/mp4" />
        </video>
      )
    } else {
      return <img onError={createPostError} alt='' src={`${CDN_ROUTE}${mainFile?.filePath}`} style={{ width: '100%', height: height, objectFit: 'cover' }} />
    }
  }
}
