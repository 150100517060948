import { useNavigate } from "react-router";
import { BsPersonCircle } from 'react-icons/bs';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const UserSection = ({ user, date = null, className = "mb-4", noNav = false }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (noNav) {

    } else {
      e.stopPropagation();
      navigate(`/profile/${user?.id}`)
    }
  }

  const getImgPath = () => {
    if (user?.profilePictureURL) {
      return `${CDN_ROUTE}${user?.profilePictureURL}`
    } else {
      return `/assets/imgs/errorPhoto.png`
    }
  }

  return(
    <div onClick={handleClick} className={`d-flex align-items-center justify-content-start ${className}`} style={{ cursor: 'pointer' }}>
      <UserIcon user={user} style={{ height: 32, width: 32, borderRadius: '50%', border: '1px solid white', objectFit: 'cover', flexShrink: 0 }} />
      <div style={{ color: 'white', textAlign: 'left', marginLeft: 10 }}>
        <div style={{ fontWeight: 700, lineHeight: 1, marginBottom: 3 }}>{user?.username}</div>
        {date && <div style={{ fontSize: 11, color: '#8c8a84', fontWeight: 700, lineHeight: 1 }}>{date}</div>}
      </div>
    </div>
  )
}

export default UserSection;

export const UserIcon = ({ user = {}, btnRef = null, onClick = () => {}, style = {} }) => {
  if (user?.id) {
    if (user?.profilePic) {
      return <img ref={btnRef} onClick={onClick} alt='' src={`${CDN_ROUTE}${user?.profilePic?.filePath}`} style={{ height: 28, width: 28, borderRadius: '50%', border: '1px solid white', objectFit: 'cover', flexShrink: 0, cursor: 'pointer', ...style }} />
    } else {
      let firstLetter = user?.username[0];
      return <div ref={btnRef} onClick={onClick} style={{ width: 28, height: 28, borderRadius: '50%', border: '1px solid white', textTransform: 'uppercase', color: 'white', flexShrink: 0, fontSize: 15, fontWeight: 700, cursor: 'pointer', ...style }} className='jc-ac'>{firstLetter}</div>
    }
  } else {
    return <img ref={btnRef} onClick={onClick} alt='' src="/assets/imgs/errorPhoto.png" style={{ height: 28, width: 28, borderRadius: '50%', border: '1px solid white', objectFit: 'cover', flexShrink: 0, cursor: 'pointer', ...style }} />
  }
}
