import { useEffect, useState } from 'react';

import outsyde from "@outsyde/sdk";

import { useApp } from 'context';
import DynamicPage from 'components/DynamicPage';

const Contact = () => {
  return(
    <DynamicPage url="/contact-outsyde" overrides={{ name: "Reach Out to Us", firstChildren: <Form /> }} />
  )
}

export default Contact;

const Form = () => {
  const { user, setLoading, setError } = useApp();
  const [values, setValues] = useState({ category: 'CONTACT-FORM' });
  const isLoggedIn = !!user?.id;

  const handleUpdate = (changes = {}) => {
    setValues({ ...values, ...changes })
  }

  const handleSubmit = async () => {
    if (!values?.body) {
      setError("Please add a message!")
    } else if (!values?.email) {
      setError("Please add your email!")
    } else {
      try {
        setLoading(true);
        await outsyde.posts.reportPost(values);
        setValues({ type: 'CONTACT-FORM', userId: user?.id })
        setLoading(false);
      } catch(err) {
        setError(err?.message)
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      handleUpdate({ email: user?.email, username: user?.username, userId: user?.id })
    }
  }, [isLoggedIn])

  return(
    <div>
      <div className='row mt-4'>
        <div className='col-12 col-lg-6'>
          <Input value={values?.username} onChange={(v) => handleUpdate({ username: v })} placeholder="USERNAME" />
        </div>
        <div className='col-12 col-lg-6 mt-3 mt-lg-0'>
          <Input value={values?.email} onChange={(v) => handleUpdate({ email: v })} placeholder="EMAIL" />
        </div>
      </div>
      <div className='col-12 mt-3'>
        <TextArea value={values?.body} onChange={(v) => handleUpdate({ body: v })} placeholder="*MESSAGE" />
      </div>
      <div className='col-12 mt-3'>
        <div onClick={handleSubmit} className="btn1">SUBMIT</div>
      </div>
    </div>
  )
}

const Input = ({ value = "", onChange = () => {}, placeholder = "" }) => {
  return(
    <input onChange={(v) => onChange(v?.target?.value)} value={value} style={{ color: 'white', width: '100%', background: 'transparent', border: '1px solid rgba(255,255,255,.12)', outline: 'none', height: 40, paddingLeft: 12 }} placeholder={placeholder} />
  )
}

const TextArea = ({ value = "", onChange = () => {}, placeholder = "" }) => {
  return(
    <textarea onChange={(v) => onChange(v?.target?.value)} value={value} style={{ color: 'white', width: '100%', background: 'transparent', border: '1px solid rgba(255,255,255,.12)', outline: 'none', height: 120, paddingLeft: 12, paddingTop: 8 }} placeholder={placeholder} />
  )
}
