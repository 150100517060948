import { BlogCard2, BlogCard3, BlogCard4, BlogCard5 } from 'components/BlogCards';
import { getFilteredBlogsByTypes } from 'utils';
import { useState, useEffect } from 'react';
import outsyde from "@outsyde/sdk";
import { useApp } from 'context';

const BlogDisplay = ({ query = {}, cardType = 2, title = "", subTitle = "", id, children }) => {
  const [blogs, setBlogs] = useState([]);
  
  const handleBlogs = async () => {
    try {
      let data = await outsyde.posts.getAll({ order: '-createdAt', ...query });
      setBlogs(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleBlogs()
  }, [id])

  const getCard = (blog, key) => {
    if (cardType === 2) {
      return <BlogCard2 post={blog} key={key} />
    } else if (cardType === 3) {
      return <BlogCard3 post={blog} key={key} />
    } else if (cardType === 4) {
      return <BlogCard4 post={blog} key={key} />
    } else if (cardType === 5) {
      return <BlogCard5 post={blog} key={key} />
    }
  }

  return(
    <div className='container'>
      {blogs?.length > 0 && children}
      <div className='row'>
        {blogs?.map((blog, key) => getCard(blog, key))}
      </div>
    </div>
  )
}

export default BlogDisplay;
