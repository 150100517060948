import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';
import { BsCheck } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { IoOpenOutline } from 'react-icons/io5';
import CenterModal from 'components/CenterModal';
import { PostProductCardNew } from 'components/ProductCards';

const ProductModal = ({ open, onChange, handleClose, existingProducts }) => {
  const { setError } = useApp();
  const [optionProducts, setOptionProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const [newModal, setNewModal] = useState(false);

  const handleAddProduct = (newProduct) => {
    if (existingProducts?.length < 2) {
      onChange({ products: [...existingProducts, newProduct] })
      let updatedOptions = optionProducts?.filter((p) => p?.id !== newProduct?.id);
      setOptionProducts(updatedOptions)
    } else {
      setError("Max 2 products per post")
    }
  }

  const handleRemoveProduct = (p) => {
    let remaining = existingProducts?.filter((ep) => ep?.id !== p?.id);
    onChange({ products: remaining })
    setOptionProducts([...optionProducts, p])
  }

  const handleInitialProducts = async () => {
    if (!productsLoading) {
      setProductsLoading(true);
      try {
        let data = await outsyde.products.getAll({ limit: 11 });
        let allowedCount = 11 - existingProducts?.length;
        let maxxed = data?.filter((p, idx) => idx < allowedCount)
        setOptionProducts(maxxed);
        setProductsLoading(false);
      } catch(err) {
        setProductsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (open) {
      handleInitialProducts()
      setSearchVal('')
    } else {
      setOptionProducts([])
    }
  }, [open])

  const handleSearch = async (_searchVal) => {
    setSearchVal(_searchVal);
    setProductsLoading(true);
    try {
      let data = await outsyde.products.getAll({ filter: { name: { _iLike: `%25${_searchVal}%25` } } });
      let existingIds = existingProducts?.map((p) => p?.id);
      let filtered = data?.filter((p) => !existingIds?.includes(p?.id));
      let allowedCount = 11 - existingIds?.length;
      let maxxed = filtered?.filter((p, idx) => idx < allowedCount)
      setOptionProducts(maxxed);
      setProductsLoading(false);
    } catch(err) {
      setProductsLoading(false);
    }
  }

  const handleAddNew = () => {
    setNewModal(true);
    handleClose()
  }

  return(
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ width: 400, height: 600 }} className='p-3'>
          <div className='mb-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Add Products</div>
          <Input className='mt-2 mb-2' placeholder='SEARCH PRODUCTS' value={searchVal} onChange={handleSearch} />
          {existingProducts?.map((p, idx) => <ChosenProductItem handleRemoveProduct={handleRemoveProduct} key={`${p?.id}+${idx}`} p={p} />)}
          {optionProducts?.map((p, idx) => <ProductOption handleAddProduct={handleAddProduct} key={`${p?.id}+${idx}`} p={p} isLast={idx === optionProducts?.length - 1} />)}
          {optionProducts?.length < 3 && (
            <div style={{ width: '100%', color: '#4f7361', cursor: 'pointer', fontWeight: 700 }} className='jc-ac pt-2 pb-2' onClick={handleAddNew}>
              Add New
            </div>
          )}
        </div>
      </CenterModal>
      <NewProductModal open={newModal} setOpen={setNewModal} handleAddProduct={handleAddProduct} />
    </>
  )
}

export default ProductModal;

const ProductOption = ({ p, isLast, handleAddProduct }) => {
  return(
    <div style={{ width: '100%', color: 'white', cursor: 'pointer', borderBottom: isLast ? "" : '1px solid rgba(255,255,255,.12)', whiteSpace: 'nowrap' }} className='js-ac truncate1 pt-2 pb-2' onClick={() => handleAddProduct(p)}>
      {p?.name?.slice(0, 42)}{p?.name?.length > 42 && "..."}
    </div>
  )
}

const ChosenProductItem = ({ p, handleRemoveProduct }) => {
  return(
    <div style={{ width: '100%', color: 'white', cursor: 'pointer', borderBottom: '1px solid rgba(255,255,255,.12)', whiteSpace: 'nowrap' }} className='js-ac truncate1 pt-2 pb-2 js-ac' onClick={() => handleRemoveProduct(p)}>
      <BsCheck style={{ fontSize: 20, color: '#4f7361' }} className='me-2' />
      {p?.name?.slice(0, 38)}{p?.name?.length > 38 && "..."}
    </div>
  )
}

const NewProductModal = ({ open, setOpen, handleAddProduct }) => {
  const { setLoading, setError } = useApp();
  const [productUrl, setProductUrl] = useState("");
  const [newProduct, setNewProduct] = useState({});
  const [merchants, setMerchants] = useState([]);

  useEffect(() => {
    if (open) {
      if (merchants?.length === 0) {
        handleMerchants()
      }
      setNewProduct({});
      setProductUrl("");
    }

  }, [open])

  const handleMerchants = async () => {
    try {
      let data = await outsyde.products.getAllMerchants({ filter: { active: { _eq: true } } });
      setMerchants(data)
    } catch(err) {
      setError("Error getting merchants, please check your internet connection and try again")
    }
  }

  const handleUrl = async () => {
    try {
      setLoading(true);
      let data = await outsyde.products.create({ url: productUrl });
      setNewProduct(data)
      setLoading(false);
    } catch(err) {
      setError(err?.message);
      setLoading(false);
    }
  }

  const handleAdd = () => {
    handleAddProduct(newProduct);
    setNewProduct({});
    setOpen(false);
  }

  return(
    <CenterModal open={open} handleClose={() => setOpen(false)}>
      <div style={{ width: 400, height: 600 }} className='p-3'>
        {!newProduct?.id && <NewProduct1 handleUrl={handleUrl} merchants={merchants} productUrl={productUrl} setProductUrl={setProductUrl} />}
        {newProduct?.id && <NewProduct2 newProduct={newProduct} handleAddProduct={handleAdd} />}
      </div>
    </CenterModal>
  )
}

const NewProduct1 = ({ handleUrl, merchants, productUrl, setProductUrl }) => {
  const validURL = productUrl?.length > 0;
  const unValidStyles = { opacity: 0.5, background: '#4f7361', color: 'white' };
  const styles = validURL ? {} : unValidStyles;

  const openMerchantSite = async (domain) => {
    window.open(`https://${domain}`, '_blank');
  };

  return(
    <>
      <div className='mb-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Add New Product</div>
      <Input className='mt-2 mb-2' placeholder='PRODUCT URL' value={productUrl} onChange={setProductUrl} />
      <div style={{ fontSize: 12, fontWeight: 400, color: 'white' }}>
        To add and start selling a new product visit one of our partner stores listed below. Choose a product, copy it's URL and paste it above.
      </div>
      <div style={{ height: 370, overflowY: 'auto', paddingBottom: 24 }}>
        {merchants?.map((merchant) => (
          <div key={merchant?.id} className='jb-ac pb-1 pt-1' style={{ color: 'white', borderBottom: '1px solid rgba(255,255,255,.15)', cursor: 'pointer' }}>
            <div className='js-ac' onClick={() => openMerchantSite(merchant?.domain)}>
              {merchant?.name}
              <IoOpenOutline style={{ marginLeft: 5, opacity: .25 }} />
            </div>
            <div>{merchant?.averageCPA}%</div>
          </div>
        ))}
      </div>
      <div onClick={handleUrl} style={styles} className='btn1'>
        Check Product URL
      </div>
    </>
  )
}

const NewProduct2 = ({ newProduct, handleAddProduct }) => {
  return(
    <div style={{ color: 'white', width: '100%' }}>
      <div className='mb-3' style={{ fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Add New Product</div>

      <div style={{ width: '100%', background: "#19181a" }} className='shadow-sm js-ac'>
        <img alt='' src={newProduct?.imageUrl} style={{ width: 100, height: 70, objectFit: 'cover', flexShrink: 0 }} />
        <div className='pt-2 pb-2 ps-3 pe-3 flex-col jb-as' style={{ height: 70, width: '100%' }}>
          <div className='truncate' style={{ fontWeight: 600, lineHeight: 1 }}>{newProduct?.name}</div>
          <div className='jb-ac' style={{ width: '100%' }}>
            <div style={{ cursor: 'pointer', lineHeight: 1, color: '#7d5a71', fontSize: 12, fontWeight: 700, textTransform: 'uppercase', textDecoration: 'none' }}>{newProduct?.merchant?.name}</div>
            <div style={{ color: '#4f7361', fontSize: 12, fontWeight: 700 }}>{newProduct?.price}</div>
          </div>
        </div>
      </div>

      <div className='mt-3' style={{ height: 380, overflowY: 'auto', color: 'white', marginBottom: 15, fontSize: 13 }}>
        {newProduct?.description}
      </div>
      <div className='btn1' onClick={() => handleAddProduct(newProduct)}>
        Add to Post
      </div>
    </div>
  )
}
