// import { useApp } from 'context';
import { format } from 'date-fns';
import outsyde from "@outsyde/sdk";
import { useEffect, useState } from 'react';
import Pill, { Pill2 } from 'components/Pill';
import UserSection from 'components/UserSection';
import { PostProductCardNew } from 'components/ProductCards';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

export const BlogCard1 = ({ post, user }) => {
  let _user = user || post?.user;

  return(
    <div className='col-12 col-lg-6 col-xl-4 mb-3'>
      <div style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <a href={`/post/${post?.id}`}>
          <Display post={post} />
        </a>
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <Pill post={post} />
            <Pill2 post={post} />
          </div>
          <a href={`/post/${post?.id}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.5, height: 48, color: 'white', textDecoration: 'none' }}>{post?.name}</a>
          <div className='jb-ac mt-3'>
            <UserSection user={_user} date={handleDate(post)} className='mb-0' />
          </div>
        </div>
      </div>
    </div>
  )
}

export const BlogCard2 = ({ post, noUser = false }) => {
  return(
    <div className='col-12 col-md-6 col-lg-3 mb-3'>
      <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <a href={`/post/${post?.id}`}>
          <Display post={post} />
        </a>
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <Pill post={post} />
            <Pill2 post={post} />
          </div>
          <a href={`/post/${post?.id}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.5, height: 48, color: 'white', textDecoration: 'none' }}>{post?.name || post?.caption}</a>
          {!noUser && <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-3' />}
        </div>
      </div>
    </div>
  )
}

export const BlogCard3 = ({ post }) => {
  return(
    <div className='col-12 col-md-6 mb-3'>
      <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <a href={`/post/${post?.id}`}>
          <Display post={post} height={280} />
        </a>
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <Pill post={post} />
            <Pill2 post={post} />
          </div>
          <a href={`/post/${post?.id}`} className='truncate1' style={{ fontWeight: 700, lineHeight: 1.5, height: 24, color: 'white', textDecoration: 'none' }}>{post?.name}</a>
          <div className='mt-3 truncate4' style={{ lineHeight: 1.5, height: 96 }}>{post?.caption}</div>
          <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-4' />
        </div>
      </div>
    </div>
  )
}

export const BlogCard4 = ({ post }) => {
  const sections = post?.sections?.filter((ps) => ps?.sectionType === "Product");
  const [products, setProducts] = useState([]);
  const toShow = products?.filter((p, idx) => idx < 1);

  const handleProducts = async () => {
    try {
      let productSections = await Promise.all(
        sections?.map((ps) => outsyde.posts.getSectionProducts(ps?.id))
      );
      let formatted = productSections?.flat();
      let postProducts = post?.products || [];
      setProducts([...postProducts, ...formatted]);
    } catch(err) {
      
    }
  }

  useEffect(() => {
    handleProducts();

    //eslint-disable-next-line
  }, [])

  if (products?.length > 0) {
    return(
      <div className='col-12 col-md-6 col-xl-4 mb-3'>
        <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
          <a href={`/post/${post?.id}`}>
            <Display post={post} />
          </a>
          <div className='p-3'>
            <div className='d-flex mb-2'>
              <Pill post={post} />
              <Pill2 post={post} />
            </div>
            <a href={`/post/${post?.id}`} className='truncate mb-3' style={{ fontWeight: 700, lineHeight: 1.5, height: 48, color: 'white', textDecoration: 'none' }}>{post?.name}</a>
            {toShow?.map((p, key) => (
              <PostProductCardNew key={key} product={p} post={post} className="col-xl-12" />
            ))}
            <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-5' />
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export const BlogCard5 = ({ post }) => {
  return(
    <div className='col-12 col-md-6 col-lg-4 mb-3'>
      <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <a href={`/post/${post?.id}`}>
          <Display post={post} />
        </a>
        <div className='p-3'>
          <div className='d-flex mb-2'>
            <Pill post={post} />
            <Pill2 post={post} />
          </div>
          <a href={`/post/${post?.id}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.5, height: 48, color: 'white', textDecoration: 'none' }}>{post?.name || post?.caption}</a>
          <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-3' />
        </div>
      </div>
    </div>
  )
}

export const BlogCard6 = ({ post }) => {
  return(
    <div className='col-12 col-md-6 col-lg-4 col-xl-3 mb-3'>
      <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm'>
        <a href={`/post/${post?.id}`}>
          <Display post={post} height={400} />
        </a>
        <div className='p-3 jb-as flex-col'>
          <div>
            <div className='d-flex mb-2' style={{ height: 12 }}>
              <Pill post={post} />
              <Pill2 post={post} />
            </div>
            <a href={`/post/${post?.id}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.5, height: 48, color: 'white', textDecoration: 'none' }}>{post?.name}</a>
          </div>
          <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-3' />
        </div>
      </div>
    </div>
  )
}

// export const BlogCard6 = ({ post }) => {
//   return(
//     <div className='col-12 col-lg-6 mb-3'>
//       <div  style={{ cursor: 'pointer', background: '#1f1e20', overflow: 'hidden', textAlign: 'left', border: '1px solid rgba(255,255,255,.12)' }} className='shadow-sm js-ac'>
//         <div style={{ width: 225 }}>
//           <a href={`/post/${post?.id}`}>
//             <Display post={post} height={400} />
//           </a>
//         </div>
//         <div className='p-3 jb-as flex-col' style={{ height: 400 }}>
//           <div>
//             <div className='d-flex mb-2'>
//               <Pill post={post} />
//               <Pill2 post={post} />
//             </div>
//             <a href={`/post/${post?.id}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.5, color: 'white', textDecoration: 'none' }}>{post?.name}</a>
//             <a href={`/post/${post?.id}`} className='truncate mt-3' style={{ color: 'white', textDecoration: 'none', fontSize: 14 }}>{post?.caption}</a>
//           </div>
//           <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-3' />
//         </div>
//       </div>
//     </div>
//   )
// }

export const FeaturedBlogCard = ({ post }) => {
  if (post?.id) {
    return(
      <div className='container'>
        <div className='row pt-4' >
          <div className='col-6' style={{ paddingRight: 0, cursor: 'pointer' }}>
            <div style={{ borderTop: '1px solid rgba(255,255,255,.12)', borderLeft: '1px solid rgba(255,255,255,.12)', borderBottom: '1px solid rgba(255,255,255,.12)', height: 450, overflow: 'hidden' }}>
              <a href={`/post/${post?.id}`}>
                <Display post={post} height="100%" />
              </a>
            </div>
          </div>
          <div className='col-6' style={{ paddingLeft: 0, cursor: 'pointer' }}>
            <div style={{ background: '#1f1e20', borderTop: '1px solid rgba(255,255,255,.12)', borderRight: '1px solid rgba(255,255,255,.12)', borderBottom: '1px solid rgba(255,255,255,.12)', height: 450, flexDirection: 'column' }} className='p-3 jb-as'>
              <div>
                <div className='d-flex mb-2'>
                  <Pill post={post} />
                  <Pill2 post={post} />
                </div>
                <a href={`/post/${post?.id}`} className='truncate' style={{ fontWeight: 700, lineHeight: 1.25, fontSize: 32, color: 'white', textDecoration: 'none' }}>{post?.name}</a>
                <div style={{ lineHeight: 1.25 }} className='mt-3'>{post?.description}</div>
              </div>
              <UserSection user={post?.user} date={handleDate(post)} className='mb-0 mt-3' />
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return <div style={{ height: 450 }} />;
  }
}

const handleDate = (post) => {
  if (post?.createdAt) {
    return format(new Date(post?.createdAt), 'MM/dd/yyyy')
  } else {
    return null;
  }
}

const Display = ({ post, height = 240 }) => {
  const smallFile = post?.files?.find((f) => f?.fileType === 'small');
  const [errorPath, setErrorPath] = useState("");

  const createPostError = () => {
    setErrorPath("/assets/imgs/errorPhoto.png");
  };

  if (smallFile) {
    return <img onError={createPostError} alt='' src={errorPath || `${CDN_ROUTE}${smallFile?.filePath}`} style={{ width: '100%', height: height, objectFit: 'cover' }} />
  } else {
    const mainFile = post?.files?.find((f) => f?.fileType === 'main');

    if (mainFile?.contentType?.includes("video")) {
      return(
        <video width="100%" height="100%" style={{ width: '100%', height: height, objectFit: 'cover' }} muted={true}>
          <source src={`${CDN_ROUTE}${mainFile?.filePath}`} type="video/mp4" />
        </video>
      )
    } else {
      return <img onError={createPostError} alt='' src={`${CDN_ROUTE}${mainFile?.filePath}`} style={{ width: '100%', height: height, objectFit: 'cover' }} />
    }
  }
}
