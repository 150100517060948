import ActivitiesModal from 'components/ActivitiesModal';
import { useNavigate } from "react-router";
import Input from 'components/Input';
import outsyde from "@outsyde/sdk";
import { useState } from 'react';
import { useApp } from 'context';

const Verify = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [activities, setActivities] = useState([]);
  const [activitiesModal, setActivitiesModal] = useState(false);
  const { setLoading, setError, setUser, user } = useApp()

  const handleVerify = async () => {
    try {
      setLoading(true);
      let { dataValues } = await outsyde.auth.verifyConfirmationCode(code);
      setUser(dataValues);
      localStorage.setItem("outsyde_user", JSON.stringify(dataValues));
      setLoading(false);
      setActivitiesModal(true);
    } catch(err) {
      setLoading(false);
      setError(err?.message || 'An error occured, please try again')
    }
  };

  const handleResendCode = async () => {
    try {
      setLoading(true);
      await outsyde.auth.resendConfirmationCode();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message);
    }
  }

  const closeActivitiesModal = async () => {
    navigate("/feed");
    if (activities?.length > 0) {
      try {
        setLoading(true);
        await outsyde.users.setFavorites(user?.id, activities?.map((a) => a?.id));
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    setActivitiesModal(false);
  }

  const handleActivitiesChange = (act) => {
    let existingIds = activities?.map((a) => a?.id);
    if (existingIds?.includes(act?.id)) {
      let remaining = activities?.filter((a) => a?.id !== act?.id);
      setActivities(remaining);
    } else {
      setActivities([...activities, act])
    }
  }

  return(
    <>
      <div className='jc-ac' style={{ width: '100vw', height: '100vh' }}>
        <div style={{ width: '100%', maxWidth: 500, background: 'black', padding: 20, position: 'relative', zIndex: 2 }} className='shadow'>
          <div className='mb-2' style={{ fontSize: 32, fontWeight: 700, color: "#5c7d6d", letterSpacing: '0.35mm' }}>VERIFY EMAIL</div>
          <Input placeholder='CODE' value={code} onChange={setCode} />
          <div className='jb-ac mt-2'>
            <div onClick={() => navigate('/register')} style={{ cursor: 'pointer', lineHeight: 1, color: '#4f7361', fontWeight: 600, fontSize: 14 }}>Back</div>
            <div onClick={handleResendCode} style={{ cursor: 'pointer', lineHeight: 1, color: '#4f7361', fontWeight: 600, fontSize: 14 }}>Resend</div>
          </div>
          <div style={{ height: 38 }} className="btn1 mt-3" onClick={handleVerify}>DONE</div>
        </div>
      </div>
      <ActivitiesModal open={activitiesModal} selected={activities} handleChange={handleActivitiesChange} handleClose={closeActivitiesModal} header="What are you into?">
        <div className='p-3'>
          <div onClick={closeActivitiesModal} className='btn1'>DONE</div>
        </div>
      </ActivitiesModal>
    </>
  )
}

export default Verify;
