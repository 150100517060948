import { useNavigate } from "react-router";
import { useApp } from 'context';
import { useState, useEffect, useRef } from 'react';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';

const UserModalNotLoggedIn = ({ handleClose }) => {
  const navigate = useNavigate();
  const { setLoading, setError, setUser } = useApp()
  const [terms, setTerms] = useState(true);
  const [signUp, setSignUp] = useState(false);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [verify, setVerify] = useState(false);

  const handleVerify = async () => {
    try {
      setLoading(true);
      let { dataValues } = await outsyde.auth.verifyConfirmationCode(code);
      localStorage.setItem("outsyde_user", JSON.stringify(dataValues));
      setUser(dataValues);
      setLoading(false);
      handleClose()
    } catch(err) {
      setLoading(false);
      setError(err?.message || 'An error occured, please try again')
    }
  };

  const handleRegister = async () => {
    if (!terms) {
      setError('You must agree to our terms to register')
    } else {
      try {
        setLoading(true);
        await outsyde.auth.signup(email, password, username);
        setVerify(true)
        setLoading(false);
      } catch(err) {
        setLoading(false);
        setError(err?.message || 'An error occured, please try again')
      }
    }
  };

  const handleLogin = async () => {
    if (username?.length < 1) {
      setError("Username cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else {
      try {
        setLoading(true);
        localStorage.setItem("outsyde-remember", JSON.stringify(username));
        let data = await outsyde.auth.signin(username, password);
        localStorage.setItem("outsyde_user", JSON.stringify(data));
        setUser(data);
        setLoading(false);
        handleClose()
      } catch (err) {
        setLoading(false);
        setError(err?.message || "We couldn't log you in with those credentials!");
      }
    }
  };

  const handleResendCode = async () => {
    try {
      setLoading(true);
      await outsyde.auth.resendConfirmationCode();
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message);
    }
  }

  if (verify) {
    return(
      <>
        <div style={{ width: '100%', maxWidth: 500, background: 'black', padding: 20, position: 'relative', zIndex: 2 }} className='shadow'>
          <div className='mb-2' style={{ fontSize: 32, fontWeight: 700, color: "#5c7d6d", letterSpacing: '0.35mm' }}>VERIFY EMAIL</div>
          <Input placeholder='CODE' value={code} onChange={setCode} />
          <div className='jb-ac mt-2'>
            <div onClick={() => setVerify(false)} style={{ cursor: 'pointer', lineHeight: 1, color: '#4f7361', fontWeight: 600, fontSize: 14 }}>Back</div>
            <div onClick={handleResendCode} style={{ cursor: 'pointer', lineHeight: 1, color: '#4f7361', fontWeight: 600, fontSize: 14 }}>Resend</div>
          </div>
          <div style={{ height: 38 }} className="btn1 mt-3" onClick={handleVerify}>DONE</div>
          <div style={{ marginTop: 12 }} className="outsyde-text-btn" onClick={() => setSignUp(false)}>LOGIN</div>
        </div>
      </>
    )
  } else if (signUp) {
    return(
      <>
        <div style={{ width: '100%', maxWidth: 500, background: 'black', padding: 20, position: 'relative', zIndex: 2 }} className='shadow'>
          <div className='mb-2' style={{ fontSize: 32, fontWeight: 700, color: "#5c7d6d", letterSpacing: '0.35mm' }}>JOIN OUTSYDE</div>
          <Input placeholder='USERNAME' value={username} onChange={setUsername} />
          <Input placeholder='EMAIL' value={email} onChange={setEmail} style={{ marginTop: 12 }} />
          <Input placeholder='PASSWORD' value={password} onChange={setPassword} type='password' style={{ marginTop: 12 }} />
          <div style={{ height: 38, marginTop: 24 }} className="btn1" onClick={handleRegister}>SIGN UP</div>
          <div style={{ marginTop: 12 }} className="outsyde-text-btn" onClick={() => setSignUp(false)}>LOGIN</div>
        </div>
      </>
    )
  } else {
    return(
      <>
        <div style={{ width: '100%', maxWidth: 500, background: 'black', padding: 20, position: 'relative', zIndex: 2 }} className='shadow'>
          <div className='mb-2' style={{ fontSize: 32, fontWeight: 700, color: "#5c7d6d", letterSpacing: '0.35mm' }}>SIGN IN</div>
          <Input placeholder='USERNAME' value={username} onChange={setUsername} />
          <Input placeholder='PASSWORD' value={password} onChange={setPassword} type='password' style={{ marginTop: 12 }} />
          <div style={{ height: 38, marginTop: 24 }} className="btn1" onClick={handleLogin}>DONE</div>
          <div style={{ marginTop: 12 }} className="outsyde-text-btn" onClick={() => setSignUp(true)}>SIGN UP</div>
        </div>
      </>
    )
  }
}

export default UserModalNotLoggedIn;
