import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import { useNavigate } from "react-router";
import TextArea from 'components/TextArea';
import { useState, useEffect } from "react";
import ShareModal from 'components/ShareModal';
import CenterModal from 'components/CenterModal';
import { IoVolumeMuteOutline, IoVolumeHighOutline, IoShareSocialOutline, IoCreateOutline, IoEyeOffOutline, IoEllipsisVerticalOutline, IoBookmarkOutline, IoBookmark } from "react-icons/io5";

const PostActions = ({ post, size = 18 }) => {
  const { user, setAccountModal, muted, setMuted } = useApp();
  const isLoggedIn = !!user?.id;
  const likeId = post?.likes?.length > 0 ? post?.likes[0]?.id : "";
  const [isLiked, setIsLiked] = useState(likeId ? true : false);
  const [shareModal, setShareModal] = useState(false);
  const [optionsModal, setOptionsModal] = useState(false);
  const mainFile = post?.files?.find((pf) => pf?.fileType === 'main');
  const isVideo = mainFile?.contentType?.includes("video");

  useEffect(() => {
    let status = likeId ? true : false;
    setIsLiked(status);
  }, [likeId]);

  const handleLike = async () => {
    if (isLoggedIn) {
      setIsLiked(!isLiked);
      try {
        await outsyde.posts.handleLike(post?.id);
      } catch (err) {
        setIsLiked(!isLiked);
      }
    } else {
      setAccountModal(true)
    }
  };

  return(
    <>
      <div className='je-ac' style={{ fontSize: size }}>
        {isVideo && (
          <div className='jc-ac' onClick={() => setMuted(!muted)}>
            {muted ? <IoVolumeMuteOutline /> : <IoVolumeHighOutline />}
          </div>
        )}
        <div className='ms-3 jc-ac' onClick={handleLike}>
          {isLiked ? <IoBookmark /> : <IoBookmarkOutline />}
        </div>
        <div className='ms-3 jc-ac' onClick={() => setShareModal(true)}>
          <IoShareSocialOutline />
        </div>
        <div className='ms-3 jc-ac' onClick={() => setOptionsModal(true)}>
          <IoEllipsisVerticalOutline />
        </div>
      </div>
      <ShareModal post={post} open={shareModal} handleClose={() => setShareModal(false)} />
      <OptionsModal post={post} handleLike={handleLike} isLiked={isLiked} open={optionsModal} setOpen={setOptionsModal} setShareModal={setShareModal} />
    </>
  )
}

export default PostActions;

const OptionsModal = ({ post, isLiked, open, setOpen, handleLike, setShareModal }) => {
  const navigate = useNavigate();
  const { setLoading, user } = useApp();
  const canEdit = post?.user?.id === user?.id;
  const [reportModal, setReportModal] = useState(false);

  const handleHide = async () => {
    try {
      setLoading(true);
      await outsyde.posts.reportPost({
        postId: post?.id,
        status: "HIDE",
      });
      setLoading(false);
    } catch(err) {
      setLoading(false);
    }
  }

  const handleReportModal = () => {
    setOpen(false);
    setReportModal(true)
  }

  return(
    <>
      <CenterModal open={open} handleClose={() => setOpen(false)}>
        <div className='pt-2 ps-2 pe-2 pb-4' style={{ width: 300, color: 'white' }}>
          <div className='ps-3 pe-3 js-ac mt-3' style={{ cursor: 'pointer' }}  onClick={handleLike}>
            {isLiked ? <IoBookmark className='me-2' /> : <IoBookmarkOutline className='me-2' />}
            {isLiked ? 'Unsave' : 'Save'}
          </div>
          <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }}  onClick={() => setShareModal(true)}>
            <IoShareSocialOutline className='me-2' />
            Share
          </div>
          <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }} onClick={handleHide}>
            <IoEyeOffOutline className='me-2' />
            Hide
          </div>
          <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }} onClick={handleReportModal}>
            <IoEyeOffOutline className='me-2' />
            Report
          </div>
          {canEdit && (
            <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }} onClick={() => navigate(`/edit-post/${post?.id}`)}>
              <IoCreateOutline className='me-2' />
              Edit
            </div>
          )}
        </div>
      </CenterModal>
      <ReportModal post={post} open={reportModal} setOpen={setReportModal} />
    </>
  )
}

const ReportModal = ({ post, open, setOpen }) => {
  const { setLoading } = useApp();
  const [report, setReport] = useState({});

  const updateReport = (changes = {}) => {
    setReport({...report, ...changes})
  }

  const handleReport = async () => {
    try {
      setLoading(true);
      await outsyde.posts.reportPost({
        postId: post?.id,
        body: report?.body,
      });
      setOpen(false);
      setLoading(false);
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={() => setOpen(false)}>
      <div className='p-2' style={{ width: 400, color: 'white', maxWidth: '100%' }}>
        <div className='mb-3' style={{ color: 'white', fontSize: 20, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>We are sorry you found this post inappropriote</div>
        <TextArea value={report.body} onChange={(v) => updateReport({ body: v })} label="What Happened?" className='mb-2' style={{ height: 100 }} />
        <div className='jb-ac'>
          <div style={{ width: '49%'}} onClick={() => setOpen(false)} className='btn2'>CANCEL</div>
          <div style={{ width: '49%'}} onClick={handleReport} className='btn1'>REPORT</div>
        </div>
      </div>
    </CenterModal>
  )
}
