// import { useApp } from "context";
import { handleDate } from "utils";
import Viewer from "components/Viewer";
import Pill, { Pill2 } from "components/Pill";
import PostActions from "components/PostActions";
import UserSection from "components/UserSection";
import { GetSection } from "components/BlogSections";
import { PostProductCardNew } from "components/ProductCards";

const PostContent = ({
  post,
  getY,
  index,
  inView,
  postWidth,
  dragging,
  getIsInView,
  mouseEnd,
  mouseMove,
  mouseStart,
  touchEnd,
  touchMove,
  touchStart,
  contentHeight,
}) => {
  const canShow = post?.files?.length > 0;
  const noDisplay = index < inView - 2 || index > inView + 3;
  const hasProducts = post?.products?.length > 0;
  const sectionHeight = hasProducts ? contentHeight - 202 : contentHeight - 100;
  const sortedSections = post?.sections?.sort(
    (a, b) => a.position - b.position
  );

  if (noDisplay) {
    return null;
  } else {
    return (
      <div
        className="js-as"
        onMouseUp={(e) => mouseEnd(e)}
        onMouseMove={(e) => mouseMove(e)}
        onMouseDown={(e) => mouseStart(e)}
        onTouchEnd={(e) => touchEnd(e)}
        onTouchMove={(e) => touchMove(e)}
        onTouchStart={(e) => touchStart(e)}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          display: "flex",
          overflow: "hidden",
          position: "absolute",
          height: contentHeight,
          transform: `translate(0px, ${getY(index)}px)`,
          transition: dragging
            ? "transform 22ms linear"
            : "transform 220ms ease-out",
        }}
      >
        <div
          style={{
            width: "calc(100% - 80px)",
            height: canShow ? contentHeight : 0,
            overflow: "hidden",
            opacity: canShow ? 1 : 0,
            transition: "all 80ms linear",
            display: "flex",
            background: "#1f1e20",
            borderLeft: "1px solid rgba(255,255,255,.12)",
            borderRight: "1px solid rgba(255,255,255,.12)",
            textAlign: "left",
          }}
        >
          <div
            style={{
              width: postWidth,
              position: "relative",
              height: contentHeight,
              flexShrink: 0,
            }}
          >
            {canShow && <Viewer post={post} isInView={getIsInView(index)} />}
          </div>
          <div
            className="p-3 jb-as"
            style={{ flexDirection: "column", width: "100%" }}
          >
            <div style={{ width: "100%" }}>
              <div className="js-ac mb-3">
                <Pill post={post} />
                <Pill2 post={post} />
              </div>
              <div className="jb-ac mb-3" style={{ width: "100%" }}>
                <UserSection
                  user={post?.user}
                  date={handleDate(post.createdAt)}
                  className="mb-0"
                />
                <PostActions post={post} />
              </div>

              <div
                style={{
                  width: "100%",
                  overflowY: "auto",
                  paddingBottom: 40,
                  maxHeight: sectionHeight,
                  display: getIsInView(index) ? "" : "none",
                }}
              >
                <div style={{ fontSize: 12 }}>{post?.location?.label}</div>
                <div className="mt-1" style={{ fontWeight: 700 }}>
                  {post?.name}
                </div>
                <div className="mt-1">{post?.caption}</div>
                {sortedSections?.map((s, key) => (
                  <GetSection section={s} blog={post} key={key} />
                ))}
              </div>
            </div>
            <div className="row" style={{ width: "100%", marginLeft: 0 }}>
              {post?.products?.map((pp, key) => (
                <PostProductCardNew product={pp} key={key} post={post} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default PostContent;
