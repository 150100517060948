import { useState, useContext, createContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { uploadFile } from './utils';
import outsyde from "@outsyde/sdk";
import { useApp } from "context";

const UploadContext = createContext();

export default function UploadContextWrapper({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [postData, setPostData] = useState({});
  const { setError, setLoading, user, acts, postTypes } = useApp();

  const handlePost = async () => {
    try {
      setLoading(true);
      let data = await outsyde.posts.get(id);
      if (user?.id === data?.user?.id) {
        let activity = acts?.find((a) => a?.id === data?.activityId);
        let postType = postTypes?.find((a) => a?.id === data?.postTypeId);
        setPostData({...data, activity, postType})
      } else {
        navigate("/feed")
      }
      setLoading(false);
    } catch(err) {
      setError(err.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    handlePost();
  }, [])

  const updatePostData = (changes) => {
    setPostData({ ...postData, ...changes });
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await outsyde.posts.delete(id);
      setLoading(false);
      navigate('/feed');
    } catch(err) {
      setLoading(false);
      setError(err?.message);
    }
  }

  const uploadLocalState = {
    postData,
    handleDelete,
    updatePostData
  };

  return <UploadContext.Provider value={uploadLocalState}>{children}</UploadContext.Provider>;
}

export function useUpload() {
  return useContext(UploadContext);
}
