import { useState, useContext, createContext } from 'react';
import { uploadFile } from './utils';
import outsyde from "@outsyde/sdk";
import { useApp } from "context";

const UploadContext = createContext();

export default function UploadContextWrapper({ children }) {
  const { setError } = useApp();
  const [uploadStep, setUploadStep] = useState(0);
  const [postData, setPostData] = useState({});
  const [uploadComplete, setUploadComplete] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileId, setFileId] = useState(null);

  const reset = () => {
    setProgress(0);
    setFileId(null);
    setPostData({});
    setUploadComplete(false);
  }

  const handleCancel = () => {
    reset();
    // if (postData?.postId) {
    //   outsyde.posts.delete(postData?.postId);
    // }
  }

  const updatePostData = (changes) => {
    setPostData({ ...postData, ...changes });
  };

  const handleMedia = async (ev) => {
    setUploadComplete(false);
    let files = ev?.target?.files;

    if (files?.length > 0) {
      let media = files[0] || {};
      let type = media?.type

      try {
        let post = await outsyde.posts.create();
        updatePostData({
          post,
          ...media,
          contentType: type,
          display: URL.createObjectURL(media)
        });
        let newFileId = await uploadFile(media, 'main', setProgress)
        outsyde.posts.addFiles(post?.id, newFileId);
        if (!type?.includes("video")) {
          outsyde.files.processImages({ postId: post?.id, fileId: newFileId });
        }
        setUploadComplete(true);
      } catch (err) {
        handleCancel();
        setError("There was a problem creating your post, please try again");
      }
    }
  };

  const uploadLocalState = {
    fileId,
    progress,
    postData,
    showAlert,
    uploadStep,
    uploadComplete,

    reset,
    handleMedia,
    setShowAlert,
    handleCancel,
    setUploadStep,
    updatePostData
  };

  return <UploadContext.Provider value={uploadLocalState}>{children}</UploadContext.Provider>;
}

export function useUpload() {
  return useContext(UploadContext);
}
