import ProApplicationModal from 'components/ProApplicationModal';
import ActivitiesModal from 'components/ActivitiesModal';
import { useState, useEffect, useRef } from 'react';
import { UserIcon } from 'components/UserSection';
import { useNavigate } from "react-router";
import { getYear } from 'date-fns';
import { useApp } from 'context';

const Footer = () => {
  const navigate = useNavigate();
  const today = new Date();
  const year = getYear(today);

  return(
    <div className='footer mb-4' style={{ textAlign: 'center' }}>
      <div className='container mt-5 mb-5 pt-5' style={{ borderTop: '1px solid rgba(255,255,255,.12)' }}>
        <div className='row'>
          <div className='col-12 mb-4 mb-lg-0 col-lg-3' style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <img src="/assets/imgs/white-logo.png" style={{ width: 80 }} className="mb-1" alt="outsyde-logo" />
            <div style={{ cursor: 'pointer', fontSize: 38, fontWeight: 700, lineHeight: 1 }} onClick={() => navigate('/')}>OUTSYDE</div>
          </div>
          <div className='col-12 mb-4 mb-lg-0 col-lg-2' style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 700, textAlign: 'center' }} className='mb-2'>PLATFORM</div>
            <div className='jc-ac'>
              <a href='/feed' className='mb-1 green-text-hover footer-link'>Watch</a>
            </div>
            <div className='jc-ac'>
              <a href='/read' className='mb-1 green-text-hover footer-link'>Read</a>
            </div>
            <div className='jc-ac'>
              <a href='/listen' className='mb-1 green-text-hover footer-link'>Listen</a>
            </div>
            <div className='jc-ac'>
              <a href='/locations' className='mb-1 green-text-hover footer-link'>Explore</a>
            </div>
          </div>
          <div className='col-12 mb-4 mb-lg-0 col-lg-2' style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 700, textAlign: 'center' }} className='mb-2'>GENERAL</div>
            <div className='jc-ac'>
              <a href='/about-outsyde' className='mb-1 green-text-hover footer-link'>About</a>
            </div>
            <div className='jc-ac'>
              <a href='/outsyde-discord' className='mb-1 green-text-hover footer-link'>Discord</a>
            </div>
            <div className='jc-ac'>
              <a href='/outsyde-support' className='mb-1 green-text-hover footer-link'>Support</a>
            </div>
            <div className='jc-ac'>
              <a href='/contact-outsyde' className='mb-1 green-text-hover footer-link'>Contact</a>
            </div>
            <div className='jc-ac'>
              <a href='/privacy-policy' className='mb-1 green-text-hover footer-link'>Privacy Policy</a>
            </div>
            <div className='jc-ac'>
              <a href='/terms-conditions' className='mb-1 green-text-hover footer-link'>Terms & Conditions</a>
            </div>
          </div>
          <div className='col-12 mb-4 mb-lg-0 col-lg-2' style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 700, textAlign: 'center' }} className='mb-2'>CREATORS</div>
            <div className='jc-ac'>
              <a href='/legal' className='mb-1 green-text-hover footer-link'>Legal</a>
            </div>
            <div className='jc-ac'>
              <a href='/creator-support' className='mb-1 green-text-hover footer-link'>Support</a>
            </div>
            <div className='jc-ac'>
              <a href='/docs' className='mb-1 green-text-hover footer-link'>Documentation</a>
            </div>
          </div>
          <UserArea />
        </div>
      </div>
      <div className='jc-ac' style={{ flexDirection: 'column' }}>
        <div className='mb-3 mt-lg-0' style={{ fontWeight: 700 }}>&copy; Outsyde {year}, All Rights Reserved</div>
        <div className='jc-ac mt-1' style={{ fontSize: 14, fontWeight: 700 }}>
          <img src="/assets/imgs/la-outline.png" style={{ width: 50 }} className="me-3" alt="Louisiana" />
          <img src="/assets/imgs/hi-outline.png" style={{ width: 60 }} className="" alt="Hawaii" />
        </div>
      </div>
    </div>
  )
}

export default Footer;

const UserArea = () => {
  const { user } = useApp();
  const [userFavorites, setUserFavorites] = useState([]);
  const [applyModal, setApplyModal] = useState(false);
  const [favChanges, setFavChanges] = useState(false);
  const [actsModal, setActsModal] = useState(false);
  const notCreator = user?.isPro === 'false';
  const isCreator = user?.isPro === 'true';
  const navigate = useNavigate();

  useEffect(() => {
    if (actsModal) {
      setUserFavorites(user?.activities || []);
    }

  }, [actsModal])

  const handleFavorites = (changes) => {
    setFavChanges(true)
    let selectedIds = userFavorites?.map((s) => s?.id);

    if (selectedIds?.includes(changes?.id)) {
      let remaining = userFavorites?.filter((f) => f?.id !== changes?.id)
      setUserFavorites(remaining)
    } else {
      setUserFavorites([...userFavorites, changes])
    }
  }

  if (user?.id) {
    return(
      <div className='col-12 col-lg-3' style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <div className='jc-ac mb-2'>
          <UserIcon user={user} />
          <div className='ms-2' style={{ fontWeight: 700, textAlign: 'center' }}>{user?.username}</div>
        </div>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/profile/${user?.id}`)} className='mb-1 green-text-hover footer-link'>Profile</div>
        <div style={{ cursor: 'pointer' }} onClick={() => navigate(`/saved`)} className='mb-1 green-text-hover footer-link'>Saved</div>
        <div style={{ cursor: 'pointer' }} onClick={() => setActsModal(true)} className='mb-1 green-text-hover footer-link'>Activities</div>
        {isCreator && <div style={{ cursor: 'pointer' }} onClick={() => navigate('/add-post')} className='mb-1 green-text-hover footer-link'>Add Post</div>}
        {isCreator && <div style={{ cursor: 'pointer' }} onClick={() => navigate('/wallet')} className='mb-1 green-text-hover footer-link'>Wallet</div>}
        {notCreator && <div style={{ cursor: 'pointer' }} onClick={() => setApplyModal(true)} style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} className='js-ac'>Become a Pro</div>}
        <ProApplicationModal open={applyModal} handleClose={() => setApplyModal(false)} />
        <ActivitiesModal open={actsModal} handleClose={() => setActsModal(false)} header='Favorite Activities' selected={userFavorites} handleChange={handleFavorites} />
      </div>
    )
  } else {
    return(
      <div className='col-12 col-lg-3' style={{ flexDirection: 'column', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <a href="/login" style={{ width: '80%', textDecoration: 'none' }} className="btn2">LOGIN</a>
        <a href="/register" style={{ width: '80%', textDecoration: 'none' }} className="btn1 mt-3">SIGN UP</a>
      </div>
    )
  }
}
