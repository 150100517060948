import { IoBookmarkOutline, IoEyeOffOutline, IoBookmark, IoShareSocialOutline, IoLogoInstagram, IoLogoTiktok, IoPencilOutline, IoLinkOutline, IoEllipsisVerticalOutline } from "react-icons/io5";
import EditProfileModal from 'components/EditProfileModal';
import { ShareProfileModal } from 'components/ShareModal';
// import { PodcastCard1 } from 'components/PodcastCards';
// import { ProductCard2 } from 'components/ProductCards';
import { UserIcon } from 'components/UserSection';
import { BlogCard1 } from 'components/BlogCards';
import CenterModal from 'components/CenterModal';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import outsyde from "@outsyde/sdk";
import { useApp } from 'context';

const Profile = () => {
  const { id } = useParams();
  const [optionsModal, setOptionsModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [profile, setProfile] = useState({});
  const [posts, setPosts] = useState([]);
  const { user } = useApp();
  const canEdit = user?.id === id;
  const links = profile?.links?.entries;
  const [isFollowed, setIsFollowed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  if (window.document) {
    window.document.body.style.overflowY = 'auto';
  }

  const handleUser = async () => {
    try {
      let [_user, _posts] = await Promise.all([
        outsyde.users.get(id),
        outsyde.users.getPosts(id, { filter: { published: { _eq: true } } }),
      ])
      const likeId = _user?.followedBy?.length > 0;
      if (likeId) {
        setIsFollowed(true)
      }
      setProfile(_user)
      setPosts(_posts)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleUser()
    //eslint-disable-next-line
  }, [id])

  const handleFollow = async () => {
    try {
      if (isFollowed) {
        setIsFollowed(false);
        await outsyde.users.unFollowUser(user?.id, [id]);
      } else {
        setIsFollowed(true);
        await outsyde.users.followUser(user?.id, [id]);
      }
    } catch(err) {
      console.log(err);
    }
  }

  return(
    <div className="App">
      <Header />
      <div className='container pt-4'>
        <div style={{ width: '100%' }} className='js-ac flex-col mb-4'>
          <UserIcon user={profile} style={{ width: 150, height: 150, borderRadius: '50%', border: '2px solid white', objectFit: 'cover', fontSize: 32 }} />
          <div className='jc-ac mt-3'>
            <div className='' style={{ fontWeight: 700, lineHeight: 1.25, fontSize: 32 }}>{profile?.username}</div>
            {canEdit && <div onClick={() => setEditModal(true)} style={{ color: 'rgb(79, 115, 97)', fontSize: 20 }} className='ms-2'><IoPencilOutline /></div>}
          </div>
          {profile?.bio && <div className='mt-1' style={{  }}>{profile?.bio}</div>}
          <div className='jc-ac'>
            <div onClick={handleFollow} style={{ color: 'rgb(79, 115, 97)' }} className='ms-2 me-2 mt-1 mb-1'>
              {!isFollowed && <IoBookmarkOutline />}
              {isFollowed && <IoBookmark />}
            </div>
            {profile?.instagram && (
              <a style={{ color: 'rgb(79, 115, 97)' }} rel="noopener noreferrer" target="_blank" className='ms-2 me-2 mt-1 mb-1' href={`https://instagram.com/${profile?.instagram}`}><IoLogoInstagram /></a>
            )}
            {profile?.tiktok && (
              <a style={{ color: 'rgb(79, 115, 97)' }} rel="noopener noreferrer" target="_blank" className='ms-2 me-2 mt-1 mb-1' href={`https://tiktok.com/@${profile?.tiktok}`}><IoLogoTiktok /></a>
            )}
            <div onClick={() => setOptionsModal(true)} style={{ color: 'rgb(79, 115, 97)' }} className='ms-2'><IoEllipsisVerticalOutline /></div>
          </div>
          {links?.map((l) => (
            <div key={l?.id}>
              <a style={{ color: 'white', textDecoration: 'none', fontWeight: 600 }} rel="noopener noreferrer" target="_blank" href={`https://${l?.url}`}>
                <IoLinkOutline className='me-1' />
                {l?.name}
              </a>
            </div>
          ))}
        </div>
        <div className='jc-as mt-4'>
          <div style={{ width: '100%', maxWidth: 900 }}>
            <div className='row' style={{ minHeight: '60vh' }}>
              {posts?.map((p, key) => (
                <BlogCard1 post={p} key={key} user={profile} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer className=" " />
      {canEdit && <EditProfileModal open={editModal} handleClose={() => setEditModal(false)} />}
      <ShareProfileModal profile={profile} open={shareModal} handleClose={() => setShareModal(false)} />
      <OptionsModal canEdit={canEdit} isFollowed={isFollowed} profile={profile} open={optionsModal} setOpen={setOptionsModal} handleFollow={handleFollow} setShareModal={setShareModal} setEditModal={setEditModal} />
    </div>
  )
}

export default Profile;

const OptionsModal = ({ profile, canEdit, isFollowed, open, setOpen, handleFollow, setEditModal, setShareModal }) => {
  const { setLoading, user, setError } = useApp();

  const handleBlock = async () => {
    try {
      setLoading(true);
      await outsyde.users.blockUsers(user?.id, profile?.id);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue hiding this post, please try again");
    }
  };

  const handleShareModal = () => {
    setOpen(false);
    setShareModal(true)
  }

  const handleEditModal = () => {
    setOpen(false);
    setEditModal(true)
  }

  return(
    <>
      <CenterModal open={open} handleClose={() => setOpen(false)}>
        <div className='pt-2 ps-2 pe-2 pb-4' style={{ width: 300, color: 'white' }}>
          <div className='ps-3 pe-3 js-ac mt-3' style={{ cursor: 'pointer' }}  onClick={handleFollow}>
            {isFollowed ? <IoBookmark className='me-2' /> : <IoBookmarkOutline className='me-2' />}
            {isFollowed ? 'Unsave' : 'Save'}
          </div>
          <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }}  onClick={handleShareModal}>
            <IoShareSocialOutline className='me-2' />
            Share
          </div>
          <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }} onClick={handleBlock}>
            <IoEyeOffOutline className='me-2' />
            Block
          </div>
          {canEdit && (
            <div className='ps-3 pe-3 mt-2 js-ac' style={{ cursor: 'pointer' }} onClick={handleEditModal}>
              <IoPencilOutline className='me-2' />
              Edit
            </div>
          )}
        </div>
      </CenterModal>
    </>
  )
}
