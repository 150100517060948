// External Imports
import { useState, useContext, createContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import outsyde from "@outsyde/sdk";

const AppContext = createContext();

export default function AppContextWrapper({ acts, postTypes, children }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [posts, setPosts] = useState([]);
  const [inView, setInview] = useState(0);
  const [muted, setMuted] = useState(true);
  const [toasts, setToasts] = useState([]);
  const [filters, setFilters] = useState({});
  const [locationTypes, setLocationTypes] = useState([]);
  const [accountModal, setAccountModal] = useState(false);

  const savedUser = localStorage.getItem("outsyde_user");
  const parsedUser = JSON.parse(savedUser) || {};
  const [user, setUser] = useState(parsedUser);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const urlActivities = urlParams.get("activities");

  const width = window.innerWidth;
  const height = window.innerHeight;
  const contentHeight = width > 991 ? height - 113 : height - 62;

  const updateFilter = (updates = {}) => {
    setFilters({ ...filters, ...updates });
  };

  const handleUser = async () => {
    if (parsedUser?.id) {
      try {
        let [data, activityData] = await Promise.all([
          outsyde.users.get(),
          outsyde.users.getFavorites(),
        ]);
        setUser({ ...data, activities: activityData });
        if (urlActivities) {
          updateFilter({ activities: [urlActivities] });
        } else {
          updateFilter({ activities: activityData?.map((a) => a?.id) });
        }
        localStorage.setItem("outsyde_user", JSON.stringify(data));
      } catch (err) {
        setUser({});
        localStorage.setItem("outsyde_user", JSON.stringify({}));
      }
    } else {
      setUser({});
      localStorage.setItem("outsyde_user", JSON.stringify({}));
    }
  };

  const addToast = (msg) => {
    setToasts([
      ...toasts,
      { message: msg, id: uuidv4(), time: new Date(), open: true },
    ]);
  };

  const handleLocationTypes = async () => {
    if (locationTypes?.length === 0) {
      try {
        let data = await outsyde.locations.getTypes();
        setLocationTypes(data);
      } catch (err) {}
    }
  };

  const handleLogout = () => {
    setUser({});
    outsyde.auth.signout();
    localStorage.setItem("outsyde_user", JSON.stringify({}));
  };

  useEffect(() => {
    handleUser();
  }, []);

  const getPostType = (post) => {
    return postTypes?.find((pt) => pt?.id === post?.postTypeId) || {}
  }

  const getActivity = (post) => {
    return acts?.find((act) => act?.id === post?.activityId) || {}
  }

  const appLocalState = {
    acts,
    user,
    posts,
    error,
    muted,
    inView,
    toasts,
    setUser,
    loading,
    filters,
    setMuted,
    setPosts,
    setError,
    addToast,
    postTypes,
    setInview,
    setToasts,
    setFilters,
    handleUser,
    setLoading,
    getActivity,
    getPostType,
    accountModal,
    handleLogout,
    updateFilter,
    locationTypes,
    contentHeight,
    setAccountModal,
    handleLocationTypes,
  };

  return (
    <AppContext.Provider value={appLocalState}>{children}</AppContext.Provider>
  );
}

export function useApp() {
  return useContext(AppContext);
}
