import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import CenterModal from 'components/CenterModal';
import { uploadFile } from 'pages/AddPost/utils';
import { v4 as uuidV4 } from 'uuid';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const EditProfileModal = ({ open, handleClose }) => {
  const { user, setError, setLoading, handleUser } = useApp();
  const [bio, setBio] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [instagram, setInstagram] = useState("");
  const [links, setLinks] = useState([]);
  const [file, setFile] = useState(null)
  const [proPicDisplay, setProPicDisplay] = useState(null);
  const sortedLinks = links?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt))

  useEffect(() => {
    if (open) {
      setBio(user?.bio || "")
      setTiktok(user?.tiktok || "")
      setInstagram(user?.instagram || "")
      if (user?.profilePic) {
        setProPicDisplay(user?.profilePic)
      }
      let filteredLinks = user?.links?.entries?.filter((l) => l?.name && l?.url)
      if (filteredLinks?.length > 0) {
        setLinks(filteredLinks);
      } else {
        setLinks([{ name: '', url: '', createdAt: new Date(), id: uuidV4() }])
      }
    }
  }, [open])

  const standardizeUrl = (url) => {
    let u1 = url?.replace("https://", "")
    let u2 = u1?.replace("http://", "")
    let u3 = u2?.replace("www.", "")
    return `https://${u3}`;
  }

  const handleDone = async () => {
    if (!bio) {
      setError("Please add a bio!")
    } else if (!tiktok && !instagram) {
      setError("Please add either an Instagram or Tiktok account!")
    } else {
      try {
        setLoading(true);
        let filteredLinks = links?.filter((l) => l?.name && l?.url);
        let formattedLinks = filteredLinks?.map((l) => ({ ...l, url: standardizeUrl(l?.url) }))
        await outsyde.users.update(user?.id, { bio, tiktok, instagram, profilePicId: file, links: { entries: formattedLinks } });
        handleUser()
        setLoading(false);
        handleClose()
      } catch(err) {
        setLoading(false);
        setError(err?.message)
      }
    }
  }

  const handleProPic = async (ev) => {
    let files = ev?.target?.files;

    if (files?.length > 0) {
      let media = files[0] || {};

      try {
        setLoading(true);
        setProPicDisplay(URL.createObjectURL(media))
        let _fileId = await uploadFile(media, 'propic');
        setFile(_fileId);
        setLoading(false);
      } catch (err) {
        setError(err?.message);
        setLoading(false);
      }
    }
  };

  const getProPicSrc = () => {
    if (proPicDisplay?.filePath) {
      return `${CDN_ROUTE}${proPicDisplay?.filePath}`
    } else {
      return proPicDisplay
    }
  }

  const updateLink = (id, changes = {}) => {
    let toUpdate = links?.find((l) => l?.id === id);
    let others = links?.filter((l) => l?.id !== id);
    setLinks([...others, {...toUpdate, ...changes}])
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ width: 600, maxWidth: "100%", color: 'white' }} className='p-3'>
        <div className='mb-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Edit Profile</div>
        <div className='jc-as mb-3'>
          <div style={{ width: 96, height: 96, borderRadius: '50%', border: '1px solid white', textTransform: 'uppercase', color: 'white', flexShrink: 0, fontSize: 15, fontWeight: 700, cursor: 'pointer', textAlign: 'center', position: 'relative', overflow: 'hidden' }} className='jc-ac'>
            {proPicDisplay && <img alt='' src={getProPicSrc()} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
            {!proPicDisplay && <>PROFILE PCITURE</>}
            <input onChange={handleProPic} style={{ width: '100%', height: '100%', opacity: 0, position: 'absolute', left: 0, top: 0 }} type='file' multiple={false} accept='.png, .jpg' />
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            <Input value={instagram} onChange={setInstagram} label="Instagram" className='mb-2' />
          </div>
          <div className='col-12 col-lg-6'>
            <Input value={tiktok} onChange={setTiktok} label="Tiktok" className='mb-2' />
          </div>
          <div className='col-12'>
            <TextArea value={bio} onChange={setBio} label="Bio" className='mb-2' style={{ height: 100 }} />
          </div>

          <div className='col-12 mb-3'>
            <div className='jb-ac mb-1'>
              <div style={{ color: '#5c7d6d', fontSize: 14, fontWeight: 600 }}>LINKS</div>
              <div onClick={() => setLinks([...links, { name: '', url: '', createdAt: new Date(), id: uuidV4() }])} style={{ color: '#5c7d6d', fontSize: 14, fontWeight: 600 }}>ADD</div>
            </div>
            {sortedLinks?.map((l, key) => <LinkItem key={l?.id} link={l} updateLink={updateLink} />)}
          </div>

          <div className='col-12'>
            <div onClick={handleDone} className="btn1">SAVE</div>
          </div>
        </div>
      </div>
    </CenterModal>
  )
}

export default EditProfileModal;

const LinkItem = ({ link = {}, updateLink }) => {
  const { name, url, id } = link;

  return(
    <div className='row mb-2'>
      <div className='col-6'>
        <Input value={name} onChange={(v) => updateLink(id, { name: v })} label="Name" placeholder='My Outsyde Profile' />
      </div>
      <div className='col-6'>
        <Input value={url} onChange={(v) => updateLink(id, { url: v })} label="URL" placeholder="outsyde.app/me" />
      </div>
    </div>
  )
}
