import UserSection from 'components/UserSection';
import { useEffect, useState } from 'react';
import {useParams} from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import outsyde from "@outsyde/sdk";
import {format} from 'date-fns';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const PodcastEpisode = () => {
  const { id, episodeid } = useParams();
  const [episode, setEpisode] = useState({});

  const handlePodcast = async () => {
    try {
      // let data = await outsyde.podcasts.getEpisode(episodeid);
      // setEpisode(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handlePodcast()
  }, [id])

  return(
    <div className='App'>
      <Header />
      <div className='container mt-3 pb-5 d-flex justify-content-center'>
        <div style={{ maxWidth: 1000, width: '100%' }}>


        </div>
      </div>
      <Footer />
    </div>
  )
}

export default PodcastEpisode;
