import Video from "./Video";
import Photo from "./Photo";
// import { getIsVideo } from "utils";
// import { useApp } from "context";
// import { useEffect } from "react";

const Viewer = ({
  post,
  isInView,
  styles = {},
  dragging = false
}) => {
  const mainFile = post?.files?.find((f) => f?.fileType === 'main');
  const isVideo = mainFile?.contentType?.includes("video");

  const getContent = () => {
    if (isVideo) {
      return (
        <Video
          post={post}
          styles={styles}
          isInView={isInView}
          dragging={dragging}
        />
      );
    } else {
      return <Photo post={post} file={mainFile} />;
    }
  };

  return (
    <div
      style={{ width: "100%", height: "100%", position: "relative", ...styles }}
    >
      {getContent()}
    </div>
  );
};

export default Viewer;
