import { useApp } from 'context';
import { useState } from 'react';
import { useEffect } from 'react';
import { handleDate } from 'utils';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';
import Header from 'components/Header';
import { BsCheck } from 'react-icons/bs';
import TextArea from 'components/TextArea';
import { useNavigate } from "react-router";
import GeoSearch from 'components/GeoSearch';
import UserSection from 'components/UserSection';
import CenterModal from 'components/CenterModal';
import ProductModal from 'components/SelectProductModal';
import UploadContextWrapper, { useUpload } from './context';
import { PostProductCardNew } from 'components/ProductCards';
import { IoTrashOutline, IoImageOutline } from 'react-icons/io5';

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const EditPost = () => {
  const { user, acts, postTypes, setError, setLoading, contentHeight } = useApp()
  const { postData, handleDelete, updatePostData } = useUpload();
  const postWidth = contentHeight / 16 * 9;
  const [giveaways, setGiveaways] = useState([]);
  const [activityModal, setActivityModal] = useState(false);
  const [typeModal, setTypeModal] = useState(false);
  const [productsModal, setProductsModal] = useState(false);
  const [compModal, setCompModal] = useState(false);
  const navigate = useNavigate();

  const handleGiveaways = async () => {
    try {
      let data = await outsyde.giveaways.getAll();
      setGiveaways(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // handleGiveaways();
    if (!user?.id) {
      console.log("NO USER!");
    }
  }, [])

  const handleUpdate = async () => {
    let { activity, caption, name } = postData;

    if (!activity?.id) {
      setError("Please add an activity!");
    } else if (!name) {
      setError("Please add a name!");
    } else {
      setLoading(true);

      try {
        let { postType, location,  competition, id } = postData;

        let postBody = {
          activityId: activity?.id,
          postTypeId: postType?.id,
          locationId: location?.id,
          giveawayId: competition?.id,
          caption,
          name
        }

        await outsyde.posts.update(id, postBody);

        let { products } = postData;
        if (products?.length > 0) {
          let productIds = products?.map((p) => p?.id);
          await outsyde.posts.addProducts(id, productIds);
        }

        setLoading(false);
        navigate('/feed')
      } catch (err) {
        setLoading(false);
        setError(err?.message);
      }
    }
  };
  //<div onClick={() => setCompModal(true)} className="ms-2" style={{ cursor: 'pointer', background: "#baa465", lineHeight: 1, padding: '2px 6px', color: 'white', fontSize: 12, fontWeight: 700, textShadow: '1px 1px 2px rgba(0,0,0,.2)', textTransform: 'uppercase' }}>{postData?.competition?.name || "COMPETITION"}</div>
  return(
    <>
      <div className='App'>
        <Header />
        <div
          className='jc-as'
          style={{
            width: "100%",
            overflow: "hidden",
            height: contentHeight
          }}
        >
          <div
            style={{
              width: "100%",
              height: '100%',
              overflow: "hidden",
              transition: "all 80ms linear",
              display: 'flex',
              background: '#1f1e20',
              borderLeft: '1px solid rgba(255,255,255,.12)',
              borderRight: '1px solid rgba(255,255,255,.12)',
              textAlign: 'left'
            }}
          >
            <div className='jc-ac' style={{ width: postWidth, position: 'relative', height: '100%', flexShrink: 0, position: 'relative', background: 'rgba(54,54,54,1)' }}>
              {postData?.files?.length > 0 && <Display postData={postData} />}
            </div>
            <div className='p-3 jb-as' style={{ flexDirection: 'column', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <div className='js-ac mb-3'>
                  <div onClick={() => setActivityModal(true)} style={{ cursor: 'pointer', background: '#4f7361', lineHeight: 1, padding: '2px 6px', color: 'white', fontSize: 12, fontWeight: 700, textShadow: '1px 1px 2px rgba(0,0,0,.2)', textTransform: 'uppercase' }}>{postData?.activity?.name || "ACTIVITY"}</div>
                  <div onClick={() => setTypeModal(true)} className="ms-2" style={{ cursor: 'pointer', background: "#97477D", lineHeight: 1, padding: '2px 6px', color: 'white', fontSize: 12, fontWeight: 700, textShadow: '1px 1px 2px rgba(0,0,0,.2)', textTransform: 'uppercase' }}>{postData?.postType?.name || "TYPE"}</div>
                </div>
                <div className='jb-ac mb-3' style={{ width: '100%' }}>
                  <UserSection user={user} date={handleDate(postData?.createdAt)} className="mb-0" />
                  <div onClick={handleDelete} className='je-ac' style={{ fontSize: 12, color: 'red', cursor: 'pointer' }}>
                    <IoTrashOutline className='me-1' />
                    Delete Post
                  </div>
                </div>
                <GeoSearch onChange={(v) => updatePostData({ location: v })} value={postData?.location?.name} />
                <Input className='mt-2' placeholder='NAME' value={postData?.name || ""} onChange={(v) => updatePostData({ name: v })} />
                <TextArea className='mt-2' placeholder='CONTENT' value={postData?.caption || ""} onChange={(v) => updatePostData({ caption: v })} />
              </div>
              <div style={{ width: '100%' }}>
                <ProductSection postData={postData} productsModal={productsModal} updatePostData={updatePostData} setProductsModal={setProductsModal} />
                <div className='row mt-3'>
                  <div className='col-6'>
                    <div onClick={() => setProductsModal(true)} style={{ height: 44, background: 'rgba(54,54,54,1)', border: '1px solid rgba(255,255,255,.2)' }} className='jc-ac'>
                      ADD PRODUCTS
                    </div>
                  </div>
                  <div className='col-6'>
                    <div onClick={handleUpdate} className='btn1'>UPDATE</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CenterModal open={activityModal} handleClose={() => setActivityModal(false)}>
        <div style={{ width: 400, height: 600 }}>
          <div className='mt-3 mb-3 ps-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Post Activity</div>
          <div style={{ height: 550, overflow: 'auto' }}>
            {acts?.map((act, key) => <ActivityItem key={key} act={act} postData={postData} updatePostData={updatePostData} />)}
          </div>
        </div>
      </CenterModal>
      <CenterModal open={typeModal} handleClose={() => setTypeModal(false)}>
        <div style={{ width: 400, height: 600 }}>
          <div className='mt-3 mb-3 ps-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Post Type</div>
          <div style={{ height: 550, overflow: 'auto' }}>
            {postTypes?.map((type, key) => <TypeItem key={key} type={type} postData={postData} updatePostData={updatePostData} />)}
          </div>
        </div>
      </CenterModal>
      <CenterModal open={compModal} handleClose={() => setCompModal(false)}>
        <div style={{ width: 400, height: 600 }}>
          <div className='mt-3 mb-3 ps-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Competition</div>
          <div style={{ height: 550, overflow: 'auto' }}>
            {giveaways?.map((type, key) => <CompItem key={key} type={type} postData={postData} updatePostData={updatePostData} />)}
          </div>
        </div>
      </CenterModal>
    </>
  )
}

const ProductSection = ({ postData, productsModal, updatePostData, setProductsModal }) => {
  const existingProducts = postData?.products || [];

  return(
    <>
      <div className='row'>
        {postData?.products?.map((pp, key) => <PostProductCardNew product={pp} key={key} />)}
      </div>
      <ProductModal open={productsModal} handleClose={() => setProductsModal(false)} existingProducts={existingProducts} onChange={updatePostData} />
    </>
  )
}

const ActivityItem = ({ act, postData, updatePostData }) => {
  let isSelected = act?.name === postData?.activity?.name;

  return(
    <div onClick={() => updatePostData({ activity: act })} className='ps-3 js-ac' style={{ height: 48, color: 'white', cursor: 'pointer' }}>
      <div className='me-3 jc-ac' style={{ width: 30, height: 30, borderRadius: '50%', border: '1px solid white', background: isSelected ? "#4f7361" : "" }}>
        {isSelected && <BsCheck style={{ fontSize: 20, color: 'white' }} />}
      </div>
      {act?.name}
    </div>
  )
}

const TypeItem = ({ type, postData, updatePostData }) => {
  let isSelected = type === postData?.postType

  return(
    <div onClick={() => updatePostData({ postType: type })} className='ps-3 js-ac' style={{ height: 48, color: 'white', cursor: 'pointer' }}>
      <div className='me-3 jc-ac' style={{ width: 30, height: 30, borderRadius: '50%', border: '1px solid white', background: isSelected ? "#4f7361" : "" }}>
        {isSelected && <BsCheck style={{ fontSize: 20, color: 'white' }} />}
      </div>
      {type?.name}
    </div>
  )
}

const CompItem = ({ type, postData, updatePostData }) => {
  let isSelected = type === postData?.competition

  return(
    <div onClick={() => updatePostData({ competition: type })} className='ps-3 js-ac' style={{ height: 48, color: 'white', cursor: 'pointer' }}>
      <div className='me-3 jc-ac' style={{ width: 30, height: 30, borderRadius: '50%', border: '1px solid white', background: isSelected ? "#4f7361" : "" }}>
        {isSelected && <BsCheck style={{ fontSize: 20, color: 'white' }} />}
      </div>
      <div>
        {type?.name}
        <div style={{ color: "#5c7d6d", fontSize: 12 }}>{type.activity?.name}</div>
      </div>
    </div>
  )
}

const WrappedEditPost = () => {
  return(
    <UploadContextWrapper>
      <EditPost />
    </UploadContextWrapper>
  )
}

export default WrappedEditPost;

const Display = ({ postData = {} }) => {
  let files = postData?.files;
  let file = files[0] || {};
  let { contentType, filePath } = file;

  if (filePath) {
    if (contentType?.includes("video")) {
      return (
        <video width="100%" height="100%" style={{ width: '100%', height: '100%', objectFit: 'cover' }} autoPlay muted={true}>
          <source src={`${CDN_ROUTE}${filePath}`} type="video/mp4" />
        </video>
      )
    } else {
      return(
        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt='' src={`${CDN_ROUTE}${filePath}`} />
      )
    }
  } else {
    return <IoImageOutline style={{ fontSize: 42, color: '#5c5c5c' }} />
  }
}

//e31d8b9d-0ec1-4798-87c9-950fb5e051af
//b76b3923-b39f-4af2-bc16-579bb920f57e
//57681669-6b40-415a-a360-9fc7dc514595
//0b72fea0-5d98-46ce-95a2-fb2843252770
//ef8f6d80-b94b-4757-b937-2e5c582cfd1d
