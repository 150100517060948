import outsyde from "@outsyde/sdk";
import Header from "components/Header";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { handleDate } from "utils";
import Viewer from "components/Viewer";
import PostActions from "components/PostActions";
import UserSection from "components/UserSection";
import { PostProductCardNew } from "components/ProductCards";
import Pill, { Pill2 } from "components/Pill";
import { useApp } from "context";
import { GetSection } from "components/BlogSections";
import SmallPostContent from './SmallContent';

const Post = () => {
  const width = window.innerWidth;
  const isSmall = width < 992;

  if (window.document) {
    window.document.body.style.overflowY = "hidden";
  }
  const { id } = useParams();
  const [post, setPost] = useState({});
  const { acts, contentHeight } = useApp();
  const postWidth = (contentHeight / 16) * 9;
  const act = acts?.find((a) => a?.id === post?.activityId);
  const hasProducts = post?.products?.length > 0;
  const sectionHeight = hasProducts ? contentHeight - 202 : contentHeight - 100;
  const sortedSections = post?.sections?.sort(
    (a, b) => a.position - b.position
  );

  const getPost = async () => {
    let data = await outsyde.posts.get(id);
    setPost(data);
  };

  useEffect(() => {
    if (id) {
      getPost();
    }
    //eslint-disable-next-line
  }, [id]);

  if (isSmall) {
    return (
      <div className="App" style={{ height: "100vh" }}>
        <Header />
        <div style={{ position: "relative", height: contentHeight, overflow: 'hidden' }}>
          <SmallPostContent
            index={0}
            inView={0}
            post={post}
            postWidth={postWidth}
            getIsInView={() => true}
            getY={() => { return 0 }}
            contentHeight={contentHeight}
          />
        </div>
      </div>
    )
  } else {
    return (
      <div className="App">
        <Header />
        <div
          className="js-as"
          style={{
            width: "100%",
            display: "flex",
            overflow: "hidden",
            height: "100vh - 113px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              transition: "all 80ms linear",
              display: "flex",
              background: "#1f1e20",
              textAlign: "left",
            }}
          >
            <div
              style={{
                width: postWidth,
                position: "relative",
                height: contentHeight,
                flexShrink: 0,
              }}
            >
              {post?.files?.length > 0 && <Viewer post={post} isInView={true} />}
            </div>
            <div
              className="p-3 jb-as"
              style={{ flexDirection: "column", width: "100%" }}
            >
              <div style={{ width: "100%" }}>
                <div className="js-ac mb-3">
                  <Pill post={post} />
                  <Pill2 post={post} />
                </div>
                <div className="jb-ac mb-4" style={{ width: "100%" }}>
                  <UserSection
                    user={post?.user}
                    date={handleDate(post.createdAt)}
                    className="mb-0"
                  />
                  <PostActions post={post} />
                </div>

                <div
                  style={{
                    width: "100%",
                    paddingBottom: 40,
                    maxHeight: sectionHeight,
                    overflowY: "auto",
                  }}
                >
                  <div style={{ fontSize: 12 }}>{post?.location?.label}</div>
                  <div className="mt-1" style={{ fontWeight: 700 }}>
                    {post?.name}
                  </div>
                  <div className="mt-1">{post?.caption}</div>
                  {sortedSections?.map((s, key) => (
                    <GetSection section={s} blog={post} key={key} />
                  ))}
                </div>
              </div>
              <div className="row" style={{ width: "100%", marginLeft: 0 }}>
                {post?.products?.map((pp, key) => (
                  <PostProductCardNew product={pp} key={key} post={post} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Post;
