// import { Portal } from 'react-portal';
// import { IoIosHeart } from "react-icons/io";
// import { useWindowSizes } from "react-use-window-sizes";
import { useState } from "react";
// import Viewer from "components/Viewer";
// import HomeActions from "components/HomeActions";
// import { useHomeActions } from "hooks/useHomeActions";
// import ProductModal from "components/Modals/ProductModal";
// import { useApp } from "context";
import { useNavigate } from "react-router";

const CDN_ROUTE = process.env.REACT_APP_CDN_ROUTE;

const SmallViewer = ({ posts, width, children }) => {
  return (
    <div className="js-as" style={{ flexWrap: "wrap" }}>
      {children}
      {posts?.map((post, index) => (
        <Post post={post} width={width} key={`${post?.id}-${index}`} />
      ))}
    </div>
  );
};

export default SmallViewer;

const Post = ({ post, width }) => {
  const third = width / 3;
  const navigate = useNavigate();
  const [errorPath, setErrorPath] = useState("");
  // const { pathname } = useLocation();

  let microFile = post?.files?.find((pf) => pf?.fileType === 'micro')
  let microPath = `${CDN_ROUTE}${microFile?.filePath}`
  let smallFile = post?.files?.find((pf) => pf?.fileType === 'small')
  let smallPath = `${CDN_ROUTE}${smallFile?.filePath}`
  let _path = microFile?.id ? microPath : smallPath;
  let path = errorPath || _path;

  const handleClick = () => {
    navigate(`/post/${post?.id}`)
  };

  const createPostError = () => {
    setErrorPath("/assets/imgs/errorPhoto.png");
  };

  // useEffect(() => {
  //   if (postModal) {
  //     setPostModal(false);
  //   }
  //   //eslint-disable-next-line
  // }, [pathname]);

  return (
    <>
      <div style={{ width: third, height: third, position: 'relative' }} onClick={handleClick}>
        <img
          alt=""
          src={path}
          onError={createPostError}
          style={{ width: third, height: third, objectFit: "cover" }}
        />
        <div style={{ width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, border: '1.5px solid #1f1e20' }} />
      </div>
    </>
  );
};

// {likeCount > 0 && (
//   <div style={{ position: 'absolute', left: 5, bottom: 2, textShadow: '1px 1px 2px rgba(0,0,0,.35)', fontWeight: 800, fontSize: 14, color: 'white' }} className='js-ac'>
//     <IoIosHeart style={{ marginRight: 2 }} />
//     {likeCount}
//   </div>
// )}

// const PostModal = ({ open, post, setOpen }) => {
//   const history = useHistory();
//   const { handleAction } = useHomeActions();
//   const [productsModal, setProductsModal] = useState(false);
//   const [initialTouch, setInitialTouch] = useState("");
//   const [touchTime, setTouchTime] = useState("");
//   const [dragOffset, setDragOffset] = useState(0);
//   const dragging = typeof initialTouch !== "string";
//   const { pathname } = useLocation();
//   // const open = postModal?.id;
//
//   useEffect(() => {
//     setOpen(false);
//     //eslint-disable-next-line
//   }, [pathname]);
//
//   const handleClose = () => {
//     setOpen(false);
//     setProductsModal(false);
//   };
//
//   const handleUserSelect = (p) => {
//     handleClose();
//     history.push(`/profile/${p?.id}`);
//   };
//
//   const handleProductsModal = () => {
//     setProductsModal(true);
//   };
//
//   const touchStart = (e) => {
//     e.stopPropagation();
//     let touchY = e.touches?.[0].screenY;
//     let _touchTime = e.timeStamp;
//     setTouchTime(_touchTime);
//     setInitialTouch(touchY);
//   };
//
//   const touchMove = (e) => {
//     e.stopPropagation();
//     let touchY = e.touches?.[0].screenY;
//     let _diff = touchY - initialTouch;
//     let diff = Math.max(0, _diff);
//     setDragOffset(diff);
//   };
//
//   const touchEnd = (e) => {
//     let resetDrag = () => {
//       setInitialTouch("");
//       setDragOffset(0);
//     };
//
//     let _touchTime = e.timeStamp;
//     if (_touchTime - touchTime < 250) {
//       if (dragOffset > 80) {
//         handleClose();
//         resetDrag();
//       } else {
//         resetDrag();
//       }
//     }
//     if (dragOffset > 160) {
//       handleClose();
//       resetDrag();
//     } else {
//       resetDrag();
//     }
//   };
//
//   const scale = dragging ? 100 - dragOffset / 28 : 100;
//   const borderRadius = dragging ? Math.min(5, 100 - scale) : 0;
//
//   return (
//     <>
//       <div
//         style={{
//           height: window.innerHeight - 90,
//           width: window.innerWidth,
//           overflow: "hidden",
//           position: "fixed",
//           transform: open
//             ? dragging
//               ? `translate(0%, ${dragOffset}px) scaleX(${scale / 100})`
//               : "translate(0%, 0%)"
//             : "translate(0%, 120%) scaleX(0.75)",
//           transition: dragging ? "all 25ms linear" : open ? "all 250ms ease-out 150ms" : 'all 200ms ease-out',
//           opacity: dragging ? scale / 100 : 1,
//           left: 0,
//           top: 0,
//           zIndex: open ? 100000000 : -10,
//           background: "#19181a",
//           borderRadius: borderRadius,
//         }}
//         onTouchEnd={(e) => touchEnd(e)}
//         onTouchMove={(e) => touchMove(e)}
//         onTouchStart={(e) => touchStart(e)}
//       >
//         {open && (
//           <>
//             {post?.files?.length > 0 && (
//               <Viewer
//                 isInView={open}
//                 post={{ ...post }}
//                 contentHeight={window.innerHeight - 90}
//               />
//             )}
//             <HomeActions
//               post={post}
//               modal={true}
//               showText={true}
//               handleAction={handleAction}
//               handleUserSelect={handleUserSelect}
//               handleProductsModal={handleProductsModal}
//             />
//             <ProductModal
//               post={post}
//               productsModal={productsModal}
//               setProductsModal={setProductsModal}
//             />
//           </>
//         )}
//       </div>
//     </>
//   );
// };
