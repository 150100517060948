import { useApp } from "context";
import outsyde from "@outsyde/sdk";
import { handleDate } from "utils";
import Viewer from "components/Viewer";
import { useState, useEffect } from "react";
import Pill, { Pill2 } from "components/Pill";
import ShareModal from 'components/ShareModal';
import PostActions from "components/PostActions";
import UserSection from "components/UserSection";
import { GetSection } from "components/BlogSections";
import {
  IoVolumeHighOutline,
  IoVolumeMuteOutline,
  IoEllipsisVerticalOutline,
  IoBagOutline,
  IoShareSocialOutline,
  IoBookmarkOutline,
  IoBookmark,
  IoArrowUp
} from "react-icons/io5";

const SmallPostContent = ({
  post,
  getY,
  index,
  inView,
  postWidth,
  contentHeight,
  dragging = false,
  getIsInView = () => {},
  mouseEnd = () => {},
  mouseMove = () => {},
  mouseStart = () => {},
  touchEnd = () => {},
  touchMove = () => {},
  touchStart = () => {}
}) => {
  const { acts } = useApp();
  const canShow = post?.files?.length > 0;
  const noDisplay = index < inView - 2 || index > inView + 3;

  if (noDisplay) {
    return null;
  } else {
    return (
      <div
        className="js-as"
        onMouseUp={(e) => mouseEnd(e)}
        onMouseMove={(e) => mouseMove(e)}
        onMouseDown={(e) => mouseStart(e)}
        onTouchEnd={(e) => touchEnd(e)}
        onTouchMove={(e) => touchMove(e)}
        onTouchStart={(e) => touchStart(e)}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          display: "flex",
          overflow: "hidden",
          position: "absolute",
          height: contentHeight,
          transform: `translate(0px, ${getY(index)}px)`,
          transition: dragging
            ? "transform 22ms linear"
            : "transform 220ms ease-out",
        }}
      >
        <div
          style={{
            width: "100%",
            height: canShow ? contentHeight : 0,
            overflow: "hidden",
            opacity: canShow ? 1 : 0,
            transition: "all 80ms linear",
            display: "flex",
            background: "#1f1e20",
            textAlign: "left",
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              width: postWidth,
              position: "relative",
              height: contentHeight,
              maxWidth: '100%'
            }}
          >
            {canShow && <Viewer post={post} isInView={getIsInView(index)} />}
            <div style={{ position: 'absolute', left: 10, bottom: 10, zIndex: 1000, width: "calc(100% - 20px)" }} className='jb-ae'>
              <div style={{ width: postWidth - 62 }}>
                <UserSection
                  user={post?.user}
                  date={handleDate(post.createdAt)}
                  className="mb-0"
                />
                <div style={{ fontSize: 12 }}>{post?.location?.label}</div>
                <div className="mt-1" style={{ fontWeight: 700 }}>
                  {post?.name}
                </div>
              </div>
              <div style={{ width: 32 }}>
                <SmallActions post={post} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SmallPostContent;

const SmallActions = ({ post }) => {
  const { muted, handleMute } = useApp();
  const [shareModal, setShareModal] = useState(false);
  let mainFile = post?.files?.find((pf) => pf?.fileType === 'main');
  let isVideo = mainFile?.contentType?.includes("video")
  let productsArr = post?.products;

  const [moreModal, setMoreModal] = useState(false);
  const [porductsModal, setProductsModal] = useState(false);

  const likeId = post?.likes?.length > 0 ? post?.likes[0]?.id : "";
  const [isLiked, setIsLiked] = useState(likeId ? true : false);

  const toggleAction = () => {
    // handleAction(post, handleLike);
  };

  useEffect(() => {
    let status = likeId ? true : false;
    setIsLiked(status);
  }, [likeId]);

  const handleLike = async () => {
    setIsLiked(!isLiked);
    try {
      await outsyde.posts.handleLike(post?.id);
      // handleLikedPosts();
    } catch (err) {
      setIsLiked(!isLiked);
    }
  };

  return(
    <>
      <div className="js-ac flex-col">
        <div
          className="post-type-icon"
          onClick={toggleAction}
          style={{
            marginBottom: 12,
            position: "relative",
            filter: "drop-shadow(1px 1px 2px rgba(0,0,0,.6))",
          }}
        >
          <IoEllipsisVerticalOutline />
        </div>

        <div
          className="post-type-icon"
          onClick={() => setShareModal(true)}
          style={{
            marginBottom: 12,
            position: "relative",
            filter: "drop-shadow(1px 1px 2px rgba(0,0,0,.6))",
          }}
        >
          <IoShareSocialOutline />
        </div>

        <div
          className="post-type-icon"
          style={{
            marginBottom: 12,
            position: "relative",
            display: productsArr?.length > 0 ? "" : "none",
            filter: "drop-shadow(1px 1px 2px rgba(0,0,0,.6))",
          }}
          onClick={() => setProductsModal(true)}
        >
          <IoBagOutline />
          <div
            style={{
              position: "absolute",
              right: -10,
              top: -10,
              width: 20,
              height: 20,
              borderRadius: "50%",
              background: "#5c7d6d",
              fontSize: 12,
            }}
            className="como-bold jc-ac"
          >
            {productsArr?.length}
          </div>
        </div>

        {isVideo && (
          <MuteBtn muted={muted} handleMute={handleMute} />
        )}

        <div
          className="post-type-icon"
          style={{
            marginBottom: 0,
            marginBottom: 12,
            position: "relative",
            filter: "drop-shadow(1px 1px 2px rgba(0,0,0,.6))",
          }}
          onClick={() => setMoreModal(true)}
        >
          <IoArrowUp />
        </div>

        <div
          className="post-type-icon"
          style={{
            marginBottom: 0,
            position: "relative",
            filter: "drop-shadow(1px 1px 2px rgba(0,0,0,.6))",
          }}
          onClick={handleLike}
        >
          {isLiked ? <IoBookmark /> : <IoBookmarkOutline />}
        </div>
      </div>
      <ShareModal post={post} open={shareModal} handleClose={() => setShareModal(false)} />
    </>
  )
}

//<MoreModal post={post} open={moreModal} setOpen={setMoreModal} />

const MuteBtn = ({ muted, handleMute }) => {
  return (
    <div
      className="post-action-btn"
      onClick={(e) => handleMute(e)}
      style={{
        marginBottom: 12,
        transition: "all 120ms ease-out",
      }}
    >
      {muted ? <IoVolumeMuteOutline /> : <IoVolumeHighOutline />}
    </div>
  );
};
