import { useApp } from 'context';
import { useNavigate } from "react-router";
import { useState, useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';

const SmallMenu = ({ btnRef, open, handleClose }) => {
  const { user } = useApp();
  const nodeRef = useRef(null);
  const nodeRef2 = useRef(null);
  const navigate = useNavigate();

  const handleNav = (to) => {
    handleClose()
    navigate(to)
  }

  return(
    <>
      <Transition nodeRef={nodeRef2} in={open} timeout={300}>
        {state => (
          <div ref={nodeRef2} onClick={handleClose} style={{ ...defaultStyle2, ...transitionStyles2[state] }}/>
        )}
      </Transition>
      <Transition nodeRef={nodeRef} in={open} timeout={300}>
        {state => (
          <div ref={nodeRef} className='outsyde-inner-content shadow' style={{ ...defaultStyle, ...transitionStyles[state] }}>
            <div style={{ height: 113, background: '#19181a', borderBottom: '1px solid rgba(255,255,255,.12' }} className='js-ac ps-4 pe-4'>
              <img src="/assets/imgs/white-logo.png" style={{ width: 28 }} className="mb-1 me-2" alt="outsyde-logo" />
              <div style={{ cursor: 'pointer', fontSize: 24, fontWeight: 700, lineHeight: 1 }} onClick={() => navigate('/')}>OUTSYDE</div>
            </div>
            <div style={{ lineHeight: 1, fontWeight: 600 }} className='p-4'>
              <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav(`/feed`)} className='js-ac'>Feed</div>
              <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav(`/read`)} className='js-ac'>Read</div>

              <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav('/explore')} className='js-ac'>Explore</div>
              <div style={{ height: 50, cursor: 'pointer' }} onClick={() => handleNav('/listen')} className='js-ac'>Listen</div>
            </div>
          </div>
        )}
      </Transition>
    </>
  )
}

export default SmallMenu;

// <div style={{ height: 40, cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>Gear Guides</div>
// <div style={{ height: 40, cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>How To</div>
// <div style={{ height: 40, cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>Product Reviews</div>
// <div style={{ height: 40, cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>Places to Stay</div>
// <div style={{ height: 40, cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>For Beginners</div>
// <div style={{ height: 40, cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>Events</div>
// <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer', paddingLeft: 12 }} onClick={() => handleNav(`/read`)} className='js-ac'>Trail Guides</div>

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  transform: "translate(-110%, 0%)",
  width: 400, position: 'fixed', left: 0, top: 0, height: '100vh', zIndex: 100000, maxWidth: "80%"
}

const transitionStyles = {
  entering: { transform: "translate(0%, 0%)" },
  entered:  { transform: "translate(0%, 0%)" },
  exiting:  { transform: "translate(-110%, 0%)" },
  exited:  { transform: "translate(-110%, 0%)" },
};

const defaultStyle2 = {
  transition: `all 300ms ease-in-out`,
  opacity: 0,
  zIndex: -10,
  width: '100%', height: '100%', position: 'fixed', left: 0, top: 0, zIndex: 100000, background: 'rgba(0,0,0,.75)'
}

const transitionStyles2 = {
  entering: { opacity: 1, zIndex: 100000, },
  entered:  { opacity: 1, zIndex: 100000, },
  exiting:  { opacity: 0, zIndex: -10, },
  exited:  { opacity: 0, zIndex: -10, },
};
