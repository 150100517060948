import { useMeasure } from 'react-use';
import { Analytics } from 'aws-amplify';

export const PostProductCardNew = ({ product, post, style = {}, className }) => {
  const handeClick = () => {
    Analytics.record({
      name: 'productClick',
      attributes: { merchant: product?.merchant?.name, product: product?.name }
    });

    if (post?.user?.id) {
      let formattedUserId = post?.user?.id?.replaceAll("-", "");
      window.open(`${product.optimizedUrl}&key=1d01e155521f3eb3020da13432e4916f&cuid=${formattedUserId}`)
    } else {
      window.open(product.optimizedUrl)
    }
  }

  return(
    <div onClick={handeClick} className={`col-12 col-xl-6 mt-3 ${className}`}>
      <div style={{ width: '100%', background: "#19181a", ...style }} className='shadow-sm js-ac'>
        <img alt='' src={product?.imageUrl} style={{ width: 100, height: 70, objectFit: 'cover', flexShrink: 0 }} />
        <div className='pt-2 pb-2 ps-3 pe-3 flex-col jb-as' style={{ height: 70, width: '100%' }}>
          <div className='truncate' style={{ fontWeight: 600, lineHeight: 1 }}>{product?.name}</div>
          <div className='jb-ac' style={{ width: '100%' }}>
            <div style={{ cursor: 'pointer', lineHeight: 1, color: '#7d5a71', fontSize: 12, fontWeight: 700, textTransform: 'uppercase', textDecoration: 'none' }}>{product?.merchant?.name}</div>
            <div style={{ color: '#4f7361', fontSize: 12, fontWeight: 700 }}>{product?.price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PostProductCard = ({ product, post }) => {
  const [ref, { width }] = useMeasure();
  let imgSize = width * .2

  const handeClick = () => {
    Analytics.record({
      name: 'productClick',
      attributes: { merchant: product?.merchantName, product: product?.name }
    });

    if (post?.user?.id) {
      let formattedUserId = post?.user?.id?.replaceAll("-", "");
      window.open(`${product.optimizedUrl}&key=1d01e155521f3eb3020da13432e4916f&cuid=${formattedUserId}`)
    } else {
      window.open(product.optimizedUrl)
    }
  }

  return(
    <div onClick={handeClick} ref={ref} className='outsyde-inner-content shadow-sm mt-3 js-ac' style={{ overflow: 'hidden', width: '100%', cursor: 'pointer', background: '#19181a', border: '1px solid rgba(255,255,255,.25)' }}>
      <img alt='' src={product?.imageUrl} style={{ width: imgSize, height: imgSize, flexShrink: 0 }} />
      <div style={{ height: imgSize, flexDirection: 'column', width: '100%' }} className='jb-as p-2'>
        <div className='truncate1' style={{ fontWeight: 600, lineHeight: 1.2 }}>{product?.name}</div>
        <div className='jb-ac' style={{ width: '100%' }}>
          <div>
            <div style={{ fontSize: 12, lineHeight: 1 }}>Shop Now</div>
            <div style={{ fontSize: 14, fontWeight: 800, color: '#4f7361', lineHeight: 1 }}>{product?.merchantName}</div>
          </div>
          <div className='je-ac'>
            <div className='btn1' style={{ paddingLeft: 10, paddingRight: 10, height: 24, fontSize: 14 }}>{product?.price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProductCard1 = ({ product, post }) => {
  const [ref, { width }] = useMeasure();
  let imgSize = width * .2

  const handeClick = () => {
    Analytics.record({
      name: 'productClick',
      attributes: { merchant: product?.merchantName, product: product?.name }
    });

    if (post?.user?.id) {
      let formattedUserId = post?.user?.id?.replaceAll("-", "");
      window.open(`${product.optimizedUrl}&key=1d01e155521f3eb3020da13432e4916f&cuid=${formattedUserId}`)
    } else {
      window.open(product.optimizedUrl)
    }
  }

  return(
    <div onClick={handeClick} ref={ref} className='outsyde-inner-content shadow-sm p-2 js-ac' style={{ overflow: 'hidden', width: '100%', minHeight: 100, border: '1px solid rgba(255,255,255,.25)', cursor: 'pointer' }}>
      <img alt='' src={product?.imageUrl} style={{ width: imgSize, height: imgSize, marginRight: 10, flexShrink: 0 }} />
      <div style={{ height: imgSize, flexDirection: 'column', width: '100%' }} className='jb-as'>
        <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.2 }}>{product?.name}</div>
        <div className='jb-ac' style={{ width: '100%' }}>
          <div>
            <div style={{ fontSize: 12, lineHeight: 1 }}>Shop Now</div>
            <div style={{ fontSize: 14, fontWeight: 800, color: '#4f7361', lineHeight: 1 }}>{product?.merchantName}</div>
          </div>
          <div className='je-ac'>
            <div className='btn1' style={{ paddingLeft: 16, paddingRight: 16, height: 34 }}>{product?.price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProductCard2 = ({ product, post }) => {
  const [ref, { width }] = useMeasure();
  let imgSize = width * .2

  const handeClick = () => {
    Analytics.record({
      name: 'productClick',
      attributes: { merchant: product?.merchantName, product: product?.name }
    });

    if (post?.user?.id) {
      let formattedUserId = post?.user?.id?.replaceAll("-", "");
      window.open(`${product.optimizedUrl}&key=1d01e155521f3eb3020da13432e4916f&cuid=${formattedUserId}`)
    } else {
      window.open(product.optimizedUrl)
    }
  }

  return(
    <div className='col-12 col-md-6'>
      <div onClick={handeClick} ref={ref} className='outsyde-inner-content shadow-sm p-2 js-ac' style={{ overflow: 'hidden', width: '100%', minHeight: 100, border: '1px solid rgba(255,255,255,.25)', cursor: 'pointer' }}>
        <img alt='' src={product?.imageUrl} style={{ width: imgSize, height: imgSize, marginRight: 10, flexShrink: 0 }} />
        <div style={{ height: imgSize, flexDirection: 'column', width: '100%' }} className='jb-as'>
          <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.2 }}>{product?.name}</div>
          <div className='jb-ac' style={{ width: '100%' }}>
            <div>
              <div style={{ fontSize: 12, lineHeight: 1 }}>Shop Now</div>
              <div style={{ fontSize: 14, fontWeight: 800, color: '#4f7361', lineHeight: 1 }}>{product?.merchantName}</div>
            </div>
            <div className='je-ac'>
              <div className='btn1' style={{ paddingLeft: 16, paddingRight: 16, height: 34 }}>{product?.price}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ProductCard3 = ({ product }) => {
  const [ref, { width }] = useMeasure();
  let imgSize = width * .2

  return(
    <div ref={ref} className='shadow-sm js-ac mt-2' style={{ overflow: 'hidden', width: '100%', minHeight: 100, cursor: 'pointer' }}>
      <img alt='' src={product?.imageUrl} style={{ width: imgSize, height: imgSize, marginRight: 10, flexShrink: 0 }} />
      <div style={{ height: imgSize, flexDirection: 'column', width: '100%' }} className='jb-as'>
        <div className='truncate' style={{ fontWeight: 700, lineHeight: 1.2 }}>{product?.name}</div>
        <div className='jb-ac' style={{ width: '100%' }}>
          <div>
            <div style={{ fontSize: 12, lineHeight: 1 }}>Shop Now</div>
            <div style={{ fontSize: 14, fontWeight: 800, color: '#4f7361', lineHeight: 1 }}>{product?.merchantName}</div>
          </div>
          <div className='je-ac'>
            <div className='btn1' style={{ paddingLeft: 16, paddingRight: 16, height: 34 }}>{product?.price}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PostProduct = ({ product, post }) => {
  const openCapacitorSite = async () => {
    // await Browser.open({ url: product?.requestUrl });
  };

  return (
    <div
      className="js-as como-extra-bold"
      style={{
        marginBottom: 12,
        color: "white",
        background: "rgba(54, 54, 54, 0.32)",
        padding: 10,
        borderRadius: 8,
        cursor: 'pointer'
      }}
      onClick={openCapacitorSite}
    >
      <img
        alt=""
        src={product?.imageUrl || product?.img}
        style={{
          width: 50,
          height: 50,
          objectFit: "cover",
          flexShrink: 0,
          borderRadius: 3,
        }}
      />
      <div
        style={{ marginLeft: 12, height: 50, width: "calc(100% - 76px)" }}
        className="js-as flex-col"
      >
        <div className='no-text-overflow' style={{ width: '100%' }}>{product?.name || product?.name}</div>
        <div className="jb-ac" style={{ width: "100%" }}>
          <div
            className="js-ac como-bold"
            style={{ fontSize: 14, marginTop: 3, color: "rgba(190,190,190,1)" }}
          >
            {product?.price}
          </div>
        </div>
      </div>
    </div>
  );
};
