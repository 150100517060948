import { useApp } from 'context';
import outsyde from "@outsyde/sdk";
import Input from 'components/Input';
import TextArea from 'components/TextArea';
import { useState, useEffect } from 'react';
import CenterModal from 'components/CenterModal';

const ProApplicationModal = ({ open, handleClose }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (open) {
      setPage(1)
    }
  }, [open])

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ width: 600, maxWidth: "100%", color: 'white' }} className='p-3'>
        <div className='mb-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>Become a Pro</div>
        {page === 1 && <S1 setPage={setPage} handleClose={handleClose} />}
        {page === 2 && <S2 open={open} setPage={setPage} handleClose={handleClose} />}
        {page === 3 && <S3 handleClose={handleClose} />}
      </div>
    </CenterModal>
  )
}

export default ProApplicationModal;

const S1 = ({ setPage, handleClose }) => {
  return(
    <>
      <div style={{ fontWeight: 600, fontSize: 18 }}>Monetize Your Creativity & Influence Outdoor Adventures</div>
      <div className='mt-2'>Ready to unleash your creativity and become an influential force in the great Outdoors? Empower your passion as a creator, share and monetize captivating content. Help shape the future of outdoor exploration and become a Creator on Outsdye!</div>
      <div className='jb-ac mt-3'>
        <div style={{ width: '48%' }} onClick={handleClose} className="btn2">No Thanks</div>
        <div style={{ width: '48%' }} onClick={() => setPage(2)} className="btn1">Let's Go!</div>
      </div>
    </>
  )
}

const S2 = ({ open, setPage, handleClose }) => {
  const { user, setError, setLoading, handleUser } = useApp();
  const [bio, setBio] = useState("");
  const [tiktok, setTiktok] = useState("");
  const [instagram, setInstagram] = useState("");

  useEffect(() => {
    if (open) {
      setBio(user?.bio || "")
      setTiktok(user?.tiktok || "")
      setInstagram(user?.instagram || "")
    }
  }, [open])

  const handleDone = async () => {
    if (!bio) {
      setError("Please add a bio!")
    } else if (!tiktok && !instagram) {
      setError("Please add either an Instagram or Tiktok account!")
    } else {
      try {
        setLoading(true);
        await outsyde.users.update(user?.id, { bio, tiktok, instagram, isPro: 'pending' });
        await outsyde.emails.sendCreatorWelcome();
        handleUser()
        setLoading(false);
        setPage(3);
      } catch(err) {
        setLoading(false);
        setError(err?.message)
      }
    }
  }

  return(
    <div className='row'>
      <div className='col-12 col-lg-6'>
        <Input value={instagram} onChange={setInstagram} label="Instagram" className='mb-2' />
      </div>
      <div className='col-12 col-lg-6'>
        <Input value={tiktok} onChange={setTiktok} label="Tiktok" className='mb-2' />
      </div>
      <div className='col-12'>
        <TextArea value={bio} onChange={setBio} label="Bio" className='mb-3' style={{ height: 100 }} />
      </div>
      <div className='col-12'>
        <div onClick={handleDone} className="btn1">Done</div>
      </div>
    </div>
  )
}

const S3 = ({ handleClose }) => {
  return(
    <>
      <div style={{ fontWeight: 600, fontSize: 18 }}>Thank you for applying to become a creator on Outsyde!</div>
      <div className='mt-2'>We appreciate your interest in joining our community of outdoor enthusiasts and content creators. Your application has been received, and we are excited to review it. We will carefully assess your submission and get back to you within the next few days.</div>
      <div className='mt-2'>We look forward to potentially welcoming you aboard as a valued member of our Outsyde Creator community!</div>
      <div onClick={handleClose} className="btn2 mt-3">Close</div>
    </>
  )
}
