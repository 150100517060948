import { BlogCard6, FeaturedBlogCard } from 'components/BlogCards';
import { useSearchParams } from 'react-router-dom';
// import Filter from 'components/LearnFilterUI';
import Header from 'components/Header';
import Footer from 'components/Footer';
import outsyde from "@outsyde/sdk";
import { useEffect } from 'react';
// import { useApp } from "context";
import { useState } from 'react';

const BlogLib = () => {
  const [searchParams] = useSearchParams();
  const [blogs, setBlogs] = useState([]);
  const [blogsPulling, setBlogsPulling] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const toShow = blogs?.filter((b, idx) => idx !== 0);
  const type = searchParams.get("type");
  const activities = searchParams.get("activities");

  const handleBlogs = async () => {
    try {
      // setBlogsPulling(true);
      let data = await outsyde.posts.getAll({
        limit: 31,
        order: '-createdAt',
        ...createQuery(activities, type)
      });
      setBlogs(data);
      // setBlogsPulling(false)
    } catch(err) {
      // setBlogsPulling(false)
    }
  }

  const pageinateBlogs = async () => {
    if (!blogsPulling) {
      try {
        setBlogsPulling(true);
        let data = await outsyde.posts.getAll({
          limit: 31,
          order: '-createdAt',
          offset: blogs?.length,
          ...createQuery(type)
        });
        if (data?.length === 0) {
          setNoMore(true)
        }
        setBlogs([...blogs, ...data])
        setBlogsPulling(false)
      } catch(err) {
        setBlogsPulling(false)
      }
    }
  }

  useEffect(() => {
    handleBlogs();
  //eslint-disable-next-line
  }, [activities, type])

  if (window.document) {
    window.document.body.style.overflowY = 'auto';
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = (e) => {
    let target = e?.target;
    let { scrollHeight, scrollTop } = target;
    let blogHeight = scrollHeight - 500;
    let percent = scrollTop / blogHeight;

    if (percent > 0.75 && !noMore) {
      pageinateBlogs();
    }
  }

  return(
    <div className="App" style={{ height: '100vh', overflowY: 'auto' }} onScroll={handleScroll}>
      <Header />
      <FeaturedBlogCard post={blogs[0]} />
      <div className='container mt-4 pb-5'>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              {toShow?.map((blog, key) => <BlogCard6 post={blog} key={key} />)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default BlogLib;

const createQuery = (activities, type) => {
  let filter = {};

  if (type?.length > 0) {
    filter = { postTypeId: type, activityId: { _not: null } }
  }

  if (activities?.length > 0) {
    filter = { ...filter, activityId: activities }
  }

  if (type?.length > 0 || activities?.length > 0) {
    return { filter: filter }
  } else {
    return {}
  }
}
