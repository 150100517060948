import DynamicPage from 'components/DynamicPage';

const Discord = () => {
  return(
    <DynamicPage url="/outsyde-discord" overrides={{ firstChildren: <DiscordEmbed /> }} />
  )
}

export default Discord;

const DiscordEmbed = () => {
  return(
    <iframe title="Outsyde-Discord" src="https://discord.com/widget?id=1112788588082503720&theme=dark" style={{ height: 600 }} width="350" height="600" allowtransparency="true" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
  )
}
