// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface PostTypesInterface {
  getAll: (query?: Query) => Promise<any>;
}

export const postTypes = (client: Axios): PostTypesInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery(`/post-types`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  }
});
