import Nav from "./Nav";
import { useApp } from "context";
import outsyde from "@outsyde/sdk";
import PostContent from "./Content";
import Header from "components/Header";
import { useState, useEffect } from "react";
import { getUniqueArrayValues } from "utils";
import SmallPostContent from './SmallContent';

const Feed = () => {
  const width = window.innerWidth;
  const isSmall = width < 992;

  if (window.document) {
    window.document.body.style.overflowY = "hidden";
  }

  const { user, posts, setPosts, inView, setInview, contentHeight, filters } =
    useApp();
  const [postsPulling, setPostsPulling] = useState(false);
  const postLength = posts?.length;
  const activities = filters.activities;

  const handlePosts = async () => {
    if (!postsPulling) {
      let newPosts = [];
      setPostsPulling(true);
      newPosts = await outsyde.posts.getAll({
        limit: 10,
        order: "-createdAt",
        offset: posts?.length
      });

      // if (newPosts?.length < 3) {
      //   let unfilteredPosts = await outsyde.posts.getAll({
      //     limit: 14,
      //     order: "-createdAt",
      //     offset: posts?.length
      //   });
      //   newPosts = [...newPosts, ...unfilteredPosts]
      // }

      let filtered = newPosts?.filter((p) => p?.files?.length > 0);
      let unique = getUniqueArrayValues([...posts, ...filtered], "id");
      setPosts(unique);
      setPostsPulling(false);
    }
  };

  useEffect(() => {
    if (activities || !user?.id) {
      handlePosts();
    }
    //eslint-disable-next-line
  }, [activities]);

  const blockScroll = false;
  const [initialTouch, setInitialTouch] = useState("");
  const [touchTime, setTouchTime] = useState("");
  const [dragOffset, setDragOffset] = useState(0);
  const dragging = typeof initialTouch !== "string";

  const _postWidth = (contentHeight / 16) * 9;
  const screenWidth = window.innerWidth;
  const postWidth = Math.min(_postWidth, screenWidth)
  const scrollerLeft = screenWidth / 2 + postWidth;

  const mouseStart = (e) => {
    if (!blockScroll) {
      let touchY = e.clientY;
      let _touchTime = e.timeStamp;
      setTouchTime(_touchTime);
      setInitialTouch(touchY);
    }
  };

  const mouseMove = (e) => {
    if (!blockScroll && dragging) {
      e.stopPropagation();
      let touchY = e.clientY;
      let diff = touchY - initialTouch;
      setDragOffset(diff);
    }
  };

  const mouseEnd = (e) => {
    if (!blockScroll) {
      if (inView === 0 && dragOffset > 100) {
        handlePosts();
      }

      let resetDrag = () => {
        setInitialTouch("");
        setDragOffset(0);
      };

      let _touchTime = e.timeStamp;
      if (_touchTime - touchTime < 250) {
        if (dragOffset < -80 && inView !== postLength - 1) {
          setInview(inView + 1);
          if (inView > postLength - 5) {
            handlePosts(true);
          }
          resetDrag();
        } else if (dragOffset > 80 && inView !== 0) {
          setInview(inView - 1);
          resetDrag();
        } else {
          resetDrag();
        }
      } else if (dragOffset < -100 && inView !== postLength - 1) {
        setInview(inView + 1);
        if (inView > postLength - 5) {
          handlePosts(true);
        }
        resetDrag();
      } else if (dragOffset > 100 && inView !== 0) {
        setInview(inView - 1);
        resetDrag();
      } else {
        resetDrag();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mouseup", mouseEnd);

    return () => {
      window.removeEventListener("mouseup", mouseEnd);
    };
  }, []);

  const touchStart = (e) => {
    if (!blockScroll) {
      e.stopPropagation();
      let touchY = e.touches?.[0].screenY;
      let _touchTime = e.timeStamp;
      setTouchTime(_touchTime);
      setInitialTouch(touchY);
    }
  };

  const touchMove = (e) => {
    if (!blockScroll) {
      e.stopPropagation();
      let touchY = e.touches?.[0].screenY;
      let diff = touchY - initialTouch;
      setDragOffset(diff);
    }
  };

  const touchEnd = (e) => {
    if (!blockScroll) {
      if (inView === 0 && dragOffset > 100) {
        handlePosts();
      }

      let resetDrag = () => {
        setInitialTouch("");
        setDragOffset(0);
      };

      let _touchTime = e.timeStamp;
      if (_touchTime - touchTime < 250) {
        if (dragOffset < -80 && inView !== postLength - 1) {
          setInview(inView + 1);
          if (inView > postLength - 5) {
            handlePosts(true);
          }
          resetDrag();
        } else if (dragOffset > 80 && inView !== 0) {
          setInview(inView - 1);
          resetDrag();
        } else {
          resetDrag();
        }
      } else if (dragOffset < -120 && inView !== postLength - 1) {
        setInview(inView + 1);
        if (inView > postLength - 5) {
          handlePosts(true);
        }
        resetDrag();
      } else if (dragOffset > 120 && inView !== 0) {
        setInview(inView - 1);
        resetDrag();
      } else {
        resetDrag();
      }
    }
  };

  const getY = (index) => {
    if (index === inView) {
      return 0 + dragOffset;
    } else if (index === inView - 1) {
      return -contentHeight + dragOffset;
    } else if (index === inView + 1) {
      return contentHeight + dragOffset;
    } else if (index < inView) {
      return -contentHeight;
    } else {
      return contentHeight;
    }
  };

  const getIsInView = (index) => {
    if (inView === index) {
      return true;
    } else if (inView === index + 1 && dragOffset > 10) {
      return true;
    } else if (inView === index - 1 && dragOffset < -10) {
      return true;
    } else {
      return false;
    }
  };

  const handleBack = () => {
    if (inView !== 0) {
      setInview(inView - 1);
    }
  };

  const handleNext = () => {
    if (inView !== postLength - 1) {
      setInview(inView + 1);
      if (inView > postLength - 5) {
        handlePosts(true);
      }
    }
  };

  if (isSmall) {
    return(
      <div className="App" style={{ height: "100vh" }}>
        <Header />
        <div style={{ position: "relative", height: contentHeight, overflow: 'hidden' }}>
          {posts?.map((p, index) => (
            <SmallPostContent
              post={p}
              key={p.id}
              getY={getY}
              index={index}
              inView={inView}
              dragging={dragging}
              touchEnd={touchEnd}
              mouseEnd={mouseEnd}
              postWidth={postWidth}
              touchMove={touchMove}
              mouseMove={mouseMove}
              dragOffset={dragOffset}
              touchStart={touchStart}
              mouseStart={mouseStart}
              getIsInView={getIsInView}
              contentHeight={contentHeight}
            />
          ))}
        </div>
      </div>
    )
  } else {
    return (
      <div className="App" style={{ height: "100vh" }}>
        <Header />
        <div style={{ position: "relative", height: contentHeight, overflow: 'hidden' }}>
          {posts?.map((p, index) => (
            <PostContent
              post={p}
              key={p.id}
              getY={getY}
              index={index}
              inView={inView}
              dragging={dragging}
              touchEnd={touchEnd}
              mouseEnd={mouseEnd}
              postWidth={postWidth}
              touchMove={touchMove}
              mouseMove={mouseMove}
              dragOffset={dragOffset}
              touchStart={touchStart}
              mouseStart={mouseStart}
              getIsInView={getIsInView}
              contentHeight={contentHeight}
            />
          ))}
          <Nav
            scrollerLeft={scrollerLeft}
            handleBack={handleBack}
            handleNext={handleNext}
            inView={inView}
          />
        </div>
      </div>
    );
  }
};

export default Feed;

const createQuery = (activities) => {
  if (activities?.length > 0) {
    return { filter: { activityId: activities } };
  } else {
    return {};
  }
};
