const Input = ({ value, onChange, placeholder, style = {}, className = '', type = '', label }) => {
  return(
    <div>
      {label && <div style={{ fontSize: 12, color: '#5c7d6d', fontWeight: 600 }}>{label}</div>}
      <input type={type} className={className} style={style} placeholder={placeholder} value={value || ""} onChange={(e) => onChange(e.target.value)} />
    </div>
  )
}

export default Input
