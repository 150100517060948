import { BsCheck } from 'react-icons/bs';
import CenterModal from 'components/CenterModal';
import { useApp } from 'context';

const ActivitiesModal = ({ children, open, selected = [], handleChange = () => {}, handleClose, header = '' }) => {
  const { acts } = useApp();

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ width: 400, height: 600 }}>
        <div className='pt-3 mb-3 ps-3' style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2, textTransform: 'uppercase' }}>{header}</div>
        <div style={{ height: 'calc(100% - 60px)', overflow: 'auto' }}>
          {acts?.map((act, key) => <ActivityItem key={key} act={act} selected={selected} handleChange={handleChange} />)}
        </div>
      </div>
      {children}
    </CenterModal>
  )
}

export default ActivitiesModal;

const ActivityItem = ({ act, selected, handleChange }) => {
  let selectedIds = selected?.map((s) => s?.id);
  let isSelected = selectedIds?.includes(act?.id);

  return(
    <div onClick={() => handleChange(act)} className='ps-3 js-ac' style={{ height: 48, color: 'white', cursor: 'pointer' }}>
      <div className='me-3 jc-ac' style={{ width: 30, height: 30, borderRadius: '50%', border: '1px solid white', background: isSelected ? "#4f7361" : "" }}>
        {isSelected && <BsCheck style={{ fontSize: 20, color: 'white' }} />}
      </div>
      {act?.name}
    </div>
  )
}
