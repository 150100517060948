// External Imports
import { Axios } from "axios";

export interface SearchInterface {
  search: (value: string) => Promise<any>;
}

export const search = (client: Axios): SearchInterface => ({
  search: async (value) => {
    const { data } = await client.post('/search', { query: value });
    return data;
  }
});
