import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';

const Nav = ({ scrollerLeft, handleBack, handleNext, inView }) => {
  return(
    <div style={{ position: 'absolute', right: 0, top: 0, width: 80, height: '100%', borderRight: '1px solid rgba(255,255,255,.12)' }}>
      <div onClick={handleBack} style={{ width: '100%', height: '50%', borderBottom: '1px solid rgba(255,255,255,.12)', color: inView === 0 ? "rgba(255,255,255,.12)" : "" }} className='jc-ac feed-nav-icon'>
        <IoChevronUpOutline />
      </div>
      <div onClick={handleNext} style={{ width: '100%', height: '50%' }} className='jc-ac feed-nav-icon'>
        <IoChevronDownOutline />
      </div>
    </div>
  )
}

export default Nav;
