// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface ProductsInterface {
  getAll: (query?: Query) => Promise<any>;
  getAllMerchants: (query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
}

export const products = (client: Axios): ProductsInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery(`/products`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/products", query);
    const cleanedValues = cleanValues(values);
    const { data } = await client.post(url, cleanedValues);
    return data;
  },

  getAllMerchants: async (query?: Query) => {
    const url = addUrlQuery(`/merchants`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  }
});
