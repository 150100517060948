import { BsList, BsSearch } from 'react-icons/bs';
import { useNavigate, useLocation } from "react-router";
import { format } from 'date-fns';
import { useState, useEffect, useRef } from 'react';
import { useApp } from 'context';
import InlineModal from './InlineModal';
import UserSection from 'components/UserSection';
import Input from 'components/Input';
import outsyde from "@outsyde/sdk";
import { UserIcon } from 'components/UserSection';
import { Transition } from 'react-transition-group';
import ActivitiesModal from './ActivitiesModal';
import Menu from './Menu';
import Search from './SearchBar';
import SmallMenu from 'components/SmallMenu';

const Header = ({ floating = false }) => {
  const { accountModal, setAccountModal, user } = useApp();
  const navigate = useNavigate();
  const accountBtnRef = useRef(null)
  const [ldd, setLdd] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [searchModal, setSearchModal] = useState(false);
  const [smallMenu, setSmallMenu] = useState(false);
  const [dropdownReady, setDropdownReady] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDropdownReady(true)
    }, 1000)
  }, [])

  const getBg = () => {
    if (floating) {
      if (scrollY < 50) {
        return 'transparent'
      } else {
        return 'black'
      }
    } else {
      return 'black'
    }
  }

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLearn = () => {
    navigate('/read')
    setLdd(false)
  }

  return(
    <>
      <div style={{ background: getBg(), transition: 'all 120ms ease-in' }} className='header'>
        <div className='jb-ac pt-3 pb-3'>
          <div onClick={() => setSmallMenu(true)} style={{ width: 70 }} className='js-ac ms-4'>
            <BsList className='d-flex d-lg-none' style={{ fontSize: 24 }} />
          </div>
          <h1 className='' style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>OUTSYDE</h1>
          <div style={{ width: 70 }} className='je-ac me-4'>
            <div onClick={() => setSearchModal(!searchModal)} className='jc-ac me-4' style={{ fontSize: 20 }}>
              <BsSearch />
            </div>
            <UserIcon user={user} btnRef={accountBtnRef} onClick={() => setAccountModal(true)} />
          </div>
        </div>
        <div className='jc-ac d-none d-lg-flex'>
          <a className='header-link pb-3 truncate1' style={{ paddingLeft: 24, paddingRight: 16 }} href='/feed'>WATCH</a>
          <div className='header-link pb-3 truncate1 learn-btn' style={{ paddingLeft: 30, paddingRight: 30 }}>
            <a href='/read' className='learn-btn'>READ</a>
            {dropdownReady && <LearnDropdown open={ldd} setOpen={setLdd} />}
          </div>
          <a href='/locations' className='header-link pb-3 truncate1' style={{ paddingLeft: 16, paddingRight: 24 }}>EXPLORE</a>
          <a href='/listen' className='ms-2 ps-3 me-2 pe-3 header-link pb-3 truncate1'>LISTEN</a>
        </div>
      </div>
      {!floating && <div className='header-floater' />}
      <SmallMenu open={smallMenu} handleClose={() => setSmallMenu(false)} />
      <Menu btnRef={accountBtnRef} open={accountModal} handleClose={() => setAccountModal(false)} />
      <Search open={searchModal} setOpen={setSearchModal} />
    </>
  )
}

export default Header;

const lddBtns = [
  { label: 'Gear Guides', img: '/assets/imgs/gearguides.png', href: '8d02fa91-b079-463a-8b72-6770eb8f7526' },
  { label: 'How To', img: '/assets/imgs/howto.png', href: 'a753fd1f-8843-4565-8c58-2e0b03580699' },
  { label: 'Product Reviews', img: '/assets/imgs/productreviews.png', href: '8cb27e7e-262a-4659-a3a0-3c865a08f061' },
  { label: 'Places to Stay', img: '/assets/imgs/placestostay.png', href: 'b81fb392-5031-4434-8cae-71d786584e4d' },
]

const lddBtns2 = [
  { label: 'For Beginners', img: '/assets/imgs/beginners.png', href: 'c37e2c70-2b32-4b93-a510-757c2617075b' },
  { label: 'Destinations', img: '/assets/imgs/destinations.png', href: '590c9777-948b-414f-9122-6bf59d4f77cc' },
  { label: 'Events', img: '/assets/imgs/events.png', href: 'a583ba51-f69b-4761-bb41-ac25bcd20637' },
  { label: 'Trail Guides', img: '/assets/imgs/trailguides.png', href: '3f8eef00-20b9-4b73-bf37-c3d78f5a75a6' },
]

const LearnDropdown = ({ open, setOpen }) => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const accountBtnRef2 = useRef(null);
  const { pathname } = useLocation();
  const blogsToShow = posts?.filter((b, idx) => idx < 6);
  const [accountModal, setAccountModal] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const show = open && !blocked;

  const handlePosts = async () => {
    try {
      let data = await outsyde.posts.getAll({ limit: 6 });
      setPosts(data)
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    setOpen(false);
    setBlocked(true);
    if (posts?.length === 0) {
      handlePosts()
    }
  }, [pathname])

  const handleMove = () => {
    if (blocked) {
      setBlocked(false);
      setOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener("mousemove", handleMove);
    return () => {
      window.removeEventListener("mousemove", handleMove);
    }
  });

  const handleLearnClick = () => {
    if (pathname === '/learn') {
      setOpen(false)
    } else {
      navigate('/learn')
    }
  }

  return(
    <>
      <div className="ldfh" />
      <div className="learn-drop" style={{ }}>
        <div className='row pt-1 pb-3 ps-5 pe-5'>
          <div className='col-8'>
            <div className='row mb-1'>
              {lddBtns?.map((btn, key) => (
                <div key={key} onClick={() => navigate(`/read?type=${btn?.href}`)} style={{ flexDirection: 'column' }} className='col-3 pt-3 pb-2 jc-ac grey-hover green-text-hover'>
                  <img alt='' src={btn.img} style={{ width: '100%', height: 120, border: '1px solid white' }} />
                  <div className='truncate1'>{btn?.label}</div>
                </div>
              ))}
            </div>
            <div className='row'>
              {lddBtns2?.map((btn, key) => (
                <div key={key} onClick={() => navigate(`/read?type=${btn?.href}`)} style={{ flexDirection: 'column' }} className='col-3 pt-3 pb-2 jc-ac grey-hover green-text-hover'>
                  <img alt='' src={btn.img} style={{ width: '100%', height: 120, border: '1px solid white' }} />
                  <div className='truncate1'>{btn?.label}</div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ textAlign: 'left' }} className='col-4 pt-3'>
            <div style={{ lineHeight: 1 }}>Recent Posts</div>
            {blogsToShow?.map((blog, key) => (
              <div onClick={() => navigate(`/post/${blog?.id}`)} key={key} style={{ width: '100%' }} className='mt-2 green-text-hover'>
                <div className='truncate1' style={{ lineHeight: 1.2, fontWeight: 500 }}>{blog?.name}</div>
                <div className='js-ac' style={{ fontSize: 12, fontWeight: 400 }}>
                  <div className='me-2'>{blog?.user?.username}</div>
                  <div style={{ width: 4, height: 4, borderRadius: '50%', background: 'white' }} />
                  <div className='ms-2'>{format(new Date(blog?.createdAt), 'MM/dd/yyyy')}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
