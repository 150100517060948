import { PodcastCard1 } from 'components/PodcastCards';
import { ProductCard2 } from 'components/ProductCards';
import { BlogCard2 } from 'components/BlogCards';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { useMeasure } from 'react-use';
import outsyde from "@outsyde/sdk";
import { getImgUrl } from 'utils';
import { useApp } from 'context';

const Saved = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    handleSaved()
  }, [])

  if (window.document) {
    window.document.body.style.overflowY = 'auto';
  }

  const [saved, setSaved] = useState([]);
  const { user, setLoading } = useApp();

  const handleSaved = async () => {
    try {
      setLoading(true);
      let data = await outsyde.users.getSaved();
      setSaved(data)
      setLoading(false);
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  return(
    <div className="App">
      <Header />
      <div className='container pt-4'>
        <div className='row mb-4'>
          <div className='col-12 col-md-6'>
            <div style={{ color: 'white', fontSize: 24, fontWeight: 600, textAlign: 'left', lineHeight: 1.2 }}>
              Saved by {user?.username}
            </div>
          </div>
          <div className='col-12 col-md-6'>
          </div>
        </div>
        <div className='row' style={{ minHeight: '80vh' }}>
          {saved?.map((p, key) => (
            <BlogCard2 post={p} key={key} />
          ))}
        </div>
      </div>
      <Footer className=" " />
    </div>
  )
}

export default Saved;
