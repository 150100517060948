import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router";
import { useApp } from 'context';
import UserSection from 'components/UserSection';
import outsyde from "@outsyde/sdk";
import ActivitiesModal from 'components/ActivitiesModal';
import ProApplicationModal from 'components/ProApplicationModal';

const UserModalLoggedIn = ({ open, user, handleClose }) => {
  const navigate = useNavigate();
  const { handleLogout, handleUser } = useApp();
  const isCreator = user?.isPro === 'true';
  const notCreator = user?.isPro === 'false';
  const isCreatorPending = user?.isPro === 'pending';
  const [actsModal, setActsModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const [userFavorites, setUserFavorites] = useState([]);
  const [favChanges, setFavChanges] = useState(false);

  useEffect(() => {
    if (open) {
      setUserFavorites(user?.activities || []);
    }

  }, [open])

  const handleFavorites = (changes) => {
    setFavChanges(true)
    let selectedIds = userFavorites?.map((s) => s?.id);

    if (selectedIds?.includes(changes?.id)) {
      let remaining = userFavorites?.filter((f) => f?.id !== changes?.id)
      setUserFavorites(remaining)
    } else {
      setUserFavorites([...userFavorites, changes])
    }
  }

  const logout = async () => {
    await handleClose()
    setTimeout(() => handleLogout(), 400)
  }

  const handleNav = (to) => {
    handleClose()
    navigate(to)
  }

  const handleFavsClose = async () => {
    setActsModal();

    if (favChanges) {
      try {
        let formatted = userFavorites?.map((f) => f?.id);
        await outsyde.users.setFavorites(user?.id, formatted);
        handleUser()
      } catch(err) {
        console.log(err);
      }
    }
  }

  return(
    <>
      <div style={{ height: 113, background: '#19181a', borderBottom: '1px solid rgba(255,255,255,.12' }} className='js-ac ps-4 pe-4'>
        <UserSection className=' ' user={user} />
      </div>
      <div style={{ lineHeight: 1, fontWeight: 600 }} className='p-4'>
        {isCreator && <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav(`/profile/${user?.id}`)} className='js-ac'>Profile</div>}
        <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav(`/saved`)} className='js-ac'>Saved</div>
        <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => setActsModal(true)} className='js-ac'>Favorite Activites</div>
        {isCreator && <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav('/add-post')} className='js-ac'>Add Post</div>}
        {isCreator && <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} onClick={() => handleNav('/wallet')} className='js-ac'>Wallet</div>}
        {notCreator && <div onClick={() => setApplyModal(true)} style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} className='js-ac'>Become a Pro</div>}
        {isCreatorPending && <div style={{ height: 50, borderBottom: '1px solid rgba(255,255,255,.12', cursor: 'pointer' }} className='jb-ac'>Become a Pro<div style={{ fontSize:12, fontWeight:600, color: '#5c7d6d' }}>PENDING!</div></div>}
        <div onClick={logout} style={{ height: 50, color: 'red', cursor: 'pointer' }} className='js-ac'>Logout</div>
      </div>
      <ActivitiesModal open={actsModal} handleClose={handleFavsClose} header='Favorite Activities' selected={userFavorites} handleChange={handleFavorites} />
      <ProApplicationModal open={applyModal} handleClose={() => setApplyModal(false)} />
    </>
  )
}

export default UserModalLoggedIn;
