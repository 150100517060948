// External Imports
import { Axios } from "axios";

// Internal Imports
import { addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface GiveawaysInterface {
  getAll: (query?: Query) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  // create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  // update: (
  //   id: string,
  //   values: { [key: string]: any },
  //   query?: Query
  // ) => Promise<any>;
  // delete: (id: string, query?: Query) => Promise<any>
}

export const giveaways = (client: Axios): GiveawaysInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery(`/giveaways`, query);
    const { data } = await client.get(url);
    return data;
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/giveaways/${id}`, query);
    const { data } = await client.get(url);
    return data;
  },

  // create: async (values: { [key: string]: any }, query?: Query) => {
  //   const url = addUrlQuery("/giveaways", query);
  //   const cleanedValues = cleanValues(values);
  //   const { data } = await client.post(url, cleanedValues);
  //   return data;
  // },
  //
  // update: async (id: string, values: { [key: string]: any }, query?: Query) => {
  //   const url = addUrlQuery(`/giveaways/${id}`, query);
  //   const cleanedValues = cleanValues(values);
  //   const { data } = await client.put(url, cleanedValues);
  //   return data;
  // },
  //
  // delete: async (id: string, query?: Query) => {
  //   const url = addUrlQuery(`/giveaways/${id}`, query);
  //   const { data } = await client.delete(url);
  //   return data;
  // }
});
