// External Imports
import { Axios, AxiosResponse } from "axios";

// Internal Imports
import { cleanValues, addUrlQuery } from "../utils";
import { Query } from "../types/query";

export interface LocationsInterface {
  getAll: (query?: Query) => Promise<any>;
  getAllGeo: (values: { [key: string]: any }) => Promise<any>;
  get: (id: string, query?: Query) => Promise<any>;
  create: (values: { [key: string]: any }, query?: Query) => Promise<any>;
  update: (
    id: string,
    values: { [key: string]: any },
    query?: Query
  ) => Promise<any>;
  delete: (id: string, query?: Query) => Promise<any>;
  getAllTypes: (query?: Query) => Promise<any>;
  searchGoogle: (values: { [key: string]: any }) => Promise<any>;
  addressToGeo: (values: { [key: string]: any }) => Promise<any>;
  getPosts: (id: string, query?: Query) => Promise<any>;
}

export const locations = (client: Axios): LocationsInterface => ({
  getAll: async (query?: Query) => {
    const url = addUrlQuery(`/locations`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  getAllGeo: async (bounds: { [key: string]: any }) => {
    const { data }: AxiosResponse = await client.get(`/locations/geo/geo?south=${bounds?.south}&north=${bounds?.north}&east=${bounds?.east}&west=${bounds?.west}`);
    return data;
  },

  get: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/locations/${id}`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  create: async (values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery("/locations", query);
    const cleanedValues = cleanValues(values);
    const { data } = await client.post(url, cleanedValues);
    return data;
  },

  update: async (id: string, values: { [key: string]: any }, query?: Query) => {
    const url = addUrlQuery(`/locations/${id}`, query);
    const cleanedValues = cleanValues(values);
    const { data } = await client.put(url, cleanedValues);
    return data;
  },

  delete: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/locations/${id}`, query);
    const { data } = await client.delete(url);
    return data;
  },

  getAllTypes: async (query?: Query) => {
    const url = addUrlQuery(`/location-types`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },

  searchGoogle: async (values: { [key: string]: any }) => {
    const { data }: AxiosResponse = await client.post(`/locations/geo/search-google`, values);
    return data;
  },

  addressToGeo: async (values: { [key: string]: any }) => {
    const { data }: AxiosResponse = await client.post(`/locations/geo/address-geo`, values);
    return data;
  },

  getPosts: async (id: string, query?: Query) => {
    const url = addUrlQuery(`/locations/${id}/posts`, query);
    const { data }: AxiosResponse = await client.get(url);
    return data;
  },
});
